import { useState, useRef, useCallback, useContext } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { formatDateWithDots } from '@shared/components/CalendarForm/CalendarForm';
import {IconButton} from "@shared/components";
import iconDots from "@assets/images/icons/subtask-dots.svg";
import iconFolder from "@assets/images/icons/folder.svg";
import { server_url } from 'src/settings/base-url';

import useAuth from 'src/hooks/useAuth';

import { FileLoader } from '@shared/widgets/FileLoader';
import {StyledChatMenuSeparator} from "@components/chat/styles";
import {
  CatalogFileName,
  CatalogFolder,
  StyledDotsMenu,
  StyledDotsMenuItem, StyledDotsWrapper,
  TitlePopup
} from "@components/storage/styles";

import SnackbarContext from '../../contexts/SnackbarContext';
import { getStorageTree, searchStorage } from '../../redux/features/storageSlice';
import { convertBytes, doubleClickHandle, useOutsideTrigger } from '../../utilize/helper-functions';
import { errorTypes, processResponseErrors } from '../../utilize/processResponseErrors';

import ConfirmAction from '../warnings/ConfirmAction';

import FolderVersionsModal from './FolderVersionsModal';
import RenameModal from './RenameModal';
import TransferCopyFilesModal from './TransferCopyFilesModal';


const FolderDetailsRow = ({
  storageId,
  folder,
  renderUserName,
  handleFolderSelect,
  userCanWrite,
  modifiedFolderOpen, // optional
  versionsModal, // optional - для индикации что открыто модальное окно версий
  openTrashStack, // optional - используется, когда папка в корзине, для отслеживания дерева открытых папок
  removeItem, // optional - используется, когда папка в корзине, для удаления элемента из дерева открытых папок
  attachFolderReference, // optional - для прикрепления  ссылки к папке
}) => {
  const [openFolderMenu, setOpenFolderMenu] = useState(false);

  const folderMenuBtn = useRef();
  const folderMenu = useRef();
  const refsArray = useRef([folderMenuBtn, folderMenu]);

  const auth = useAuth();

  const outsideClickEvent = useCallback(() => setOpenFolderMenu(false), []);

  useOutsideTrigger(refsArray.current, outsideClickEvent, openFolderMenu);

  const [openFolderVersions, setOpenFolderVersions] = useState();
  const [transferCopyModal, setTransferCopyModal] = useState();
  const [renameModal, setRenameModal] = useState();

  const [isSubmitting, setIsSubmitting] = useState();
  const [confirmTrashing, setConfirmTrashing] = useState();
  const [confirmDelete, setConfirmDelete] = useState();
  const [showFullTitle, setShowFullTitle] = useState(false);
  const { showSnackbar } = useContext(SnackbarContext);

  const dispatch = useDispatch();

  const searchExpression = useSelector((state) => state.storage.searchExpression);

  const sendToTrash = () => {
    setIsSubmitting(true);
    axios
      .patch('/api/storage_folders/to_trash', {
        folder_id: folder.id,
      })
      .then(() => {
        dispatch(getStorageTree({ storageId, showSnackbar }));
        if (searchExpression) dispatch(searchStorage({ storage_id: storageId, search_expression: searchExpression }));
        setConfirmTrashing(false);
        setIsSubmitting(false);
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при удалении папки в корзину');
        setIsSubmitting(false);
      });
  };

  const deleteFolder = () => {
    setIsSubmitting(true);
    axios
      .patch('/api/storage_folders/remove', {
        folder_id: folder.id,
      })
      .then(() => {
        removeItem({ dataType: 'folders', dataId: folder.id });
        setConfirmDelete(false);
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при удалении папки');
      });
  };

  const restoreFolder = () => {
    axios
      .patch('/api/restore_from_trash', {
        folder_id: folder.id,
      })
      .then(() => {
        removeItem({ dataType: 'folders', dataId: folder.id });
        showSnackbar('Папка восстановлена', 'success');
        dispatch(getStorageTree({ storageId, showSnackbar }));
      })
      .catch((e) => {
        const errorType = processResponseErrors(e);

        if (errorType === errorTypes.NO_PARENT_FOLDER) {
          showSnackbar('Ошибка при восстановлении: родительская папка была удалена');
        } else {
          showSnackbar('Возникла ошибка при восстановлении файла');
        }
      });
  };

  const handleTrashFolderDownload = () => {
    if (openTrashStack) {
      showSnackbar('Идет архивация папки для скачивания...', 'success');
      axios
        .get(`/api/download_folder_from_trash/${folder.id}`, {
          responseType: 'blob',
          timeout: 30000,
        })
        .then((r) => {
          const blob = r.data;
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;

          a.download = folder.title;
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при скачивании папки');
        });
    }
  };

  const handleFolderDownload = () => {
    const a = document.createElement('a');
    a.href = `${server_url}/api/download_storage_folder/${folder.id}?token=${auth.token}`;
    a.download = `${folder.title}`;

    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
  };

  return (
    <>
      <CatalogFolder icon={iconFolder}
        // компонент FolderDetailsRow используется в двух местах:
        // 1) в открытой актуальной папке 2) в открытой папке прошлых версий
        // В зависимости от вида открытой папки, использовать соответствующий функционал по открытию подпапки
        onClick={() => {
          // открыть выбранную папку, если эта папка открывается в модальном окне версий
          if (versionsModal || openTrashStack) modifiedFolderOpen();
          else {
            // при двойном клике открыть папку
            // при одиночном клике открыть версии папки
            doubleClickHandle(
              () => handleFolderSelect(folder.id),
              () => setOpenFolderVersions(true),
            );
          }
        }}
      >
        <TitlePopup $active={showFullTitle}>{folder.title}</TitlePopup>
        <CatalogFileName
           onMouseEnter={() => setShowFullTitle(true)}
           onMouseLeave={() => setShowFullTitle(false)}
        >
          {folder.title}
        </CatalogFileName>
        <p>{convertBytes(folder.size)}</p>
        <p>{formatDateWithDots(folder.date_created)}</p>

        {renderUserName(folder.creator_id)}

        <StyledDotsWrapper>
          <IconButton
            ref={folderMenuBtn}
            icon={iconDots}
            background="#F0F0F0"
            size={24}
            style={{ backgroundSize: 'auto' }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenFolderMenu(!openFolderMenu);
            }}
          />
          <StyledDotsMenu
            $active={openFolderMenu}
            ref={folderMenu}
          >
            <FileLoader
              size={folder.size}
              customComponent={
                <StyledDotsMenuItem
                  onClick={() => {
                    setOpenFolderMenu(false);
                  }}
                >
                  Скачать
                </StyledDotsMenuItem>
              }
              customDownloadHanlder={handleFolderDownload}
              isFolder
            />
            {typeof attachFolderReference === 'function' && (
              <StyledDotsMenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  attachFolderReference({
                    storage_id: storageId,
                    storage_folder_id: folder.id,
                    title: folder.title,
                  });
                  showSnackbar('Ссылка на папку добавлена в сообщение', 'success');
                  setOpenFolderMenu(false);
                }}
              >
                Добавить ссылку на папку
              </StyledDotsMenuItem>
            )}

            {!versionsModal && (
              <StyledDotsMenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenFolderVersions(true);
                  setOpenFolderMenu(false);
                }}
              >
                История версий
              </StyledDotsMenuItem>
            )}
            {/* показать кнопку скачивания, если это Корзина (реализовано только в коризне)  */}
            {openTrashStack && (
              <StyledDotsMenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  handleTrashFolderDownload();
                  setOpenFolderMenu(false);
                }}
              >
                Скачать
              </StyledDotsMenuItem>
            )}

            {/* Функционал, когда папка не в корзине */}
            {!openTrashStack && userCanWrite && (
              <>
                <StyledDotsMenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setTransferCopyModal({ mode: 'transfer' });
                    setOpenFolderMenu(false);
                  }}
                >
                  Перенести в папку этого хранилища
                </StyledDotsMenuItem>
                <StyledDotsMenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setTransferCopyModal({ mode: 'copy' });
                    setOpenFolderMenu(false);
                  }}
                >
                  Скопировать в другое хранилище
                </StyledDotsMenuItem>

                {!versionsModal && (
                  <StyledDotsMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setRenameModal(true);
                      setOpenFolderMenu(false);
                    }}
                  >
                    Переименовать
                  </StyledDotsMenuItem>
                )}

                <StyledDotsMenuItem
                  $isDelete
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfirmTrashing(true);
                    setOpenFolderMenu(false);
                  }}
                >
                  Удалить в корзину
                </StyledDotsMenuItem>
              </>
            )}
            {openTrashStack && userCanWrite && (
              <>
                <StyledDotsMenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    restoreFolder();
                    setOpenFolderMenu(false);
                  }}
                >
                  Восстановить
                </StyledDotsMenuItem>

                <StyledChatMenuSeparator />

                <StyledDotsMenuItem
                  $isDelete
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfirmDelete(true);
                    setOpenFolderMenu(false);
                  }}
                >
                  Удалить навсегда
                </StyledDotsMenuItem>
              </>
            )}
          </StyledDotsMenu>
        </StyledDotsWrapper>
      </CatalogFolder>

      {openFolderVersions && (
        <FolderVersionsModal
          currentFolderData={folder}
          close={() => setOpenFolderVersions(false)}
          renderUserName={renderUserName}
          userCanWrite={userCanWrite}
        />
      )}

      {renameModal && (
        <RenameModal
          close={() => setRenameModal(false)}
          storageId={storageId}
          data={folder}
          type="folder"
          renderUserName={renderUserName}
        />
      )}

      {confirmTrashing && (
        <ConfirmAction
          actionText="Вы уверены что хотите удалить эту папку в корзину?"
          cancel={() => setConfirmTrashing(false)}
          confirm={sendToTrash}
          isSubmitting={isSubmitting}
          confirmButtonText="Удалить"
        />
      )}

      {confirmDelete && (
        <ConfirmAction
          actionText="Вы уверены что хотите полностью удалить эту папку?"
          cancel={() => setConfirmDelete(false)}
          confirm={deleteFolder}
          isSubmitting={isSubmitting}
          confirmButtonText="Удалить"
        />
      )}

      {transferCopyModal && (
        <TransferCopyFilesModal
          close={() => setTransferCopyModal(false)}
          data={folder}
          type="folder"
          mode={transferCopyModal.mode}
          renderUserName={renderUserName}
          storageId={storageId}
        />
      )}
    </>
  );
};

export default FolderDetailsRow;
