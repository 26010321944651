import { DateInput, Label, StyledModalRowStart } from '@shared/components';
import { useController, useWatch } from 'react-hook-form';

const PollDeadline = ({ control }) => {
  const deadline_type = useWatch({ control, name: 'deadline_type' });
  const { field } = useController({ control, name: 'deadline' });

  return (
    <>
      {deadline_type?.value === 'limited' && (
        <StyledModalRowStart>
          <div>
            <Label>Дата и время окончания</Label>
            <DateInput
              placeholder="выбрать срок окончания"
              onChange={(date) => field.onChange(date)}
              selected={field.value}
              timeIntervals={15}
              timeCaption="Время"
              timeFormat="p"
              dateFormat="Pp"
              showTimeSelect
            />
          </div>
        </StyledModalRowStart>
      )}
    </>
  );
};

export default PollDeadline;
