import { Modal } from '@shared/components/Modal/Modal';
import { Text } from '@shared/components/Text/Text';
import { useCancelTicketMutation } from 'src/redux/features/api/tickets';

export const CancelTicketModal = ({ id, onClose }) => {
  const [cancelTicket, { isLoading: isCancelTicketLoading, isCancelTicketError }] = useCancelTicketMutation();

  const handleСancelTicket = async () => {
    await cancelTicket({
      ticket_id: id,
    });

    onClose();
  };

  return (
    <Modal
      modalSize="548px"
      onSave={handleСancelTicket}
      onClose={onClose}
      confirmButtonText={'Да'}
      cancelButtonText={'Нет'}
    >
      <Text style={{ textAlign: 'center' }} size={3} tag="p">
        Вы уверены, что хотите отменить поручение?
      </Text>
    </Modal>
  );
};
