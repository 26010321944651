import styled from 'styled-components';

export const StyledStorageHeader = styled.section`
  align-items: center;
  display: grid;
  gap: 12px;
  grid-template-columns: 2fr 1.5fr 1fr;
`;

export const StyledStorageTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
`;
