import HTMLReactParser from 'html-react-parser';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import { StyledMessageUsername } from '@components/chat/message/styles';
import { ExternalLink } from '@shared/components';

const options = {
  entityStyleFn: (entity) => {
    const entityType = entity.get('type').toLowerCase();

    if (entityType === 'mention') {
      const { mention } = entity.get('data');
      return {
        element: 'a',
        attributes: {
          userid: mention.userId,
        },
      };
    } else if (entityType === 'LINK') {
      const { url } = entity.get('data');
      return {
        element: 'a',
        attributes: {
          href: url,
        },
      };
    }
  },
};

const getInnerNode = (node) => {
  let childNode = node;
  while (childNode.children) {
    childNode = childNode.children[0];
  }
  return childNode;
};

// если в аттрибутах присутствует userId, то трансформировать в span с функцией открытия профиля юзера
// если это ссылка с аттрибутом href - то указать как обычную ссылку
export const transform = (node, showUserProfile) => {
  if (node.type === 'tag' && node.name === 'a') {
    if (node.attribs.userid) {
      const childNode = getInnerNode(node);
      if (childNode.type === 'text') {
        return (
          <StyledMessageUsername
            as="span"
            style={{ cursor: 'pointer' }}
            // to={`/team/users/${node.attribs.userid}`}
            onClick={() => showUserProfile(node.attribs.userid)}
            key={Math.random() * 10000}
          >
            {childNode.data}
          </StyledMessageUsername>
        );
      }
    } else if (node.attribs.href) {
      const childNode = getInnerNode(node);
      if (childNode.type === 'text') {
        return (
          <ExternalLink
            href={node.attribs.href}
            target="_blank"
            rel="noreferrer"
            key={Math.random() * 10000}
            onClick={(e) => e.stopPropagation()}
          >
            {childNode.data}
          </ExternalLink>
        );
      }
    }
    return null;
  }
};

export const removeParagraphTags = (str) => {
  if (!str) return '';
  if (str.startsWith('<p>') && str.endsWith('</p>')) {
    str = str.slice(3);
    str = str.slice(0, -4);
  }
  return str;
};

export const renderHTMLString = (htmlString, options) => {
  if (!htmlString) return null;
  return HTMLReactParser(htmlString, options);
};

export const renderMessage = (draftJsState, showUserProfile, noParagraph) => {
  if (!draftJsState) return null;

  try {
    let htmlString = stateToHTML(convertFromRaw(JSON.parse(draftJsState)), options);
    if (htmlString === '<p><br></p>') return null;

    if (noParagraph) htmlString = removeParagraphTags(htmlString);

    const reactHtml = HTMLReactParser(htmlString, {
      replace: (node) => transform(node, showUserProfile),
    });
    return reactHtml;
  } catch (e) {
    return null;
  }
};
