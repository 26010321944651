import React from 'react';

import { Modal } from '@shared/components';

import { isFileNameImage, isPreviewFile } from 'src/utilize/helper-functions';

import { Wrapper } from './styles';
import { ImagesViewer } from './ui/ImagesViewer';
import { DocsViewer } from './ui/DocsViewer';

export const PreviewModal = ({ url, filePath, fileName, onClose }) => {
  return (
      <Modal modalSize={'1140px'} onClose={onClose} withoutButtons>
        <Wrapper>
          {isFileNameImage(fileName) && <ImagesViewer url={url} filePath={filePath} fileName={fileName} />}
          {isPreviewFile(fileName) && <DocsViewer filePath={filePath} fileName={fileName} />}
        </Wrapper>
      </Modal>
  );
};
