import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { formatDateWithDots } from '@shared/components/CalendarForm/CalendarForm';
import {Subtitle} from "@shared/components";
import {CatalogFileName, CatalogFolder, StorageList} from "@components/storage/styles";
import iconFolder from "@assets/images/icons/folder.svg";

import Preloader from '../preloaders/Preloader';

import FolderVersionRowMenu from './FolderVersionsRowMenu';


const FolderVersionsList = ({
  renderUserName,
  setFolderVersionDetails,
  folderVersions,
  currentFolderData,
  isLoadingVersions,
  setParentFolderStack,
  userCanWrite,
  onCloseModal,
}) => {
  const navigate = useNavigate();
  const { storageId } = useParams();

  const [isSubmitting, setIsSubmitting] = useState();

  return (
    <>
      {isSubmitting ? (
        <Preloader />
      ) : (
        <>
            <Subtitle>Текущая</Subtitle>
            <StorageList>
              <CatalogFolder
                icon={iconFolder}
                onClick={() => {
                  navigate(`/storages/${storageId || currentFolderData?.storage_id}/folder/${currentFolderData.id}`);
                  onCloseModal();
                }}
              >
                <CatalogFileName>{currentFolderData.title}</CatalogFileName>
                <p>{/*20 мб*/}</p>
                <p>{formatDateWithDots(currentFolderData.date_created)}</p>
                {renderUserName(currentFolderData.creator_id)}
              </CatalogFolder>
            </StorageList>
          {isLoadingVersions && <Preloader />}
          {folderVersions?.length > 0 && (
            <>
              <Subtitle>Предыдущие</Subtitle>
              <StorageList>
                {folderVersions?.map((versionData, i) => (
                  <CatalogFolder
                    icon={iconFolder}
                    key={i}
                    onClick={() => {
                      setParentFolderStack((p) => [...p, versionData]);
                      setFolderVersionDetails(versionData);
                    }}
                  >
                    <CatalogFileName>{versionData.title}</CatalogFileName>
                    <p>{/*20 мб*/}</p>
                    <p>{formatDateWithDots(versionData.date_created)}</p>
                    {renderUserName(versionData.creator_id)}
                    <FolderVersionRowMenu
                      setIsSubmitting={setIsSubmitting}
                      versionData={versionData}
                      dataType="folder"
                      userCanWrite={userCanWrite}
                    />
                  </CatalogFolder>
                ))}
              </StorageList>
            </>
          )}
        </>
      )}
    </>
  );
};

export default FolderVersionsList;
