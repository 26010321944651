import HTMLReactParser from 'html-react-parser';
import { useMemo, useRef } from 'react';
import moment from 'moment';
import { Label } from '@shared/components/Label/Label';
import { Text } from '@shared/components/Text/Text';
import { Modal } from '@shared/components/Modal/Modal';
import { StyledModalRowEnd } from '@shared/components/Modal/style';
import { ModalStatusInfo } from '@shared/components/Modal/ModalStatusInfo';
import { Status } from 'src/shared/components/Status';
import { Container } from '@shared/components/Container';
import { Link } from '@shared/components/Link/Link';
import AttachedFile from '@components/files/AttachedFile';
import { TicketReport } from '@components/Tickets/TicketsForMe/TicketReport/TicketReport';
import { useGetTicketDetailsQuery } from 'src/redux/features/api/tickets';
import Preloader from '@components/preloaders/Preloader';
import NotFoundComponent from '@shared/components/NotFoundComponent/NotFoundComponent';
import { Avatar } from '@shared/components/Avatar';

import { ReportForm } from './ui/ReportForm';
import { StyledDescription } from './ui/ReportForm/styles';
import { StyledUserName } from './style';

export const TicketModal = ({ ticketId, onClose }) => {
  const { data, isFetching: isTicketLoading, error } = useGetTicketDetailsQuery({ id: ticketId });

  const ticket = useMemo(() => data?.ticket_detail, [data]);

  const date = useMemo(() => new Date(ticket?.date_finish));

  const isReturned = useMemo(() => ticket?.ticket_actions[0].action === 'returned', [ticket]);

  const isExpired = useMemo(() => moment().diff(ticket?.date_finish) > 0, [ticket]);

  const currentAction = useMemo(() => ticket?.ticket_actions[0].action, [ticket]);

  const removeFilesOnClose = useRef();

  const onCloseHandler = async () => {
    const onRemove = removeFilesOnClose.current;

    if (onRemove) {
      await onRemove();
    }

    onClose();
  };

  return (
    <Modal modalSize="767px" title="Поручение" onClose={onCloseHandler} withoutButtons>
      {isTicketLoading ? (
        <Preloader />
      ) : (
        <>
          {(error?.status === 403 || error?.status === 404) && <NotFoundComponent small />}

          {(error?.status !== 403 || error?.status !== 404) && (
            <>
              {isReturned && <ModalStatusInfo color="error">Поручение возвращено</ModalStatusInfo>}
              {ticket?.base_chat_message_id && (
                <ModalStatusInfo>
                  <Text tag="p">
                    Поручение на основе сообщения из {ticket?.base_chat_message.project_title ? 'проекта' : 'задачи'}
                    <Link
                      link={`/projects/${
                        ticket?.base_chat_message.project_id
                          ? ticket?.base_chat_message.project_id
                          : ticket?.base_chat_message.task_id
                      }`}
                      tag="b"
                      hover="var(--main)"
                    >
                      {` "${ticket?.base_chat_message.project_title || ticket?.base_chat_message.task_title}".`}
                    </Link>
                  </Text>
                  <Text tag="p">{ticket?.base_chat_message.message}</Text>
                </ModalStatusInfo>
              )}
              <div>
                <StyledModalRowEnd>
                  <div>
                    <Label>Название</Label>
                    <Text size={2} tag="b">
                      {ticket?.title}
                    </Text>
                  </div>
                  <div>
                    <Label>Задание от</Label>
                    <Container align={'center'}>
                      <Avatar userId={ticket?.creator_id} size={24} />
                      <StyledUserName size={1}>
                        {ticket?.creator_last_name} {ticket?.creator_first_name}
                      </StyledUserName>
                    </Container>
                  </div>
                </StyledModalRowEnd>
              </div>
              <Container direction="column" gap={'8px'}>
                <StyledDescription>
                  <Label>Описание</Label>
                  {!!ticket?.description && (
                    <Text size={2} tag="p">
                      {HTMLReactParser(String(ticket?.description))}
                    </Text>
                  )}
                </StyledDescription>
                <div>
                  {ticket?.ticket_actions[ticket?.ticket_actions.length - 1].ticket_action_files?.length > 0 && (
                    <>
                      {ticket?.ticket_actions[ticket?.ticket_actions.length - 1].ticket_action_files.map((file, i) => {
                        const url = `/ticket_actions/files/${
                          ticket?.ticket_actions[ticket?.ticket_actions.length - 1].id
                        }/${file.id}/${file.file}`;
                        return (
                          <AttachedFile
                            fileUrl={url}
                            fileName={file.file}
                            fileId={file.id}
                            key={i}
                            storageFileId={file.from_storage}
                          />
                        );
                      })}
                    </>
                  )}
                </div>
              </Container>
              <div>
                <Label>Срок исполнения</Label>
                <Container direction="column" gap="2px">
                  <Text size={2} tag="b">
                    {moment(date).format('L')} {moment(date).format('LT')}
                  </Text>
                  {isExpired && <Status color="red">Просрочено</Status>}
                </Container>
              </div>
              <Container direction={'column'}>
                {ticket?.ticket_actions.map((action) => {
                  return action.report ? <TicketReport action={action} /> : null;
                })}
              </Container>

              <ReportForm
                ticketId={ticket?.id}
                currentAction={currentAction}
                onClose={onClose}
                removeFilesOnClose={removeFilesOnClose}
              />
            </>
          )}
        </>
      )}
    </Modal>
  );
};
