import React, {useRef, useState, memo, useCallback, useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import ProjectFormCreateEdit from 'src/windows/projects/project-form-create-edit/ProjectFormCreateEdit';
import {useSocketContext} from 'src/contexts/SocketContext';
import {formatDateWithDots} from '@shared/components/CalendarForm/CalendarForm';
import useAuth from 'src/hooks/useAuth';
import {useOutsideTrigger} from 'src/utilize/helper-functions';
import CreateEditGuestModal from 'src/windows/profile/CreateEditGuestModal';
import ConfirmAction from 'src/components/warnings/ConfirmAction';
import CreateTaskModal from 'src/components/tasks/CreateTaskModal';

import {IconButton, Term} from '@shared/components';

import iconLock from '@assets/images/icons/lock.svg';
import iconUnlock from '@assets/images/icons/unlocked.svg';
import iconInfo from "@assets/images/icons/icon-info.svg";
import iconPinList from "@assets/images/icons/pinlist.svg";
import {colors} from 'src/variables';

import {
  ProjectStatus,
  StyledChatHeader,
  StyledChatHeaderCol,
  StyledChatHeaderTitle,
  StyledChatHeaderWrapper,
  StyledChatMenu,
  StyledChatMenuItem, StyledChatTitleWrapper,
  StyledTermWrapper,
} from '@components/chat/styles';

import {PinnedMessage} from "@components/chat/message/PinnedMessage/PinnedMessage";

import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper/modules";
import {StyledPinnedContainer} from "@components/chat/message/PinnedMessage/styles";
import PinnedMessageModal from "@components/chat/message/PinnedMessage/PinnedMessageModal";

import {useMessageContext} from "../../../contexts/MessageContext";

import SingleProjectHeaderMenu from './SingleProjectHeaderMenu';

import SingleProjectHeaderMessageBasis from './SingleProjectHeaderMessageBasis';

// статусы проектов
const status = {
  in_progress: colors.warningSecondary,
  finished: colors.success,
  overdue: colors.warning,
  created: colors.disabled
};

const SingleProjectHeader = ({projectData, getProjectData, showSubtaskAddModal, pinnedMessagesList}) => {
  const {projectId, taskId} = useParams();
  const auth = useAuth();
  const {socket, addToRoom, leaveRoom, startTask, finishTask, unfinishTask, onTaskStatusChange} = useSocketContext();
  const {pinnedMessagesModal, setPinnedMessagesModal} = useMessageContext();
  const [editModal, setEditModal] = useState();

  const locked = useMemo(() => projectData?.locked, [projectData]);

  // войти в socket комнату для отслеживвания статуса проекта/таска
  useEffect(() => {
    if (socket?.connected) {
      addToRoom('project_task_status_changed');

      return () => leaveRoom('project_task_status_changed');
    }
  }, [addToRoom, leaveRoom, socket?.connected]);

  // проверить есть ли у юзера права на изменение статуса проекта/таска
  const userHasRights = useMemo(() => {
    if (!auth?.user?.id || !auth?.rightTypes) return false;
    if (auth?.isUserRightful()) return true;
    if (auth.user.id === projectData?.creator_id) return true;
    if (auth.user.id === projectData?.responsible_id) return true;
    if (auth.user.id === projectData?.executor_id) return true;
    return false;
  }, [auth, projectData]);

  // const [optionsMenu, toggleOptionsMenu] = useState(false);
  const [unfinishButton, showUnfinishButton] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  // для показа окна создания гостевого доступа
  const [guestCreateModal, showGuestCreateModal] = useState(false);

  const clearOptionsMenu = useCallback(() => {
    // toggleOptionsMenu(false);
    showUnfinishButton(false);
  }, []);
  const clearShowMenu = useCallback(() => {
    setShowInfo(false);
  }, []);

  // для закрытия всплывающих меню при нажатии вне этих меню
  // const optionsBtnRef = useRef();
  // const optionsMenuRef = useRef();
  const unfinishBtnRef = useRef();
  const projectStatusRef = useRef();
  const iconInfoRef = useRef();
  const refsArray = useRef([/*optionsMenuRef, optionsBtnRef,*/ unfinishBtnRef, projectStatusRef]);

  useOutsideTrigger(refsArray.current, clearOptionsMenu, unfinishButton);
  useOutsideTrigger([iconInfoRef], clearShowMenu, showInfo);

  // отправлять socket события на сервер при изменении статуса проекта текущим юзером
  const manageTaskStatus = (action) => {
    const data = {};
    taskId ? (data.task_id = projectData.id) : (data.project_id = projectData.id);

    if (action === 'start') startTask(data);
    else if (action === 'finish') finishTask(data);
    else if (action === 'unfinish') unfinishTask(data);
  };

  // статус проекта
  const [projectStatus, setProjectStatus] = useState();

  useEffect(() => {
    if (projectData?.status) {
      setProjectStatus(projectData?.status);
    }
  }, [projectData?.status]);

  // socket callback для отслеживания изменений статуса проекта
  const statusChangeCallback = useCallback(
    (data) => {
      if ((taskId && projectData?.id === data.task_id) || (!taskId && projectData.id === data.project_id)) {
        setProjectStatus(data.status);
      }
    },
    [taskId, projectData?.id],
  );

  // слушать socket событие об изменении статуса проекта
  useEffect(() => {
    onTaskStatusChange(statusChangeCallback);
  }, [onTaskStatusChange, statusChangeCallback]);

  const [confirmModal, showConfirmModal] = useState(false);

  const closeEditModal = useCallback(() => setEditModal(false), []);

  const projectEditData = useMemo(() => ({editable: {projectId: projectData.id}}), [projectData?.id]);
  const swiperRef = useRef(null);

  return (
    <>
      <StyledChatHeaderWrapper>
        <StyledChatHeader>
          <StyledChatHeaderCol>
            <StyledChatTitleWrapper>
              <IconButton icon={locked ? iconLock : iconUnlock}/>
              <div style={{position: 'relative'}}>
                {projectStatus && status[projectStatus] && (
                  <ProjectStatus
                    color={status[projectStatus]}
                    events={projectStatus === 'finished' && userHasRights}
                    onClick={() => showUnfinishButton(true)}
                    ref={projectStatusRef}
                    type="button"
                  />
                )}
                <StyledChatMenu $active={unfinishButton}  style={{right: 'auto', left: 0}}>
                  <StyledChatMenuItem
                    onClick={() => {
                      manageTaskStatus('unfinish');
                      showUnfinishButton(false);
                    }}
                    ref={unfinishBtnRef}
                  >
                    Вернуть в работу
                  </StyledChatMenuItem>
                </StyledChatMenu>
              </div>
              <StyledChatHeaderTitle>{`${projectData?.title}`}</StyledChatHeaderTitle>

              {
                (projectData?.date_start || projectData?.date_finish || projectData.base_chat_message_id) && (
                  <div style={{position: 'relative'}}>
                    <IconButton
                      size={20}
                      icon={iconInfo}
                      onClick={() => setShowInfo(!showInfo)}
                      ref={iconInfoRef}
                      style={{verticalAlign: 'middle'}}
                    />
                    <StyledChatMenu $active={showInfo}>
                      {(projectData?.date_start || projectData?.date_finish) && (
                        <StyledChatMenuItem>
                          <StyledTermWrapper>
                            {projectData?.date_start && <Term>{formatDateWithDots(projectData?.date_start)}</Term>}-
                            {projectData?.date_finish && <Term>{formatDateWithDots(projectData.date_finish)}</Term>}
                          </StyledTermWrapper>
                        </StyledChatMenuItem>
                      )}
                      {projectData.base_chat_message_id && (
                        <StyledChatMenuItem>
                          <SingleProjectHeaderMessageBasis projectData={projectData}/>
                        </StyledChatMenuItem>
                      )}
                    </StyledChatMenu>
                  </div>
                )
              }
            </StyledChatTitleWrapper>
          </StyledChatHeaderCol>
          <StyledChatHeaderCol style={{marginLeft: 'auto'}}>
            {auth?.user && !auth.user.is_guest && projectData && (
              <SingleProjectHeaderMenu
                projectStatus={projectStatus}
                userHasRights={userHasRights}
                setEditModal={setEditModal}
                taskId={taskId}
                projectId={projectId}
                showSubtaskAddModal={showSubtaskAddModal}
                showConfirmModal={showConfirmModal}
                showGuestCreateModal={showGuestCreateModal}
                canConvert={projectData?.canConvert}
                canDelete={projectData?.canDelete}
                canChangeCreator={projectData?.canChangeCreator}
                manageTaskStatus={manageTaskStatus}
              />
            )}
          </StyledChatHeaderCol>
          {pinnedMessagesList.length > 0 && (
            <>
              <StyledPinnedContainer>
                <Swiper
                  slidesPerView={1}
                  direction="vertical"
                  initialSlide={pinnedMessagesList.length - 1}
                  mousewheel={false}
                  allowTouchMove={false}
                  pagination={{clickable: true}}
                  modules={[Pagination]}
                  onSwiper={(swiper) => { swiperRef.current = swiper; }}
                >
                  {pinnedMessagesList.map(message => {
                      return <SwiperSlide key={message.id}>
                        <PinnedMessage
                          message={message}
                          projectData={projectData}
                          swiperRef={swiperRef}
                        />
                      </SwiperSlide>
                    }
                  )}
                </Swiper>
              </StyledPinnedContainer>
              <IconButton
                icon={iconPinList}
                size={32}
                style={{alignSelf: 'end', marginLeft: 'auto'}}
                onClick={() => setPinnedMessagesModal(true)}
              />
            </>
          )}

        </StyledChatHeader>
      </StyledChatHeaderWrapper>

      {/* окно со списком закрепленных сообщений */}
      {
        pinnedMessagesModal && (
          <PinnedMessageModal
            pinnedMessagesList={pinnedMessagesList}
            onClose={() => setPinnedMessagesModal(false)}
          />
        )
      }

      {/* окно подтверждения завершении проекта */}
      {confirmModal && (
        <ConfirmAction
          confirm={() => {
            manageTaskStatus('finish');
            showConfirmModal(false);
          }}
          confirmButtonText="Завершить"
          cancel={() => showConfirmModal(false)}
          actionText={`Вы уверены, что хотите завершить ${taskId ? 'задачу' : 'проект'}?`}
        />
      )}

      {/* окно создания гостевого доступа */}
      {guestCreateModal && (
        <CreateEditGuestModal
          projectData={projectData}
          dataType={taskId ? 'task' : 'project'}
          close={() => showGuestCreateModal(false)}
        />
      )}

      {/* окно редактирования */}
      {editModal && (
        <>
          {projectData?.project_id || projectData?.parent_task_id ? (
            <CreateTaskModal close={closeEditModal} taskIdToEdit={projectData.id} getTaskData={getProjectData}/>
          ) : (
            <ProjectFormCreateEdit onClose={closeEditModal} data={projectEditData} getProjectData={getProjectData}/>
          )}
        </>
      )}
    </>
  );
};

export default memo(SingleProjectHeader);
