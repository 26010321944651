import React, { useState } from 'react';

import { Container, Icon, IconButton, Text } from '@shared/components';

import IconFile from '@assets/images/icons/icon-file.svg';

import { convertBytes, doubleClickHandle, isFileNameImage, isPreviewFile } from 'src/utilize/helper-functions';

import { formatDateWithDots } from '@shared/components/CalendarForm/CalendarForm';

import IconDots from '@assets/images/icons/subtask-dots.svg';

import { Dropdown } from '@shared/components/Dropdown/Dropdown';

import { useDownloader } from '@shared/hooks/useDownloader';

import ConfirmAction from '@components/warnings/ConfirmAction';

import { useNavigate } from 'react-router-dom';

import { PreviewModal } from '@shared/widgets/PreviewModal';
import { downloadFileUrl } from 'src/settings/base-url';
import useAuth from 'src/hooks/useAuth';

import FileVersionsModal from '@components/storage/FileVersionsModal';

import useRenderUserName from '@components/storage/useRenderUserName';

import UserProfileModalContainer from 'src/windows/profile/UserProfileModalContainer';

import { StyledLink, StyledListItem } from '../../styles';

import { StyledMenu, StyledMenuItem, StyledRow, StyledText, StyledTextWrapper } from './styles';

export const CardFile = ({ index, file, onClose }) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [fileVerisonsModal, setFileVersionsModal] = useState(false);
  const [userProfileId, setUserProfileId] = useState();

  const renderUserName = useRenderUserName(setUserProfileId);

  const navigate = useNavigate();

  const { downloadFile } = useDownloader();

  const auth = useAuth();

  const filePath = `/storages/${file.path}`;

  const url = `${downloadFileUrl}${encodeURIComponent(filePath)}?token=${auth.token}`;

  const onDownloadHandler = () => {
    downloadFile({ url: filePath, filename: file.title }).then((res) => {
      if (res.fileDetails.isLarge) {
        setConfirmModal(true);
      }
    });
  };

  const onConfirm = () => {
    downloadFile({ url: filePath, filename: file.title, forceDownload: true });
    setConfirmModal(false);
  };

  const onClickHandler = () => {
    doubleClickHandle(
      () => downloadFile({ url: filePath, filename: file.title, forceDownload: true }),
      () => {
        navigate(
          `/storages/${file.storage_id || file.root_storage.id}${file.folder_id ? `/folder/${file.folder_id}` : ''}`,
        );
        onClose();
      },
    );
  };

  return (
    <>
      <StyledListItem key={index}>
        <Container gap={'12px'}>
          <div>{index + 1}.</div>
          <Container direction={'column'} gap={'8px'} width={'100%'}>
            <Text size={3}>Хранилище: {file?.root_storage.title}</Text>
            <StyledRow>
              <StyledLink target="_blank" rel="noreferrer">
                <Container
                  onClick={(e) => {
                    e.preventDefault();
                    onClickHandler();
                  }}
                  align={'center'}
                  gap={'10px'}
                  style={{ cursor: 'pointer' }}
                >
                  <Icon icon={IconFile} size={20} />
                  <StyledTextWrapper>{file?.title}</StyledTextWrapper>
                </Container>
              </StyledLink>

              <StyledText>{convertBytes(file?.size)}</StyledText>
              <StyledText>{formatDateWithDots(file?.data_update)}</StyledText>
              <StyledText>
                {file?.creator_first_name} {file?.creator_last_name}
              </StyledText>
              <Dropdown
                overlay={
                  <StyledMenu>
                    {(isFileNameImage(file?.title) || isPreviewFile(file?.title)) && (
                      <StyledMenuItem onClick={() => setPreviewModalVisible(true)}>Просмотр</StyledMenuItem>
                    )}
                    <StyledMenuItem
                      onClick={() => {
                        navigate(
                          `/storages/${file.storage_id || file.root_storage.id}${
                            file.folder_id ? `/folder/${file.folder_id}` : ''
                          }`,
                        );
                        onClose();
                      }}
                    >
                      Перейти в хранилище
                    </StyledMenuItem>
                    <StyledMenuItem onClick={onDownloadHandler}>Скачать</StyledMenuItem>
                    <StyledMenuItem onClick={() => setFileVersionsModal(true)}>История версий</StyledMenuItem>
                  </StyledMenu>
                }
              >
                <IconButton icon={IconDots} />
              </Dropdown>
            </StyledRow>
          </Container>
        </Container>
      </StyledListItem>

      {fileVerisonsModal && (
        <FileVersionsModal
          close={() => setFileVersionsModal(false)}
          currentFileData={file}
          renderUserName={renderUserName}
        />
      )}

      {previewModalVisible && (
        <PreviewModal
          url={url}
          filePath={filePath}
          fileName={file?.title}
          onClose={() => setPreviewModalVisible(false)}
        />
      )}

      {confirmModal && (
        <ConfirmAction
          cancel={() => setConfirmModal(false)}
          confirm={onConfirm}
          actionText={`Вы уверены, что хотите скачать файл? Он весит ${convertBytes(file?.size)}`}
          confirmButtonText={'Да'}
          cancelButtonText={'Нет'}
          isSuccess
        />
      )}

      {userProfileId && <UserProfileModalContainer profileId={userProfileId} onClose={() => setUserProfileId(null)} />}
    </>
  );
};
