// данный context используется для функционала, связанного с сообщениями: контекстное меню по сообщению,
// редактировать сообщение, ответить на сообщение, показать профиль автора сообщения,
// получить список гостевых прав автора и т.д.
import {useState, createContext, useContext, useMemo} from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectGuestRights } from '../redux/features/projectsSlice';

import UserProfileModalContainer from '../windows/profile/UserProfileModalContainer';

const MessageContext = createContext({
  popover: { show: false, x: null, y: null, text: null },
  showPopover: () => {},
  quoteMessage: null,
  setQuoteMessage: () => {},
  messageToEdit: null,
  editMessage: () => {},
  showUserProfile: () => {},
  guestRights: () => {},
  fileFromStorage: null,
  setFileFromStorage: null,
  setPinnedMessagesModal: () => {},
  // для сброса текста, файлов при переключении задач в сайдпанели
  setResetEditor: () => {},
  resetEditor: null,
});

const MessageContextProvider = ({ children }) => {
  const { projectId, taskId } = useParams();

  const selectorParams = useMemo(() => {
    return taskId ? { projectType: 'task', projectId: +taskId } : { projectType: 'project', projectId: +projectId };
  }, [projectId, taskId]);

  // для скрытия элементов интерфейса, если юзер-гость и у него нет соответсвующих прав
  const guestRights = useSelector((state) =>
    selectGuestRights(state, selectorParams.projectType, selectorParams.projectId),
  );

  const [quote, setQuote] = useState(null);

  const [popover, showPopover] = useState({
    show: false,
    x: null,
    y: null,
    text: null,
  });

  const [messageToEdit, editMessage] = useState(null);

  const [userProfile, showUserProfile] = useState(null);
  const [pinnedMessagesModal, setPinnedMessagesModal] = useState();
  // для сброса текста, файлов при переключении задач в сайдпанели
  const [resetEditor, setResetEditor] = useState(null);

  return (
    <MessageContext.Provider
      value={{
        popover,
        showPopover,
        quoteMessage: quote,
        setQuoteMessage: setQuote,
        messageToEdit,
        editMessage,
        showUserProfile,
        guestRights,
        pinnedMessagesModal,
        setPinnedMessagesModal,
        setResetEditor,
        resetEditor
      }}
    >
      <>
        {children}

        {userProfile && <UserProfileModalContainer profileId={userProfile} onClose={() => showUserProfile(null)} />}
      </>
    </MessageContext.Provider>
  );
};

const useMessageContext = () => {
  const context = useContext(MessageContext);
  if (context) {
    return context;
  }
  throw new Error('useMessageContext must be used within a MessageContextProvider');
};

export { useMessageContext, MessageContextProvider };
