import React, {useRef, useState, useCallback, useEffect, useContext, memo, useMemo} from 'react';
import {EditorState, RichUtils, convertToRaw, convertFromRaw} from 'draft-js';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import useAuth from 'src/hooks/useAuth';
import {getFilesFromDataTransferItems, randomSequence, useOutsideTrigger} from 'src/utilize/helper-functions';
import {useSocketContext} from 'src/contexts/SocketContext';
import SnackbarContext from 'src/contexts/SnackbarContext';
import LocalDateTime from 'src/components/shared/LocalDateTime';
import FilesUploadManage from 'src/components/files/FilesUploadManage';
import FileToUpload from 'src/components/files/FileToUpload';
import FolderLink from '@components/files/FolderLink';
import {Button, IconButton} from '@shared/components';
import DeleteCircleButton from '@shared/components/DeleteCircleButton';
import {AttachTag, AttachWrapper, ScrollWrapper, StyledChatTextEditor} from '@components/text-editor/style';

import {
  StyledQuote,
  StyledQuoteBody,
  StyledQuoteDate,
  StyledQuoteDeleteButton,
  StyledQuoteName,
  StyledQuoteWrapper,
  StyledResultTitle,
  StyledTag,
} from '@components/chat/message/styles';
import {
  StyledChatContextMobile,
  StyledChatContextWrapper,
  StyledChatEditorWrapper,
  StyledChatFooterCol,
  StyledChatFooterRow,
  StyledChatInput, StyledChatMobileMenu, StyledMenuButtonMobile,
  StyledSendButtonMobile,
} from '@components/chat/styles';
import iconDelete from '@assets/images/icons/delete-circle.svg';
import iconSend from '@assets/images/icons/send-mobile.png';
import iconDots from '@assets/images/icons/subtask-dots.svg';
import iconFile from "@assets/images/icons/icon-file.svg";

import {SubmitButton} from '@components/text-editor/style';

import {useWindowWidth} from "@shared/hooks/useWindowWidth";

import EditorWithMentions from './mentions/EditorWithMentions';
import ChatTextEditorBottomMenu from './ChatTextEditorBottomMenu';



let typing, typingTimeoutId;

const MESSAGE_SUBMIT_STATUS = {
  pending_files_upload: 'pending_files_upload',
  ready_to_send: 'ready_to_send',
  sending_message: 'sending_message',
};

// в качестве редактора сообщений используется сторонняя библиотека draft-js
const ChatTextEditor = ({
                          draggedFiles,
                          chatId,
                          goals,
                          quoteMessage,
                          setQuoteMessage,
                          messageToEdit,
                          editMessage,
                          filesUploadDisabled,
                          // fileFromStorage,
                          storageId,
                          setDisableChatFilesDrop,
                          setResetEditor,
                        }) => {
  const {t} = useTranslation();
  const {taskId} = useParams();

  const auth = useAuth();

  //показывает кнопки стилей
  const [stylingToolbar, setStylingToolbar] = useState(false);

  const {socket, initSocket, sendMessage, sendMessageEdit, userWrites, userStoppedWriting} = useSocketContext();
  // для показа всплывающих информационных сообщений
  const {showSnackbar} = useContext(SnackbarContext);

  // для ввода заголовка сообщения
  const [messageTitle, setChatTitle] = useState('');
  // state библиотеки draft-js
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [plainEditText, setPlainEditText] = useState('');

  const [selectedTags, setSelectedTags] = useState([]);
  // для отображения статуса отправки файлов при отправлении сообщения
  // const [fileUploadPercentages, setFileUploadPercentages] = useState({});
  // для выбора результата при написании сообщения
  const [goalMessage, setGoalMessage] = useState(null);

  const editorRef = useRef();
  const containerRef = useRef();

  // для редактирования списка файлов при редактировании сообщения
  // const [messageFilesToEdit, setMessageFilesToEdit] = useState(null);
  const [filesDeleteArr, setFilesDeleteArr] = useState([]);
  const messageEditFilesToDelete = useRef([]);

  const {filesToEdit, addFilesFormDataTemplate} = useMemo(() => {
    let filesToEdit, addFilesFormDataTemplate;
    if (messageToEdit?.message_files && auth?.token) {
      filesToEdit = messageToEdit.message_files.map((file) => ({
        ...file,
        name: file.file,
        src: `/chat_messages/files/${messageToEdit.chat_message_id}/${file.id}/${file.file}`,
      }));

      addFilesFormDataTemplate = new FormData();
      addFilesFormDataTemplate.append('chat_message_id', messageToEdit.chat_message_id);
    }
    return {filesToEdit, addFilesFormDataTemplate};
  }, [messageToEdit, auth]);

  // если в одном из сообщений нажимается кнопка "Редактировать",
  // то установить все параметры для редактирования этого сообщения
  useEffect(() => {
    if (messageToEdit) {
      // установить state draft-js для редактирования текста сообщения
      if (messageToEdit.editor_state) {
        const editorState = convertFromRaw(JSON.parse(messageToEdit.editor_state));
        setEditorState(EditorState.createWithContent(editorState));
        setPlainEditText(editorState.getPlainText());
        // сфокусировать курсор на редакторе сообщения
        setTimeout(() => {
          if (editorRef.current) editorRef.current.focus();
        }, 10);
      }
      // если в редактируемом сообщении есть тэги, то установить тэги для редактирования
      if (messageToEdit.tags) {
        setSelectedTags(
          messageToEdit.tags.map((tag) => {
            const updTag = {...tag};
            updTag.id = tag.tag_id;
            return updTag;
          }),
        );
      }

      // если в редактируемом сообщении добавлен результат, то установить результат для редактирования
      if (messageToEdit.goals?.length) {
        const {project_goal_id, task_goal_id} = messageToEdit.goals[0];
        const selectedGoal = goals.find((goal) => goal.id === project_goal_id || goal.id === task_goal_id);

        if (selectedGoal) setGoalMessage(selectedGoal);
      }
      // если в редактируемом сообщении есть заголовок, то установить заголовок для редактирования
      if (messageToEdit.title && !messageToEdit.goals?.legnth) {
        toggleTitleInput(true);
        setChatTitle(messageToEdit.title);
      }
      // если редактируемое сообщение является ответом на другое сообщение, то установить соответствующие параметры для редактирования ответа
      if (messageToEdit.reply_text || messageToEdit.reply_first_name) {
        const author = `${messageToEdit.reply_first_name || ''} ${messageToEdit.reply_last_name || ''}`;
        setQuoteMessage({
          author,
          quoteText: messageToEdit.reply_text,
          dateTime: <LocalDateTime dateTime={messageToEdit.reply_date}/>,
          messageFiles: messageToEdit.message_files
        });
      }

      // если в редактируемом сообщении есть ссылки на папки, то показать эти ссылки
      if (messageToEdit.message_storage_folders?.length) {
        setFolderLinks(
          messageToEdit.message_storage_folders.map((f) => ({
            title: f.title,
            storage_folder_id: f.storage_folder_id,
          })),
        );
      }
    }
  }, [messageToEdit, setQuoteMessage, goals]);

  // инициировать сокет, если ранее не был инициирован
  useEffect(() => {
    if (!socket?.connected && auth?.token) {
      initSocket(auth.token);
    }
  }, [socket, auth, initSocket]);

  // если файлы были сброшены перетаскиванием, то данный эффект перехватывает эти файлы с компонента-родителя (SingleProjectChat)
  useEffect(() => {
    if (draggedFiles) setFilesToUpload([...draggedFiles]);
  }, [draggedFiles]);

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [messageSubmitStatus, setMessageSubmitStatus] = useState();
  const [isFilesUploading, setIsFilesUploading] = useState();

  const [titleInput, toggleTitleInput] = useState(false);

  // функция отправляет socket сообщение серверу, когда пользователь печатает
  const updateTyping = useCallback(() => {
    if (!auth?.user?.id) return;
    if (!typing) {
      typing = true;
      userWrites(chatId);
      setTimeout(() => (typing = false), 10000);
    }

    clearTimeout(typingTimeoutId);
    typingTimeoutId = setTimeout(() => {
      userStoppedWriting(chatId);
      typing = false;
    }, 3000);
  }, [auth, chatId]);

  // регистрация функции для отправления socket сообщения серверу, когда юзер печатает
  useEffect(() => {
    const editor = document.getElementById('chatMessageBox');
    editor.addEventListener('keydown', updateTyping);

    // отмена регистрации, при выходе из страницы
    return () => {
      editor.removeEventListener('keydown', updateTyping);
    };
  }, [updateTyping]);

  // управлять командами с клавиатуры для форматирования выделенного текста (Ctrl+B, Ctrl+U)
  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const form_id = useRef(randomSequence());

  const reset = useRef(() => {
    setEditorState(EditorState.createEmpty());
    toggleTitleInput(false);
    setChatTitle('');
    setFilesToUpload([]);
    setFilesFromStorage([]);
    setSelectedTags([]);
    setQuoteMessage(null);
    setGoalMessage(null);
    setMessageSubmitStatus(null);
    setIsFilesUploading(false);
    form_id.current = randomSequence();
    if (typeof clearFileManager.current === 'function') {
      clearFileManager.current();
    }
    editMessage(null);
    messageEditFilesToDelete.current = [];
    // если не поставить timeout, то react не успевает очистить state редактора и сразу устанавливается фокус с текущим текстом
    setTimeout(() => editorRef.current.focus(), 1);
  });

  const [filesFromStorage, setFilesFromStorage] = useState([]);
  const [folderLinks, setFolderLinks] = useState([]);

  const addStorageFileReference = useCallback((storageFileData) => {
    setFilesFromStorage((files) => {
      const alreadyAdded = files.some((fileData) => fileData.id === storageFileData.id);
      if (alreadyAdded) return files;
      return [...files, storageFileData];
    });
    showSnackbar('Ссылка на документ добавлена в сообщение', 'success');
  }, []);

  const attachFolderReference = useCallback((folderData) => {
    setFolderLinks((folderLinks) => {
      const alreadyAdded = folderLinks.some((f) => f.storage_folder_id === folderData.storage_folder_id);
      if (alreadyAdded) return folderLinks;
      return [...folderLinks, folderData];
    });
  }, []);

  const removeFolderReference = useCallback((folderData) => {
    setFolderLinks((folderLinks) => {
      return folderLinks.filter((f) => f.storage_folder_id !== folderData.storage_folder_id);
    });
  }, []);

  const submitMessage = useCallback(async () => {
    if (isFilesUploading && isFilesUploading !== 'completed') {
      setMessageSubmitStatus(MESSAGE_SUBMIT_STATUS.pending_files_upload);
      return;
    }

    // если это сообщение отправляется как результат и для результата нужен файл, то проверить
    // есть ли отправляемый файл; есть ли файл, прикрепляемый из хранилища;
    // при редактировании сообщения - есть ли нетронутый файл (который не удаляется)
    // если ни одно из вышеперечисленных условий не выполнено, то показать сообщение, что нужно добавить файл
    if (goalMessage?.type === 'file') {
      if (!filesToUpload.length && !filesFromStorage.length && !filesToEdit?.find((f) => !filesDeleteArr.includes(f))) {
        setMessageSubmitStatus(null);
        return showSnackbar('Нужно добавить файл');
      }
    }

    setMessageSubmitStatus(MESSAGE_SUBMIT_STATUS.sending_message);
    // прикрепление файлов из хранилища
    if (filesFromStorage.length) {
      const storageFilesReq = {
        storage_files: filesFromStorage.map((f) => f.id),
        form_id: form_id.current,
      };
      if (messageToEdit) {
        storageFilesReq.chat_message_id = messageToEdit.chat_message_id;
      }
      await axios.post('/api/chat_message_files/add_from_storage', storageFilesReq).catch(() => {
        showSnackbar('Возникла ошибка при прикреплении файла из хранилища');
      });
    }

    //при редактировании сообщения, удалить файлы, если юзер решил удалить
    if (messageToEdit && filesDeleteArr.length) {
      const filesRemoveReq = {
        chat_message_id: messageToEdit.chat_message_id,
        files: filesDeleteArr.map((f) => f.id),
      };
      await axios.patch('/api/chat_message_files/remove', filesRemoveReq).catch(() => {
        showSnackbar('Возникла ошибка при удалении файла');
      });
    }

    if (socket && socket.connected) {
      const currentContent = editorState.getCurrentContent();
      const rawEditorContent = convertToRaw(currentContent);
      const plainText = currentContent.getPlainText();
      let messageData = {
        chat_messages: {
          chat_id: chatId,
          message: plainText,
          editor_state: JSON.stringify(rawEditorContent),
          title: messageTitle,
        },
        form_id: form_id.current,

        // прикрепление ссылок к папкам из хранилища, если были добавлены
        chat_message_storage_folders: folderLinks.map((folderLink) => ({
          storage_folder_id: folderLink.storage_folder_id,
          title: folderLink.title,
        })),
      };
      // если есть упоминания юзеров или url-ссылки, добавить в запрос
      const {entityMap} = rawEditorContent;
      const mentionedUserIds = [];
      const links = [];
      for (const entity in entityMap) {
        if (entityMap[entity].type === 'mention') {
          const userId = entityMap[entity].data.mention.userId;
          if (mentionedUserIds.indexOf(userId) === -1) {
            mentionedUserIds.push(userId);
          }
        } else if (entityMap[entity].type === 'LINK') {
          links.push(entityMap[entity].data.url);
        }
      }

      if (mentionedUserIds.length) {
        messageData.chat_message_mentions = mentionedUserIds.map((id) => ({
          employee_id: id,
        }));
      }
      if (links.length) messageData.chat_message_links = links;

      // добавить тэги в запрос, если есть
      if (selectedTags.length) {
        messageData.chat_message_tags = selectedTags.map((tag) => ({
          tag_id: tag.id,
        }));
      }
      // добавить результат к сообщению, если выбран

      if (goalMessage) {
        messageData.chat_goal_results = {};
        taskId
          ? (messageData.chat_goal_results.task_goal_id = goalMessage.id)
          : (messageData.chat_goal_results.project_goal_id = goalMessage.id);
      }
      // добавить цитирование другого сообщения, если выбрана
      if (quoteMessage) {
        messageData.chat_messages.reply_id = quoteMessage.messageId;
        messageData.chat_messages.reply_text = quoteMessage.quoteText;
      } else {
        messageData.chat_messages.reply_id = null;
        messageData.chat_messages.reply_text = null;
      }

      // отправить на редактирование сообщения, если ранее была нажата кнопка "Редактировать" в сообщении
      if (messageToEdit) {
        messageData.chat_message_id = messageToEdit.chat_message_id;
        sendMessageEdit(messageData, reset.current);
      } else {
        sendMessage(messageData, reset.current);
      }
    } else {
      showSnackbar('Нет соединения с сервером', 'fail');
    }
  }, [
    chatId,
    editorState,
    filesToUpload,
    filesFromStorage,
    filesDeleteArr,
    goalMessage,
    isFilesUploading,
    // messageFilesToEdit,
    messageTitle,
    messageToEdit,
    quoteMessage,
    selectedTags,
    sendMessage,
    sendMessageEdit,
    showSnackbar,
    socket,
    taskId,
    folderLinks,
  ]);

  // если все файлы были выгружены на сервер и отправляемое сообщение ожидало отправки файлов,
  // то поставить статус отправки сообщения "готово" с последующим вызовом функции отправки сообщения
  useEffect(() => {
    if (messageSubmitStatus === MESSAGE_SUBMIT_STATUS.pending_files_upload && isFilesUploading === 'completed') {
      setMessageSubmitStatus(MESSAGE_SUBMIT_STATUS.ready_to_send);
    }
  }, [messageSubmitStatus, isFilesUploading]);

  useEffect(() => {
    if (messageSubmitStatus === MESSAGE_SUBMIT_STATUS.ready_to_send) {
      submitMessage();
    }
  }, [messageSubmitStatus, submitMessage]);

  const removeFilesOnClose = useRef();
  const clearFileManager = useRef();

  // валидация для отключения кнопки Отправить
  const validateBeforeSubmit = () => {
    return (
      messageSubmitStatus ||
      (!editorState.getCurrentContent().hasText() &&
        filesToUpload.length === 0 &&
        filesFromStorage.length === 0 &&
        filesToEdit?.every((file) => filesDeleteArr.includes(file)))
    );
  };

  const cancelAll = () => {
    if (typeof removeFilesOnClose.current === 'function') removeFilesOnClose.current();
    reset.current();
  };

  setResetEditor(reset);

  // зарегистрировать функцию для отслеживания paste событий
  useEffect(() => {
    const handlePasteEvent = async (event) => {
      event.preventDefault();
      const filesArr = await getFilesFromDataTransferItems(event.clipboardData.items, showSnackbar);
      if (filesArr?.length) setFilesToUpload(filesArr);
    };
    const chatMessageBox = document.getElementById('chatMessageBox');
    chatMessageBox.addEventListener('paste', handlePasteEvent);

    return () => chatMessageBox.removeEventListener('paste', handlePasteEvent);
  }, []);

  const windowWidth = useWindowWidth();
  const breakpoint = 768;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuMobile, setIsMenuMobile] = useState(false);

  useEffect(() => {
    if (windowWidth >= breakpoint) {
      setIsMenuMobile(false)
      setIsMenuOpen(false);
    } else {
      setIsMenuMobile(true)
    }
  }, [windowWidth]);

  const mobileContextMenu = useRef();
  const mobileContextMenuBtn = useRef();
  const refsArray = useRef([mobileContextMenuBtn, mobileContextMenu]);
  const toggleMenu = useCallback(() => setIsMenuOpen(false), []);

  useOutsideTrigger(refsArray.current, toggleMenu , isMenuOpen);

  return (
    <>
      <div id="chatMessageBox">
        {/* если в сообщении выбран результат, то блок кода ниже отображает этот результат */}
        {goalMessage && (
          <StyledQuoteWrapper>
            <StyledQuoteBody>
              <StyledQuoteDeleteButton icon={iconDelete} onClick={() => setGoalMessage(null)}/>
              <StyledQuote>
                <StyledResultTitle>
                  {t('common.result')}: {goalMessage.description}
                  {goalMessage.type === 'file' ? ` (${t('Goals.file')})` : ` (${t('Goals.text')})`}
                </StyledResultTitle>
              </StyledQuote>
            </StyledQuoteBody>
          </StyledQuoteWrapper>
        )}

        {/* сообщение может быть либо результатом, либо с заголовком */}
        {!goalMessage && titleInput && (
          <StyledChatInput
            type="text"
            placeholder={t('common.title')}
            value={messageTitle}
            onChange={(e) => setChatTitle(e.target.value)}
          />
        )}

        {/* если пишется ответ на другое сообщение, то данный блок показывает сообщение на которое отвечают */}
        {quoteMessage && (
          <StyledQuoteWrapper>
            <StyledQuoteBody>
              <StyledQuoteDeleteButton icon={iconDelete} onClick={() => setQuoteMessage(null)}/>
              <StyledQuote>
                <StyledQuoteName>{quoteMessage.author}</StyledQuoteName>{' '}
                <StyledQuoteDate> ({quoteMessage.dateTime})</StyledQuoteDate>
                <p>{quoteMessage.quoteText}</p>

                {quoteMessage.messageFiles.length > 0 ?
                  <>
                    <IconButton
                      icon={iconFile}
                      size={16}
                      style={{marginRight: '8px'}}
                    />
                    Прикрепленные файлы ({quoteMessage.messageFiles.length})
                  </>
                  : ''
                }
              </StyledQuote>
            </StyledQuoteBody>
          </StyledQuoteWrapper>
        )}

         {/*если пользователь редактирует сообщение, то данный блок показывает исходный вариант текста*/}
        {messageToEdit && (
          <StyledQuoteWrapper>
            <StyledQuoteBody>
              <StyledQuoteDeleteButton icon={iconDelete} onClick={() => editMessage(null)}/>
              <StyledQuote>
                <StyledQuoteName>Редактируемое сообщение</StyledQuoteName>
                <p>{plainEditText}</p>
                {messageToEdit.message_files.length > 0 ?
                  <>
                    <IconButton
                      icon={iconFile}
                      size={16}
                      style={{marginRight: '8px'}}
                    />
                    Прикрепленные файлы ({messageToEdit.message_files.length})
                  </>
                  : ''
                }
              </StyledQuote>
            </StyledQuoteBody>
          </StyledQuoteWrapper>
        )}

        <StyledChatEditorWrapper>
          <StyledChatMobileMenu ref={mobileContextMenu}>
            <StyledChatContextMobile $active={isMenuOpen}>
              <ChatTextEditorBottomMenu
                toggleTitleInput={toggleTitleInput}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                setStylingToolbar={setStylingToolbar}
                filesUploadDisabled={filesUploadDisabled}
                setDisableChatFilesDrop={setDisableChatFilesDrop}
                storageId={storageId}
                addStorageFileReference={addStorageFileReference}
                attachFolderReference={attachFolderReference}
                setFilesToUpload={setFilesToUpload}
                goals={goals}
                setGoalMessage={setGoalMessage}
                isMenuMobile={isMenuMobile}
                setIsMenuOpen={setIsMenuOpen}
              />
            </StyledChatContextMobile>
            <StyledMenuButtonMobile
              icon={iconDots}
              size={22}
              title="Открыть меню"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              ref={mobileContextMenuBtn}
            />
          </StyledChatMobileMenu>

          {/* редактор draft-js с функционалом по отмечанию других юзеров */}
          <StyledChatTextEditor>
            <EditorWithMentions
              editorState={editorState}
              setEditorState={setEditorState}
              handleKeyCommand={handleKeyCommand}
              quoteMessage={quoteMessage}
              editorRef={editorRef}
              containerRef={containerRef}
              utilsVisible={stylingToolbar}
            />
          </StyledChatTextEditor>
          <StyledSendButtonMobile icon={iconSend} onClick={submitMessage} disabled={validateBeforeSubmit()}/>
        </StyledChatEditorWrapper>
      </div>

      {/* показывает, если прикреплены файлы к сообщению */}
      {(folderLinks.length > 0 ||
        filesToUpload?.length > 0 ||
        messageToEdit?.message_files.length > 0 ||
        filesFromStorage.length > 0) && (
          <ScrollWrapper>
            <AttachWrapper>
              {folderLinks.map((folderData, i) => (
                <FolderLink key={i} folderData={folderData} removable handleDelete={removeFolderReference}/>
              ))}

              {filesFromStorage.map((f, i) => (
                <FileToUpload
                  file={f}
                  progressPercent={100}
                  isUploaded
                  removeFile={() => {
                    setFilesFromStorage((files) => files.filter((file, index) => index !== i));
                  }}
                  key={i}
                  index={i}
                  isFromStorage
                />
              ))}

              <FilesUploadManage
                formId={form_id.current}
                filesToUpload={filesToUpload}
                filesToEdit={filesToEdit}
                addFilesFormDataTemplate={addFilesFormDataTemplate}
                filesDeleteArr={filesDeleteArr}
                setFilesDeleteArr={setFilesDeleteArr}
                filesUploadPath="/api/chat_message_files/add"
                filesDeletePath="/api/chat_message_files/remove"
                setIsFilesUploading={setIsFilesUploading}
                removeFilesOnClose={removeFilesOnClose}
                removeById
                // showStorage={showStorage}
                clearFileManager={clearFileManager}
              />
            </AttachWrapper>
          </ScrollWrapper>
      )}

      {/* показывает если выбраны тэги  */}
      {selectedTags.length > 0 && (
        <AttachWrapper>
          {selectedTags.map((tag, i) => (
            <AttachTag key={i}>
              <StyledTag color={tag.color}>{tag.name}</StyledTag>
              <DeleteCircleButton onClick={() => setSelectedTags(selectedTags.filter((t) => t.id !== tag.id))}/>
            </AttachTag>
          ))}
        </AttachWrapper>
      )}

      {/* кнопки отправления/отмены сообщения и доп. функционала редактора */}
      <StyledChatFooterRow>
        <StyledChatFooterCol>
          <SubmitButton style={{hyphens: 'none'}} onClick={submitMessage} disabled={validateBeforeSubmit()}>
            {messageToEdit
              ? t(`FormButtons.${messageSubmitStatus ? 'saving' : 'save'}`)
              : t(`FormButtons.${messageSubmitStatus ? 'sending' : 'send'}`)}
          </SubmitButton>

          {/* показывать кнопку Отмена, если сообщение редактируется */}
          {(messageToEdit || isFilesUploading) && (
            <Button color="light" onClick={cancelAll}>
              {t(`FormButtons.cancel`)}
            </Button>
          )}
        </StyledChatFooterCol>
        {!isMenuMobile &&
          <StyledChatContextWrapper>
            <ChatTextEditorBottomMenu
              toggleTitleInput={toggleTitleInput}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              setStylingToolbar={setStylingToolbar}
              filesUploadDisabled={filesUploadDisabled}
              setDisableChatFilesDrop={setDisableChatFilesDrop}
              storageId={storageId}
              addStorageFileReference={addStorageFileReference}
              attachFolderReference={attachFolderReference}
              setFilesToUpload={setFilesToUpload}
              goals={goals}
              setGoalMessage={setGoalMessage}
            />
          </StyledChatContextWrapper>
        }
      </StyledChatFooterRow>
    </>
  );
};

export default memo(ChatTextEditor);
