import { StyledText } from '@shared/components/Text/style';
import styled from 'styled-components';

export const StyledUserName = styled(StyledText)`
  font-weight: 500;
  transition: all 0.3s;
  padding-top: 2px;
  margin-left: -2px;

  &:hover {
    color: ${({ theme }) => theme.main};
  }
`;
