import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { url_get_employees_list, url_get_emplyee_info } from 'src/settings/base-url';

export const logoutAction = createAction('auth/logout');

const initialState = {
  departments: null,
  employees: null,
  onlineEmployees: null,
  unavailableEmployees: [],
  positions: null,
  vacationsList: null,
  isLoading: false,
  isLoadingVacations: null,
  vacationsLoadingError: null,
  isUpdatingUser: false,
  error: null,
};

export const getUsers = createAsyncThunk('users/getUsers', async (payload, thunkAPI) => {
  try {
    const users = await axios.get(url_get_employees_list);
    return users.data.employeesList;
  } catch (e) {
    return thunkAPI.rejectWithValue('Error while fetching users');
  }
});

export const getOnlineUsers = createAsyncThunk('users/getOnlineUsers', async (payload, thunkAPI) => {
  try {
    const response = await axios.get('/api/online_employees');
    return response.data.onlineEmployees;
  } catch (e) {
    return thunkAPI.rejectWithValue('Error while fetching users');
  }
});

export const getUnavailableUsers = createAsyncThunk('users/getUnavailableUsers', async (payload, thunkAPI) => {
  try {
    const response = await axios.get('/api/employee_unavailable');
    return response.data.employeeUnavailable;
  } catch (e) {
    return thunkAPI.rejectWithValue('Ошибка при получении недоступных пользователей');
  }
});

export const updateUser = createAsyncThunk('users/updateUser', async (userId, thunkAPI) => {
  try {
    const response = await axios.get(`${url_get_emplyee_info}/${userId}`);
    return response.data.employeeInfo.employee[0];
  } catch (e) {
    return thunkAPI.rejectWithValue('Error while updating user');
  }
});

export const getVacationsList = createAsyncThunk('users/getVacationsList', async (payload, thunkAPI) => {
  try {
    const response = await axios.get('/api/employee_vacations_list');
    return response.data.employee_vacations;
  } catch (e) {
    return thunkAPI.rejectWithValue('Ошибка при загрузке списка отпусков');
  }
});

export const processVacationRequest = createAsyncThunk('users/processVacationRequest', async (payload, thunkAPI) => {
  try {
    await axios.patch('/api/approve_employee_vacation_request', {
      approved: payload.decision,
      employee_vacation_id: payload.vacationId,
    });
    thunkAPI.dispatch(getVacationsList());
    return;
  } catch (e) {
    return thunkAPI.rejectWithValue('Ошибка при обработке запроса на отпуск');
  }
});

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    userConnected: (state, { payload }) => {
      state.onlineEmployees?.push({ id: payload });
    },
    userDisconnected: (state, { payload }) => {
      if (payload === 'all') state.onlineEmployees = null;
      else {
        const index = state.onlineEmployees?.findIndex((user) => user.id === payload);
        if (index > -1) {
          state.onlineEmployees.splice(index, 1);
        }
      }
    },
    setUnavailableEmployees: (state, { payload }) => {
      // если массив недоступных юзеров не соответствует сохраненному ранее списку, то обновить state
      if (
        state.unavailableEmployees?.length !== payload.length ||
        payload.some((data, i) => data.id !== state.unavailableEmployees[i]?.id)
      ) {
        state.unavailableEmployees = payload;
      }
    },
    clearErrors: (state) => {
      state.error = null;
    },
  },

  extraReducers: {
    [getUsers.pending]: (state) => {
      state.isLoading = true;
    },
    [getUsers.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.employees = payload.employees;
      state.departments = payload.departments;
      state.positions = payload.positions;
    },
    [getUsers.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getOnlineUsers.fulfilled]: (state, { payload }) => {
      state.onlineEmployees = payload;
    },

    [getOnlineUsers.rejected]: () => {},

    [getUnavailableUsers.fulfilled]: (state, { payload }) => {
      state.unavailableEmployees = payload;
    },

    [getUnavailableUsers.rejected]: () => {},

    [updateUser.pending]: (state) => {
      state.isUpdatingUser = true;
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.isUpdatingUser = false;
      if (payload) {
        const ind = state.employees?.findIndex((user) => user.id === payload.id);

        if (ind !== -1) {
          state.employees[ind] = payload;
        }
      }
    },
    [updateUser.rejected]: (state) => {
      state.isUpdatingUser = false;
    },
    [getVacationsList.pending]: (state) => {
      state.isLoadingVacations = true;
      state.vacationsLoadingError = null;
    },
    [getVacationsList.fulfilled]: (state, { payload }) => {
      state.vacationsList = payload;
      state.isLoadingVacations = false;
    },
    [getVacationsList.rejected]: (state, { payload }) => {
      state.isLoadingVacations = false;
      state.vacationsLoadingError = payload;
    },
    [processVacationRequest.pending]: (state) => {
      state.isLoadingVacations = true;
      state.vacationsLoadingError = null;
    },
    [processVacationRequest.rejected]: (state, { payload }) => {
      state.isLoadingVacations = false;
      state.vacationsLoadingError = payload;
    },
    [logoutAction]: (state) => {
      state.errors = null;
    },
  },
});

export const { userConnected, userDisconnected, clearErrors, setUnavailableEmployees } = usersSlice.actions;

export default usersSlice.reducer;
