import {useCallback, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import SelectTagPopover from '@components/tags/SelectTagPopover';
import {useOutsideTrigger} from 'src/utilize/helper-functions';
import StorageModal from 'src/windows/storage/StorageModal';
import CreatePollModal from 'src/windows/polls/CreatePollModal';
import {
  StyledChatButtonWrapper,
  StyledChatFooterCol,
  StyledChatMenu,
  StyledChatMenuItem, StyledMenu, StyledMenuHeader, StyledMenuItem, StyledMenuTitle, StyledMenuType
} from "@components/chat/styles";
import {IconButton} from "@shared/components";
import iconDots from '@assets/images/icons/subtask-dots.svg';
import iconBold from '@assets/images/icons/chat-richtext.svg';
//import iconEvent from '@assets/images/icons/chat-event.svg';
import iconTags from '@assets/images/icons/chat-tags.svg';
import iconStorage from '@assets/images/icons/chat-storage.svg';
import iconAttach from '@assets/images/icons/chat-attach.svg';
import iconGoal from '@assets/images/icons/chat-goal.svg';
import iconTitle from '@assets/images/icons/title.svg';

const ChatTextEditorBottomMenu = ({
                                    toggleTitleInput,
                                    setStylingToolbar,
                                    selectedTags,
                                    setSelectedTags,
                                    filesUploadDisabled,
                                    setDisableChatFilesDrop,
                                    storageId,
                                    addStorageFileReference,
                                    attachFolderReference,
                                    setFilesToUpload,
                                    goals,
                                    setGoalMessage,
                                    isMenuMobile,
                                    setIsMenuOpen
                                  }) => {
  const [chatOption, setChatOption] = useState();
  const tagsList = useSelector((state) => state.tags.tagsList);
  const [showStorage, setShowStorage] = useState();
  const [pollModal, setPollModal] = useState();

  // react-ссылки на контекстное меню текстового редактора (всплывашки)
  const dotsOptionRef = useRef();
  const dotsButtonRef = useRef();
  const tagsOptionRef = useRef();
  const tagsButtonRef = useRef();
  const goalsOptionRef = useRef();
  const goalsButtonRef = useRef();

  const popupRefsArray = useRef([
    dotsOptionRef,
    dotsButtonRef,
    tagsButtonRef,
    tagsOptionRef,
    goalsOptionRef,
    goalsButtonRef,
  ]);

  const clearChatOption = useCallback(() => setChatOption(null), []);

  // закрывает контекстное меню чата при клике вне меню
  useOutsideTrigger(popupRefsArray.current, clearChatOption, chatOption);

  const openFileSelectPopup = () => {
    document.getElementById('upfile').click();
  };

  return (
    <>

      {/* кнопка с троеточием */}
      {/*<StyledChatButtonWrapper>*/}
      {/*  <IconButton*/}
      {/*    icon={iconDots}*/}
      {/*    size={18}*/}
      {/*    title="Другие действия"*/}
      {/*    onClick={() => setChatOption(chatOption === 'dots' ? null : 'dots')}*/}
      {/*    ref={dotsButtonRef}*/}
      {/*  />*/}
      {/*  <StyledChatMenu $active={chatOption === 'dots'} ref={dotsOptionRef}>*/}
      {/*    <StyledChatMenuItem*/}
      {/*      */}
      {/*    >*/}
      {/*      Сообщение с заголовком*/}
      {/*    </StyledChatMenuItem>*/}
      {/*    /!* <StyledChatMenuItem onClick={() => setPollModal(true)}>*/}
      {/*        Добавить опрос*/}
      {/*      </StyledChatMenuItem> *!/*/}
      {/*    /!* <StyledChatMenuItem>{t('FormButtons.add_client_contact')}</StyledChatMenuItem> *!/*/}
      {/*  </StyledChatMenu>*/}
      {/*</StyledChatButtonWrapper>*/}

      {/*<StyledChatButtonWrapper>*/}
      {/*  <IconButton*/}
      {/*    icon={iconEvent}*/}
      {/*    size={18}*/}
      {/*    title="Событие"*/}
      {/*  />*/}
      {/*</StyledChatButtonWrapper>*/}


      {/*Сообщение с заголовком*/}
      <StyledChatButtonWrapper>
        <IconButton
          icon={iconTitle}
          size={28}
          title="Сообщение с заголовком"
          onClick={() => {
            toggleTitleInput((titleInput) => !titleInput);
            setChatOption(null);
            isMenuMobile && setIsMenuOpen(false);
          }}
        ></IconButton>
      </StyledChatButtonWrapper>
      {/* кнопка форматирования текста */}
      <StyledChatButtonWrapper>
        <IconButton
          icon={iconBold}
          size={18}
          title="Форматировать"
          onClick={() => {
            setStylingToolbar((stylingToolbar) => !stylingToolbar);
            isMenuMobile && setIsMenuOpen(false);
        }}
        ></IconButton>
      </StyledChatButtonWrapper>

      <StyledChatButtonWrapper>
        <IconButton
          icon={iconTags}
          size={18}
          title="Добавить тэги"
          onClick={() => {
            setChatOption(chatOption === 'tags' ? null : 'tags')
          }}
          ref={tagsButtonRef}
        ></IconButton>
        <SelectTagPopover
          tagsList={tagsList}
          chatOption={chatOption}
          tagsOptionRef={tagsOptionRef}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          isMenuMobile={isMenuMobile}
        />
      </StyledChatButtonWrapper>

      {/* кнопка открытия хранилища */}
      {!filesUploadDisabled && (
        <StyledChatButtonWrapper>
          <IconButton
            icon={iconStorage}
            size={18}
            title="Открыть файловое хранилище"
            onClick={() => {
              setShowStorage(true);
              if (setDisableChatFilesDrop) setDisableChatFilesDrop(true);
            }}
          ></IconButton>
        </StyledChatButtonWrapper>
      )}

      {/* кнопка прикрепления файлов */}
      {!filesUploadDisabled && (
        <StyledChatButtonWrapper>
          <IconButton
            icon={iconAttach}
            size={18}
            title="Добавить файлы"
            onClick={openFileSelectPopup}
          >
            <input
              type="file"
              multiple
              id="upfile"
              style={{display: 'none'}}
              onChange={(e) => setFilesToUpload([...e.target.files])}
            />
          </IconButton>
        </StyledChatButtonWrapper>
      )}

      {/* кнопка выбора результата */}
      {goals?.length > 0 && (
        <StyledChatButtonWrapper>
          <IconButton
            icon={iconGoal}
            size={18}
            title="Пометить как результат выполнения проекта (задачи)"
            onClick={() => setChatOption(chatOption === 'goals' ? null : 'goals')}
            ref={goalsButtonRef}
          />

          <StyledMenu
            $active={chatOption === 'goals'}
            ref={goalsOptionRef}
          >
            {goals?.map((goal, i) => {
              return (
                <StyledMenuItem
                  key={i}
                  onClick={() => {
                    setGoalMessage(goal);
                    setChatOption(null);
                  }}
                >
                  <StyledMenuHeader>
                    <StyledMenuTitle>{goal.description}</StyledMenuTitle>
                    <StyledMenuType>{goal.type === 'text' ? 'Текст' : 'Файл'}</StyledMenuType>
                  </StyledMenuHeader>
                </StyledMenuItem>
              );
            })}
          </StyledMenu>
        </StyledChatButtonWrapper>
      )}

      {showStorage && (
        <StorageModal
          noURLParams
          close={() => {
            setShowStorage(false);
            if (setDisableChatFilesDrop) setDisableChatFilesDrop(false);
          }}
          storageId={storageId}
          addStorageFileReference={addStorageFileReference}
          attachFolderReference={attachFolderReference}
        />
      )}

      {pollModal && (
        <CreatePollModal close={() => setPollModal(false)}/>
      )}
    </>
  );
};

export default ChatTextEditorBottomMenu;
