import { createContext, useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ProjectStructureContext = createContext({
  selectedTask: null,
  setSelectedTask: null,
});

const ProjectStructureContextProvider = ({ children }) => {
  const [selectedTask, setSelectedTask] = useState(null);
  const { taskId } = useParams();

  useEffect(() => {
    if (taskId) {
      setSelectedTask(+taskId);
    }
  }, [taskId]);

  return (
    <ProjectStructureContext.Provider value={{ selectedTask, setSelectedTask }}>
      {children}
    </ProjectStructureContext.Provider>
  );
};

const useProjectStructureContext = () => {
  const context = useContext(ProjectStructureContext);
  if (context) {
    return context;
  }
  throw new Error('useProjectStructureContext must be used within a ProjectStructureContextProvider');
};

export { ProjectStructureContextProvider, useProjectStructureContext };
