import ModalPortal from '@shared/components/Modal/ModalPortal';
import Preloader from '@components/preloaders/Preloader';
import { Modal } from '@shared/components/Modal/Modal';
import React from 'react';
import { TicketsContainer } from 'src/pages/PageTickets/PageTicketsForMe/styles';

import { useGetTicketsQuery } from 'src/redux/features/api/tickets';

import { useNavigate } from 'react-router-dom';

import { TicketCard } from '../TicketCard';

export const TicketsForMeModal = ({ onClose }) => {
  const { data: ticketsData, isLoading } = useGetTicketsQuery();
  const navigate = useNavigate();

  const filteredTickets = ticketsData?.tickets_for_me.filter(
    (ticket) => ticket.ticket_actions[0].action === 'created' || ticket.ticket_actions[0].action === 'returned',
  );

  return (
      <Modal type="submit" modalSize="767px" title="Поручения мне" onClose={onClose} withoutButtons>
        {isLoading ? (
          <Preloader />
        ) : (
          <TicketsContainer>
            {filteredTickets &&
              filteredTickets.map((ticket, index) => (
                <TicketCard
                  key={index}
                  ticket={ticket}
                  onClick={() => {
                    onClose();
                    navigate(`/tickets/me?id=${ticket.id}`);
                  }}
                />
              ))}
          </TicketsContainer>
        )}
      </Modal>
  );
};
