import { StyledTextarea } from './style';

export const Textarea = ({ rows, error, type, value, placeholder, onChange, register, disabled, ...props }) => {
  const onChangeHandler = (evt) => {
    if (typeof onChange !== 'function') return;
    onChange(evt.currentTarget.value);
  };

  return (
    <StyledTextarea
      as="textarea"
      rows={rows}
      error={error}
      type={type}
      value={value}
      onChange={onChangeHandler}
      placeholder={placeholder}
      disabled={disabled}
      {...register}
      {...props}
    />
  );
};
