import { memo } from 'react';
import { bool, number, func, object, arrayOf } from 'prop-types';
import { useSelector } from 'react-redux';
import { StyledChatFooter, StyledScrollButton } from '@components/chat/styles';

import ChatTextEditorContainer from '../text-editor/ChatTextEditorContainer';

import UserTextingListener from './UserTextingListener';

SingleProjectChatFooterFC.propTypes = {
  scrollToEndButton: bool,
  requestedOldMessageId: number,
  resetToLatestMessages: func,
  scrollToLastMessage: func,
  projectData: object,
  draggedFiles: arrayOf(object),
  setDisableChatFilesDrop: func,
};

function SingleProjectChatFooterFC({
  scrollToEndButton,
  requestedOldMessageId,
  resetToLatestMessages,
  scrollToLastMessage,
  projectData,
  draggedFiles,
  setDisableChatFilesDrop,
}) {
  const isSelectMode = useSelector((state) => state.messages.isSelectMode);

  return (
    <>
      {projectData && !isSelectMode && (
        <StyledChatFooter>
          {/* скрывать/показывать кнопку скролла чата на самое актуальное состояние чата */}
          {scrollToEndButton && (
            <StyledScrollButton
              onClick={() => {
                requestedOldMessageId ? resetToLatestMessages() : scrollToLastMessage();
              }}
            />
          )}
          <UserTextingListener chatId={projectData.chat?.id} />
          <ChatTextEditorContainer
            draggedFiles={draggedFiles}
            chatId={projectData.chat?.id}
            goals={projectData.project_goals || projectData.task_goals}
            storageId={projectData.storage_id}
            setDisableChatFilesDrop={setDisableChatFilesDrop}
          />
        </StyledChatFooter>
      )}
    </>
  );
}

const SingleProjectChatFooter = memo(SingleProjectChatFooterFC);

export default SingleProjectChatFooter;
