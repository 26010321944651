import { useState, useContext } from 'react';
// import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { object, string, date, array } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Label, Input, SingleSelect, StyledModalRowEnd } from '@shared/components';

import { useEventTriggerOnEscPress } from '../../utilize/helper-functions';
import ConfirmAction from '../../components/warnings/ConfirmAction';
import SnackbarContext from '../../contexts/SnackbarContext';

import PollTypeRadioButtons from './PollTypeRadioButtons';
import PollDeadline from './PollDeadline';
import PollTargetRadioButtons from './PollTargetRadioButtons';
import PollTarget from './PollTarget';
import PollOptions from './PollOptions';

const formSchema = object({
  title: string().required(),
  type: object().required(),
  answers_type: object().required(),
  deadline_type: object().required(),
  deadline: date().nullable(),
  response_options: array().required(),
});

const CreatePollModal = ({ close, editData }) => {
  const [modalClosePrompt, setModalClosePrompt] = useState();

  const {
    register,
    control,
    // watch,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      type: 'anonymous',
      poll_target: 'chat_members',
      deadline_type: { label: 'Не ограничено', value: 'unlimited' },
      poll_options: [],
    },
  });

  const handleCloseBtnClick = () => {
    if (modalClosePrompt) return;
    if (isDirty) {
      return setModalClosePrompt(true);
    }
    close();
  };

  useEventTriggerOnEscPress(handleCloseBtnClick);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showSnackbar } = useContext(SnackbarContext);

  const submitData = () => {};

  return (
    <Modal
      title={`${editData ? 'Редактировать' : 'Добавить голосование в сообщение'}`}
      onClose={handleCloseBtnClick}
      confirmButtonText={`${editData ? 'Сохранить' : 'Добавить'}`}
      disabledSaveButton={!isDirty || !isValid || isSubmitting}
      onSave={handleSubmit(submitData)}
    >
      <div>
        <Label htmlFor="name">
          Название голосования<sup>*</sup>
        </Label>
        <Input id="name" register={register('title')} />
      </div>

      <div>
        <Label htmlFor="type">Тип опроса</Label>
        <Controller
          control={control}
          name="type"
          render={({ field }) => {
            return <PollTypeRadioButtons value={field.value} onChange={field.onChange} />;
          }}
        />
      </div>

      <StyledModalRowEnd justify="space-between" wrap="wrap">
        <div>
          <Label>Вариантов ответа</Label>
          <SingleSelect
            defaultValue={{ label: 'Один', value: 'single' }}
            // width="270.5px"
            options={[
              { label: 'Один', value: 'single' },
              { label: 'Несколько', value: 'multiple' },
            ]}
          />
        </div>
        <div>
          <Label>Время на ответы</Label>
          <Controller
            name="deadline_type"
            control={control}
            render={({ field }) => {
              return (
                <SingleSelect
                  value={field.value}
                  onChange={(v) => field.onChange(v)}
                  options={[
                    { label: 'Не ограничено', value: 'unlimited' },
                    { label: 'Ограничено', value: 'limited' },
                  ]}
                />
              );
            }}
          />
        </div>
      </StyledModalRowEnd>
      <PollDeadline control={control} />

      <PollOptions control={control} register={register} />

      <div>
        <Label htmlFor="poll_target">Кого опрашиваем</Label>
        <Controller
          control={control}
          name="poll_target"
          render={({ field }) => {
            return <PollTargetRadioButtons value={field.value} onChange={field.onChange} />;
          }}
        />
        <PollTarget control={control} />
      </div>

      {modalClosePrompt && (
        <ConfirmAction
          confirm={close}
          cancel={() => setModalClosePrompt(false)}
          actionText="Уверены что хотите закрыть окно, не сохранив изменения?"
        />
      )}
    </Modal>
  );
};

export default CreatePollModal;
