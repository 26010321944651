import { StyledLayout } from '@shared/components/Layout/styles';
import React, { useEffect, useMemo, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import Pagination from '@shared/components/Pagination';

import { CancelTicketModal, CreateTicketModal, Tabs, TicketInfoModal } from '@components/Tickets/TicketsFromMe';

import { TicketCard } from '@components/Tickets/TicketsFromMe/TicketCard';

import Preloader from '@components/preloaders/Preloader';

import { EditTicketModal } from '@components/Tickets/TicketsFromMe/EditTicketModal';

import { omit } from '@shared/utils/omit';
import { ticketButtons } from '@shared/components/Tabs/nameSpace';

import { Placeholder } from '@shared/components/Placeholder';
import iconAdd from '@assets/images/icons/plus.svg';
import { ButtonWithIcon } from '@shared/components';

import { StyledSubheader, StyledTitle, StyledTitleWrapper, TicketsContainer } from './styles';

import { useLocalModel } from './model';

export const PageTickets = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [visible, setVisible] = useState(false);
  const [ticketModalVisible, setTicketModalVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [cancelVisible, setCancelVisible] = useState(false);

  const toggleModal = () => setVisible((prev) => !prev);

  const { getActiveTickets, getCompletedTickets } = useLocalModel();

  const ticketId = useMemo(() => searchParams.get('id'), [searchParams]);
  const currentFilter = useMemo(() => searchParams.get('filter'), [searchParams]);
  const currentPage = useMemo(() => searchParams.get('page'), [searchParams]);

  const queryParams = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);

  const { data: activeTickets, isLoading: isActiveTicketsLoading } = getActiveTickets({
    page: currentPage || 1,
    limit: 10,
  });
  const { data: completedTickets, isLoading: isCompletedTicketsLoading } = getCompletedTickets({
    page: currentPage || 1,
    limit: 10,
  });

  const hasData = useMemo(
    () =>
      (currentFilter === 'active' && !!activeTickets?.my_active_tickets.length) ||
      (currentFilter === 'completed' && !!completedTickets?.my_completed_tickets?.length),
    [activeTickets, completedTickets, currentFilter],
  );

  useEffect(() => {
    setSearchParams({ ...queryParams, page: 1, filter: currentFilter ? currentFilter : 'active' }, { replace: true });
  }, []);

  useEffect(() => {
    if (!!ticketId) {
      setTicketModalVisible(true);
    }
  }, [ticketId]);

  return (
    <>
      <StyledLayout>
        <StyledTitleWrapper>
          <StyledTitle>Поручения от меня</StyledTitle>
          <ButtonWithIcon color="green" position="left" icon={iconAdd} size={20} onClick={toggleModal}>
            Дать поручение
          </ButtonWithIcon>
        </StyledTitleWrapper>

        <StyledSubheader>
          <Tabs
            defaultValue={ticketButtons.filter((tab) => tab.key === queryParams.filter)[0] || ticketButtons[0]}
            buttons={ticketButtons}
            onChange={(tab) => {
              setSearchParams({ ...queryParams, filter: tab.key, page: 1 });
            }}
          />
        </StyledSubheader>
        {isActiveTicketsLoading && isCompletedTicketsLoading ? (
          <Preloader />
        ) : (
          <>
            <TicketsContainer>
              {activeTickets &&
                currentFilter === 'active' &&
                activeTickets.my_active_tickets.map((ticket) => {
                  return (
                    <TicketCard
                      key={ticket.id}
                      ticket={ticket}
                      onClick={() => setSearchParams({ ...queryParams, id: ticket.id }, { replace: true })}
                    />
                  );
                })}
              {completedTickets &&
                currentFilter === 'completed' &&
                completedTickets.my_completed_tickets.map((ticket) => {
                  return (
                    <TicketCard
                      key={ticket.id}
                      ticket={ticket}
                      onClick={() => setSearchParams({ ...queryParams, id: ticket.id }, { replace: true })}
                    />
                  );
                })}
            </TicketsContainer>
            {!hasData && <Placeholder />}

            {hasData && (
              <Pagination
                id={currentFilter}
                totalPages={currentFilter === 'active' ? activeTickets?.page_count : completedTickets?.page_count}
                onChange={(page) => setSearchParams({ ...queryParams, page })}
              />
            )}
          </>
        )}
      </StyledLayout>

      {visible && <CreateTicketModal onClose={toggleModal} />}
      {ticketModalVisible && (
        <TicketInfoModal
          ticketId={ticketId}
          onClose={() => {
            setTicketModalVisible(false);
            setSearchParams(omit(queryParams, 'id'));
          }}
          onEditTicket={() => {
            setVisible(false);
            setEditVisible(true);
          }}
          onCancelTicket={() => {
            setVisible(false);
            setCancelVisible(true);
          }}
        />
      )}
      {editVisible && <EditTicketModal ticketId={ticketId} onClose={() => setEditVisible(false)} />}
      {cancelVisible && (
        <CancelTicketModal
          id={ticketId}
          onClose={() => {
            setTicketModalVisible(false);
            setCancelVisible(false);
          }}
        />
      )}
    </>
  );
};
