import {Progress, ProgressFill} from "@components/files/styles";

const ProgressBar = ({ progressPercent, isRemoving, isUploaded, errorUploading }) => {
  const uploadStatusColor = () => {
    if (isRemoving || errorUploading) return 'error';
    if (isUploaded) return 'complete';
    return '';
  };

  return (
    <Progress>
      <ProgressFill state={uploadStatusColor()} style={{ width: `${progressPercent || 0}%` }} />
    </Progress>
  );
};

export default ProgressBar;
