import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import Preloader from 'src/components/preloaders/Preloader';
import SnackbarContext from 'src/contexts/SnackbarContext';
import NewsBlogItem from 'src/components/home/NewsBlogItem';
import { checkAuthError } from 'src/utilize/helper-functions';
import useLogout from 'src/hooks/useLogout';

import arrow from '@assets/images/icons/index-arrow.svg';

import ProjectListItem from '../Projects/project-list/project-list-container/ProjectListItem';

import {
  StyledIndexLayout,
  StyledIndexSection,
  StyledNoScroll,
  StyledPostWrapper,
  StyledProjectScroll,
  StyledProjectWrapper,
  StyledTitle,
  StyledTitleWrapper,
} from './styles';

const Home = () => {
  const { showSnackbar } = useContext(SnackbarContext);

  const [summary, setSummary] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const logout = useLogout();

  useEffect(() => {
    axios
      .get('/api/main_page')
      .then((r) => {
        setSummary(r.data.mainPageData);
      })
      .catch((error) => {
        if (checkAuthError(error)) logout();
        else showSnackbar('Возникла ошибка при загрузке данных');
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <StyledNoScroll>
      {isLoading && <Preloader />}
      {summary && (
        <StyledIndexLayout>
          <StyledIndexSection priority={1}>
            <StyledTitleWrapper>
              <StyledTitle to="/news" icon={arrow}>
                Новости
              </StyledTitle>
            </StyledTitleWrapper>
            <StyledPostWrapper>
              {summary.news.map((newsItem, i) => (
                <NewsBlogItem newsBlogItem={newsItem} key={i} type="news" />
              ))}
            </StyledPostWrapper>
          </StyledIndexSection>
          <StyledIndexSection priority={2}>
            <StyledTitleWrapper>
              <StyledTitle to="/blogs" icon={arrow}>
                Блог
              </StyledTitle>
            </StyledTitleWrapper>
            <StyledPostWrapper>
              {summary.blogs.map((blog, i) => (
                <NewsBlogItem newsBlogItem={blog} key={i} type="blogs" />
              ))}
            </StyledPostWrapper>
          </StyledIndexSection>
          <StyledIndexSection priority={5}>
            <StyledTitleWrapper>
              <StyledTitle to="/projects" icon={arrow}>
                Проекты
              </StyledTitle>
            </StyledTitleWrapper>
            <StyledProjectScroll>
              <StyledProjectWrapper>
                {summary.projects.map((project, i) => (
                  <ProjectListItem item={project} key={i} />
                ))}
              </StyledProjectWrapper>
              <StyledTitle to="/projects" icon={arrow} margin="20px 0">
                Все проекты
              </StyledTitle>
            </StyledProjectScroll>
          </StyledIndexSection>
        </StyledIndexLayout>
      )}
    </StyledNoScroll>
  );
};

export default Home;
