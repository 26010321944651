import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';

import { Label } from '@shared/components/Label/Label';

import { StyledCalendar, StyledCalendarError, StyledCalendarIcon } from '@shared/components/CalendarForm/styles';

import calendarIcon from '@assets/images/icons/calendar.svg';

// форматируем дату для  отправки на сервер, эту функцию следует импортировать и исп при отправке формы
export const getFormatCalendarData = (date) => {
  if (!date) return null;
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
};

//  удаляем лишнее, что приходит с сервера с датой (время и тд) через слэш 2020/12/23
const formatDateforPicker = (dateString) => {
  let dateToReturn = '';
  if (dateString) {
    const formattedDate = new Date(dateString.substr(0, 10).replace(/-/g, '/'));
    if (formattedDate instanceof Date && !isNaN(formattedDate)) dateToReturn = formattedDate;
  }
  return dateToReturn;
};
//   через точку  12.12.2020
export const formatDateWithDots = (dateString) => {
  if (!dateString) return '';
  return dateString.substr(0, 10).replace(/-/g, '.').split('.').reverse().join(`.`);
};
const CalendarForm = (props) => {
  const { t } = useTranslation();
  const { data, control, prev, disabled } = props;
  // если известна дата, которую нужно отобразить

  // если выдает ошибку в календаре при  редактировании - ошибка из-за ручной отправки формы, на продакшне такого не будет. приходит неправильный формат, когда писали тестовые данные типа 0000 000 в дате
  const [startDate, setStartDate] = useState(prev ? formatDateforPicker(prev) : null);
  const handlerChange = (date, field) => {
    field.onChange(date);
    setStartDate(date);
  };

  return (
    <div>
      <Label htmlFor="date">
        {data.label} {data.isRequired && <sup>*</sup>}{' '}
      </Label>

      <Controller
        control={control}
        name={data.name}
        render={({ field, fieldState }) => {
          return (
            <>
              <StyledCalendar $invalid={fieldState.invalid}>
                <DatePicker
                  disabled={disabled}
                  placeholderText={data.placeholder}
                  onChange={(date) => {
                    handlerChange(date, field);
                  }}
                  selected={prev ? startDate : field.value}
                  // selected={null}
                  dateFormat="dd.MM.yyyy"
                  minDate={props.minDate}
                />
                <StyledCalendarIcon icon={calendarIcon} />
              </StyledCalendar>
              {fieldState.invalid && <StyledCalendarError>{t('ErrorMessages.required_field')}</StyledCalendarError>}
            </>
          );
        }}
      />
    </div>
  );
};

export default CalendarForm;
