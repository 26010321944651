import { Fragment } from 'react';

import { StyledRadioWrapper } from './style';

// если параметр direction не указан, по умолчанию будет 'row' (расположение по горизонтали)
export const Radio = ({ items, direction, ...props }) => {
  return (
    <StyledRadioWrapper direction={direction}>
      {items.map((item) => {
        return (
          <Fragment key={item.id}>
            <input
              {...props}
              type="radio"
              id={item.id}
              checked={item.checked}
              value={item.value}
              name={item.name}
              onChange={item.onChange}
            />
            <label htmlFor={item.id}>{item.label}</label>
          </Fragment>
        );
      })}
    </StyledRadioWrapper>
  );
};
