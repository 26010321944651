import moment from 'moment';
import HTMLReactParser from 'html-react-parser';
import { Modal } from '@shared/components/Modal/Modal';
import { Label } from '@shared/components/Label/Label';
import { Text } from '@shared/components/Text/Text';
import { StyledModalRowEnd } from '@shared/components/Modal/style';
import { Button } from '@shared/components/Button/Button';
import { useConfirmTicketMutation, useGetTicketDetailsQuery } from 'src/redux/features/api/tickets';
import { useMemo, useRef, useState } from 'react';
import { TicketReport } from '@components/Tickets/TicketsFromMe/TicketReport/TicketReport';
import { Link } from '@shared/components/Link/Link';
import { ModalStatusInfo } from '@shared/components/Modal/ModalStatusInfo';
import { Container } from '@shared/components/Container';
import AttachedFile from '@components/files/AttachedFile';
import Preloader from '@components/preloaders/Preloader';
import { Avatar } from '@shared/components/Avatar';
import NotFoundComponent from '@shared/components/NotFoundComponent/NotFoundComponent';

import { TicketStatus } from '../TicketStatus';

import { ButtonsContainer, StyledDescription, StyledUserName } from './styles';
import { ReturnTicketForm } from './ui/ReturnTicketForm';

export const TicketInfoModal = ({ ticketId, onClose, onEditTicket, onCancelTicket }) => {
  const [formVisible, setFormVisible] = useState(false);

  const [confirmTicket] = useConfirmTicketMutation();

  const { data, isFetching: isTicketLoading, error } = useGetTicketDetailsQuery({ id: ticketId });

  const ticket = useMemo(() => data?.ticket_detail, [data]);

  const isNeedToCheck = useMemo(() => ticket?.ticket_actions[0].action === 'confirmation', [ticket]);

  const isCanceled = useMemo(() => ticket?.ticket_actions[0].action === 'canceled', [ticket]);

  const isConfirmed = useMemo(() => ticket?.ticket_actions[0].action === 'confirmed', [ticket]);

  const isExpired = useMemo(() => moment().diff(ticket?.date_finish) > 0, [ticket]);

  const handleСonfirmTicket = async () => {
    await confirmTicket({
      ticket_id: ticket.id,
    });
    onClose();
  };

  const removeFilesOnClose = useRef();

  const onCloseHandler = async () => {
    const onRemove = removeFilesOnClose.current;

    if (onRemove) {
      await onRemove();
    }

    onClose();
  };

  const headerButtons = [{ name: 'Ред.', action: onEditTicket }];

  return (
    <Modal modalSize="767px" title="Поручение" onClose={onCloseHandler} withoutButtons headerButtons={headerButtons}>
      {isTicketLoading ? (
        <Preloader />
      ) : (
        <>
          {(error?.status === 403 || error?.status === 404) && <NotFoundComponent small />}

          {error?.status !== 403 && error?.status !== 404 && ticket && (
            <>
              {ticket.base_chat_message && (
                <div>
                  <ModalStatusInfo>
                    <Text tag="p">
                      Поручение на основе сообщения из {ticket.base_chat_message.project_title ? 'проекта' : 'задачи'}
                      <Link link="/linktotask" tag="b" hover="var(--main)">
                        {` "${ticket.base_chat_message.project_title || ticket.base_chat_message.task_title}".`}
                      </Link>
                    </Text>
                    <Text tag="p">{ticket.base_chat_message.message}</Text>
                  </ModalStatusInfo>
                </div>
              )}
              <div>
                <StyledModalRowEnd>
                  <div>
                    <Label>Название</Label>
                    <Text size={2} tag="b">
                      {ticket.title}
                    </Text>
                  </div>
                  <div>
                    <Label>Кому</Label>
                    <Container align={'center'}>
                      <Avatar userId={ticket.executor_id} size={24} />
                      <StyledUserName size={1}>
                        {ticket.executor_last_name} {ticket.executor_first_name}
                      </StyledUserName>
                    </Container>
                  </div>
                </StyledModalRowEnd>
              </div>

              <Container direction="column" gap={'8px'}>
                <StyledDescription>
                  <Label>Описание</Label>
                  <Text size={2} tag="p">
                    {ticket.description && HTMLReactParser(String(ticket.description))}
                  </Text>
                </StyledDescription>

                <div>
                  {ticket.ticket_actions[ticket.ticket_actions.length - 1].ticket_action_files?.length > 0 && (
                    <>
                      {ticket.ticket_actions[ticket.ticket_actions.length - 1].ticket_action_files.map((file, i) => {
                        const url = `/ticket_actions/files/${
                          ticket.ticket_actions[ticket.ticket_actions.length - 1].id
                        }/${file.id}/${file.file}`;

                        return (
                          <AttachedFile
                            fileUrl={url}
                            fileName={file.file}
                            fileId={file.id}
                            key={i}
                            storageFileId={file.from_storage}
                          />
                        );
                      })}
                    </>
                  )}
                </div>
              </Container>

              <div>
                <Label>Срок исполнения</Label>
                <Container direction="column" gap="2px">
                  <Text size={2} tag="b">
                    {moment(ticket.date_finish).format('L')} {moment(ticket.date_finish).format('LT')}
                  </Text>
                  {isExpired && !isConfirmed && <TicketStatus color="red">Просрочено</TicketStatus>}
                </Container>
              </div>

              <Container direction={'column'}>
                {ticket.ticket_actions.map((action) => {
                  return action.report ? <TicketReport action={action} /> : null;
                })}
              </Container>

              {!isCanceled && !isConfirmed && (
                <>
                  {!formVisible ? (
                    <ButtonsContainer>
                      {isNeedToCheck && (
                        <>
                          <Button color="green" onClick={handleСonfirmTicket}>
                            Принять поручение
                          </Button>
                          <Button color="red" onClick={() => setFormVisible(true)}>
                            Не принимать поручение
                          </Button>
                        </>
                      )}
                      <Button onClick={onCancelTicket}>Отменить поручение</Button>
                    </ButtonsContainer>
                  ) : (
                    <ReturnTicketForm
                      ticketId={ticket.id}
                      onCancel={() => setFormVisible(false)}
                      onClose={onClose}
                      removeFilesOnClose={removeFilesOnClose}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </Modal>
  );
};
