import { downloadFileUrl } from 'src/settings/base-url';

export const downloadFile = async ({ url, filename, token }) => {
  const a = document.createElement('a');
  a.style.setProperty('display', 'none');
  document.body.appendChild(a);

  a.href = `${downloadFileUrl}${encodeURIComponent(url)}?token=${token}`;
  a.download = `${filename}`;

  a.click();
  a.remove();
};
