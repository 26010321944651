import React, { useEffect, useState, useRef, useMemo } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { hideTicketsModal, showTicketsModal } from 'src/redux/features/ticketsSlice';
import { TicketsForMeModal } from '@components/Tickets/TicketsForMe/TicketsForMeModal';
import { useGetTicketsQuery } from 'src/redux/features/api/tickets';
import { getCurrentGuestRights } from 'src/redux/features/projectsSlice';
import { AppVerionInfoModal } from '@components/AppVersions/AppVerionInfoModal';
import { getUsers } from 'src/redux/features/usersSlice';
import { getTags } from 'src/redux/features/tagsSlice';
import { server_url } from 'src/settings/base-url';
import Departments from 'src/windows/departments/Departments';
import AllRouters from 'src/routes/routes/all-routes';
import {department_window, office_window, position_window, project_window} from 'src/settings/window-types';
import Positions from 'src/windows/positions/Positions';
import useAuth from 'src/hooks/useAuth';
import ProjectFormCreateEdit from 'src/windows/projects/project-form-create-edit/ProjectFormCreateEdit';
import { useSocketContext } from 'src/contexts/SocketContext';
import SnackbarContext from 'src/contexts/SnackbarContext';
import Snackbar from 'src/components/warnings/Snackbar/Snackbar';

import 'react-toastify/dist/ReactToastify.css';
import { StyledToastContainer } from './styles';

// установить адрес сервера по умолчанию для http запросов
axios.defaults.baseURL = server_url;

// компонент, который передаем через пропс по проекту,  где нужно вызвать модалку из других дочерних компонентов, (из шапки сайта или списка сотрудников деревом например )
// используется для модальных окон отделов, должностей и создания проекта
const Window = (props) => {
  const { data } = props;
  const { type } = data;
  // какую модалку мы открываем, приходит от event в вызове диалогового окна внктри компонента
  return (
    <>
      {type === department_window && <Departments {...props} />}
      {type === position_window && <Positions {...props} />}
      {/* при клике на nav открываем окно создания проекта */}
      {type === project_window && <ProjectFormCreateEdit {...props} modalType={'project'} />}
      {type === office_window && <ProjectFormCreateEdit {...props}  modalType={'office'} />}
    </>
  );
};

const App = () => {
  const dispatch = useDispatch();
  const auth = useAuth();

  const { initSocket } = useSocketContext();

  // установить токен по умолчанию для дальнейших запросов, если юзер ранее проходил авторизацию.
  if (auth?.token) axios.defaults.headers.Authorization = auth.token;

  const appVersionModalVisible = useSelector((state) => state.appVersions.visible);
  const ticketsModalVisible = useSelector((state) => state.ticketsForMe.visible);
  const isTicketsLoaded = useSelector((state) => state.ticketsForMe.isTicketsLoaded);
  const ticketsForMe = useSelector((state) => state.ticketsForMe.tickets);

  const { data: ticketsData } = useGetTicketsQuery(null, { skip: !!isTicketsLoaded });

  // получить список всех юзеров и тэгов
  useEffect(() => {
    if (auth?.token && auth?.user?.id) {
      dispatch(getUsers());
      dispatch(getTags());
    }
  }, [dispatch, auth]);

  // инициировать socket соединение
  useEffect(() => {
    if (auth?.user?.id && auth?.token) {
      initSocket(auth.token);
    }
  }, [auth?.user?.id && auth?.token]);

  //получить список гостевых прав, если юзер - гость
  useEffect(() => {
    if (auth?.user?.is_guest) {
      dispatch(getCurrentGuestRights(auth.user.id));
    }
  }, [auth?.user, dispatch]);

  // Если есть поручения, отобразить модальное окно с поручениями
  useEffect(() => {
    const filteredTickets = ticketsData?.tickets_for_me.filter(
      (ticket) => ticket.ticket_actions[0].action === 'created' || ticket.ticket_actions[0].action === 'returned',
    );

    if (!!filteredTickets?.length && auth.token) {
      setTimeout(() => dispatch(showTicketsModal()), 1000);
    }
  }, [ticketsData, auth.token]);

  const [windowData, setWindowData] = useState({
    isOpen: false,
    type: null, // проекты или отделы отображать?
  });

  // для закрытия модального окна
  const onClose = () => {
    setWindowData({ isOpen: false, type: null });
  };

  // для открытия модального окна
  const onOpen = (type) => {
    setWindowData({ isOpen: true, type: type });
  };

  // если нужно редактировать что-то из разных частей приложения
  // editable - id проекта для редактирования
  const onEdit = (type, editable) => {
    setWindowData({ isOpen: true, type: type, editable: editable });
  };

  // модалка для вызова из nav компонента и в теле страницы
  const popupWindow = <Window data={windowData} onClose={onClose} onOpen={onOpen} onEdit={onEdit} />;

  // для показа всплывающих сообщений
  const snackbarDetails = useRef();
  const snackbarRef = useRef();

  const snackbarContextValue = useMemo(() => {
    const showSnackbar = (message, type, keepOpen) => {
      snackbarDetails.current = { message, type, keepOpen };
      if (snackbarRef.current?.show) {
        snackbarRef.current.show();
      }
    };

    return { showSnackbar };
  }, []);

  // делаем проверку, есть ли у нас data о пользователе ( токены и тд, иначе формы не будут работать, token null )
  return auth.isLoaded ? (
    <BrowserRouter>
      <SnackbarContext.Provider value={snackbarContextValue}>
        {windowData.isOpen && <Window data={windowData} onOpen={onOpen} onClose={onClose} onEdit={onEdit} />}

        <AllRouters popupWindow={popupWindow} />

        <StyledToastContainer
          autoClose={false}
          closeOnClick={false}
          draggable={false}
          position="bottom-right"
          closeButton={false}
        />

        <Snackbar ref={snackbarRef} snackbarDetails={snackbarDetails} />

        {ticketsModalVisible && auth.token && (
          <TicketsForMeModal tickets={ticketsForMe} onClose={() => dispatch(hideTicketsModal())} />
        )}
        {appVersionModalVisible && auth.token && <AppVerionInfoModal />}
      </SnackbarContext.Provider>
    </BrowserRouter>
  ) : (
    <p>Загружаем информацию ... </p>
  );
};

export default App;
