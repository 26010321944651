import styled from 'styled-components';

import { StyledText } from '@shared/components/Text/style';

export const StyledTicketReport = styled.div`
  padding: 16px 0;
  border-top: 1px solid ${({ theme }) => theme.disabledSecondary};
`;

export const StyledTicketTitle = styled(StyledText)`
  margin: 8px 0 4px;
  font-weight: 600;
`;

export const StyledTicketAttach = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTicketAttachWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 4px;
`;
