import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import { store } from 'src/redux/store';
import { files_url } from 'src/settings/base-url';

import { updateOldMessage } from '../redux/features/messagesSlice';
import MessageContainer from '../components/chat/message/MessageContainer';

import { isFileNameImage } from './helper-functions';

// функция для определения координат при селекте текста в сообщении и вывода контекстного меню
export const selectableTextAreaMouseUp = (e, isMousePressedHere, showPopover, localDateCreated, author, id) => {
  if (!isMousePressedHere.current) return;
  isMousePressedHere.current = false;
  const messageBox = e.currentTarget;

  // setTimeout чтобы браузер успел снять предыдущее выделение (сначала реагирует mouseup, потом selectionchange)
  setTimeout(() => {
    const currentSelection = window.getSelection();
    const isMessageSelected = document.getElementById(`messageId${id}`)?.contains(currentSelection.anchorNode);
    if (!isMessageSelected) return;
    const selectedText = currentSelection.toString();
    if (!selectedText.length) return;

    // определить позицию сообщения по отношению к контейнеру
    const offsetLeftToContainer = messageBox.offsetLeft;
    const offsetTopToContainer = messageBox.offsetTop;
    const messageBoxRect = messageBox.getBoundingClientRect();

    // вычислить позицию курсора внутри сообщения
    let offsetX = e.clientX - messageBoxRect.left;
    const offsetY = e.clientY - messageBoxRect.top;
    // сдвинуть всплывашку влево, если не помещается в контейнере
    if (messageBoxRect.width - offsetX < 200) offsetX -= 200;
    // определить позицию мышки по вертикали
    let yPosition = offsetY + offsetTopToContainer + 15;
    // сдвинуть всплывашку вверх, если не помещается в контейнере
    const messagesContainerRect = messageBox.offsetParent?.getBoundingClientRect();
    const messagesContainerMouseYPosition = e.clientY - messagesContainerRect.top;
    if (messagesContainerRect.height - messagesContainerMouseYPosition < 120) yPosition -= 140;

    showPopover({
      show: true,
      x: offsetX + offsetLeftToContainer,
      y: yPosition,

      data: {
        reply_id: id,
        reply_text: selectedText,
        date: localDateCreated,
        author: author,
      },
    });
  }, 0);
};

// react custom hook для отслеживания изменений в чате, прикрепляется к сокету внутри react компонента
export const useChatListener = ({
  additionalMessageProps,
  projectData,
  setMessagesList,
  setPinnedMessages,
  isChatEnd,
}) => {
  const dispatch = useDispatch();
  // функция реагирует на изменения в чате
  const chatChangeListener = useCallback(
    (data, action) => {
      if (projectData?.chat?.id) {
        switch (action) {
          case 'added':
            if (isChatEnd && data.chat_id === projectData.chat.id) {
              setMessagesList((messages) => [
                ...messages,
                <MessageContainer
                  key={data.id}
                  {...data}
                  goals={data.chat_goal_results}
                  projectData={projectData}
                  {...additionalMessageProps}
                />,
              ]);

              // если юзер находится в конце страницы чата, то, после добавления сообщения, сфокусировать на последнем сообщении
              if (store.getState().messages.isScrolledChatBottom) {
                setTimeout(() => {
                  const newMessage = document.getElementById(`messageId${data.id}`);
                  if (newMessage) {
                    newMessage.scrollIntoView();
                  }
                }, 100);
              }
            }
            break;

          case 'edited':
          case 'like':
          case 'dislike':
          case 'cancel_like_dislike':
          case 'recovered':
            if (data.chat_id === projectData.chat.id) {
              setMessagesList((messagesList) => {
                const foundIndex = messagesList.findIndex((message) => message.props.id === data.id);
                if (foundIndex === -1) return messagesList;
                const updMessages = [...messagesList];
                updMessages[foundIndex] = (
                  <MessageContainer
                    key={data.id}
                    {...data}
                    goals={data.chat_goal_results}
                    projectData={projectData}
                    {...additionalMessageProps}
                  />
                );
                return updMessages;
              });

              if (!setPinnedMessages) return;
              setPinnedMessages((pinnedMessages) => {
                const foundPinndeMessageIndex = pinnedMessages?.findIndex((message) => message.props.id === data.id);
                if (foundPinndeMessageIndex === -1) return pinnedMessages;
                const updMessages = [...pinnedMessages];
                updMessages[foundPinndeMessageIndex] = (
                  <MessageContainer
                    key={data.id}
                    {...data}
                    goals={data.chat_goal_results}
                    projectData={projectData}
                    pinnedMessageType={true}
                    {...additionalMessageProps}
                  />
                );
                return updMessages;
              });

              dispatch(updateOldMessage({ data }));
            }
            break;

          case 'pinned':
            if (data.chat_id === projectData.chat.id) {
              setMessagesList((messagesList) => {
                const foundIndex = messagesList.findIndex((message) => message.props.id === data.id);
                if (foundIndex === -1) return messagesList;
                const updMessages = [...messagesList];
                updMessages[foundIndex] = (
                  <MessageContainer key={data.id} {...data} goals={data.chat_goal_results} projectData={projectData} />
                );
                return updMessages;
              });

              if (!setPinnedMessages) return;
              setPinnedMessages((pinnedMessages) => [
                ...pinnedMessages,
                <MessageContainer
                  key={data.id}
                  {...data}
                  goals={data.chat_goal_results}
                  projectData={projectData}
                  pinnedMessageType={true}
                />,
              ]);

              dispatch(updateOldMessage({ data, type: action }));
            }
            break;

          case 'unpinned':
            if (!setPinnedMessages) return;
            setPinnedMessages((pinnedMessages) =>
              pinnedMessages.filter((pinnedMessage) => pinnedMessage.props.id !== data.id),
            );
            setMessagesList((messagesList) => {
              const unpinnedMessageIndex = messagesList.findIndex((message) => message.props.id === data.id);
              if (unpinnedMessageIndex === -1) return messagesList;
              const updMessages = [...messagesList];
              updMessages[unpinnedMessageIndex] = (
                <MessageContainer key={data.id} {...data} goals={data.chat_goal_results} projectData={projectData} />
              );
              return updMessages;
            });
            dispatch(updateOldMessage({ data, type: action }));
            break;

          case 'deleted':
            setMessagesList((messagesList) => {
              const messageIndexToDelete = messagesList.findIndex((message) => message.props.id === data.id);
              if (messageIndexToDelete === -1) return messagesList;
              const updMessages = [...messagesList];
              updMessages[messageIndexToDelete] = (
                <MessageContainer key={data.id} {...updMessages[messageIndexToDelete].props} deleted={1} />
              );
              return updMessages;
            });

            if (!setPinnedMessages) return;
            setPinnedMessages((pinnedMessages) => {
              const pinnedMessageIndexToDelete = pinnedMessages.findIndex((message) => message.props.id === data.id);
              if (pinnedMessageIndexToDelete === -1) return pinnedMessages;
              const updPinnedMessages = [...pinnedMessages];
              updPinnedMessages[pinnedMessageIndexToDelete] = (
                <MessageContainer
                  key={data.id}
                  {...updPinnedMessages[pinnedMessageIndexToDelete].props}
                  deleted={1}
                  pinnedMessageType={true}
                />
              );
              return updPinnedMessages;
            });

            dispatch(updateOldMessage({ data, type: action }));

            break;

          default:
            return;
        }
      }
    },
    [projectData, setMessagesList, setPinnedMessages, dispatch, additionalMessageProps, isChatEnd],
  );

  return chatChangeListener;
};

// для загрузки предыдущих сообщений в чате
export const requestPreviousMessages = (chatId, earliestMessageId) => {
  return axios.get(`/api/load_chat_prev_messages/${chatId}/${earliestMessageId}`);
};

export const requestNextMessages = (chatId, lastMessageId) => {
  return axios.get(`/api/load_chat_next_messages/${chatId}/${lastMessageId}`);
};

export const formatMessages = ({ projectData, messages, isPinned, additionalMessageProps }) => {
  if (messages) {
    return messages.map((message) => {
      return (
        <MessageContainer
          key={message.id}
          {...message}
          goals={message.chat_goal_results}
          likes={message.message_likes}
          projectData={projectData}
          tags={message.message_tags}
          pinnedMessageType={isPinned}
          {...additionalMessageProps}
        />
      );
    });
  }

  return [];
};

export const useFormatMessageFiles = (message_files, messageId, token) => {
  const { allAttachedFiles, gallerySourcesArr } = useMemo(() => {
    const gallerySourcesArr = [];
    let allAttachedFiles = null;
    if (!message_files?.length || !messageId || !token) {
      return { gallerySourcesArr, allAttachedFiles };
    }
    allAttachedFiles = message_files.map((file) => {
      const msgFile = { ...file };
      const url = `/chat_messages/files/${messageId}/${msgFile.id}/${msgFile.file}`;
      const urlToGallery = `${files_url}/chat_messages/files/${messageId}/${msgFile.id}/${msgFile.file}?token=${token}`;

      if (isFileNameImage(msgFile.file)) {
        gallerySourcesArr.push({
          original: urlToGallery,
          thumbnail: urlToGallery,
          loading: 'lazy',
          alt: msgFile.file,
          imgid: msgFile.id,
        });
      }
      msgFile.url = url;
      return msgFile;
    });

    return { gallerySourcesArr, allAttachedFiles };
  }, [message_files, messageId, token]);

  return { allAttachedFiles, gallerySourcesArr };
};

export const useNavigateToMessage = (dataType, dataId, messageId) => {
  const { projectId, taskId } = useParams();
  const navigate = useNavigate();

  const navigateToMessage = () => {
    let messageLink = `/projects/${projectId}`;

    if (dataType === 'task' && dataId) {
      messageLink += `/tasks/${dataId}`;
    }

    messageLink += `?msg=${messageId}`;

    // открывать в новом окне, если список сообщений не из текущего проекта/таска
    if ((dataType === 'task' && (!taskId || +taskId !== +dataId)) || (dataType === 'project' && taskId)) {
      window.open(`${window.location.origin}${messageLink}`);
    } else {
      navigate(messageLink);
    }
  };

  return navigateToMessage;
};
