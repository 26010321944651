import axios from 'axios';
import { useEffect, useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatDateWithDots } from '@shared/components/CalendarForm/CalendarForm';
import UserProfileModalContainer from 'src/windows/profile/UserProfileModalContainer';
import { staticFilesPathPart } from 'src/settings/base-url';
import WarningBox from '@shared/components/WarningBox/WarningBox';
import {Modal, Subtitle} from "@shared/components";
import SnackbarContext from 'src/contexts/SnackbarContext';
import { getStorageTree } from 'src/redux/features/storageSlice';
import { convertBytes, isFileNameImage } from 'src/utilize/helper-functions';
import FileIcon from 'src/assets/images/icons/icon-file.svg';
import iconCatalogClose from "@assets/images/icons/icon-folder-close.svg";
import iconCatalogOpen from "@assets/images/icons/icon-folder-open.svg";
import iconFolder from "@assets/images/icons/folder.svg";

import Preloader from '../preloaders/Preloader';

import FolderAside from './FolderAside';
import StoragesDropdown from './StoragesDropdown';
import useRenderUserName from './useRenderUserName';
import {
  CatalogFile,
  CatalogFileName,
  CatalogFolder, Dropdown,
  StorageList,
  StorageRubric,
  StorageRubricItem,
  StorageRubricLink, StyledDropdownInput,
  StyledIcon,
  StyledImageIcon, StyledSelect
} from './styles';

const processDuplicateError = (error, type, showSnackbar, defaultMessage) => {
  //
  if (error.response?.status === 409 && error.response.data.message?.includes('already exists')) {
    //
    let dataType = '';
    if (type === 'folder') dataType = 'Папка';
    else if (type === 'file') dataType = 'Файл';
    showSnackbar(`${dataType} с таким названием уже существует`);
  } else {
    showSnackbar(defaultMessage);
  }
};

const TransferCopyFilesModal = ({ close, data, type, mode, storageId, fileUrl }) => {
  const [userProfileId, setUserProfileId] = useState();
  const renderUserName = useRenderUserName(setUserProfileId);

  const currentStorageTree = useSelector((state) => state.storage.storageTree);
  const [selectedStorage, setSelectedStorage] = useState(null);
  const [selectedFolderData, setSelectedFolderData] = useState();

  const handleDestinationFolderSelect = (folderData, folderPath) => {
    if (type === 'folder' && folderData.id === data.id) return;
    setSelectedFolderData({ id: folderData.id, path: folderPath, files: folderData.files });
  };

  const [isLoadingStorageTree, setIsLoadingStorageTree] = useState();

  const { showSnackbar } = useContext(SnackbarContext);

  const [selectedStorageTree, setSelectedStorageTree] = useState(null);

  const getStorageTreeDropdown = useCallback(
    (selectedStorage) => {
      setIsLoadingStorageTree(true);
      setSelectedStorageTree(null);
      axios
        .get(`/api/storage_tree/${selectedStorage.id}`)
        .then((r) => {
          setIsLoadingStorageTree(false);
          setSelectedStorageTree(r.data.storage_tree);
        })
        .catch(() => {
          setIsLoadingStorageTree(false);
          showSnackbar('Возникла ошибка при загрузке данных выбранного хранилища');
        });
    },
    [showSnackbar],
  );

  // если файлы перемещаются внутри хранилища - показать дерево текущего хранилища
  // если файлы копируются в другое хранилище - получить дерево выбранного хранилища
  useEffect(() => {
    if (mode === 'transfer' && currentStorageTree) {
      setSelectedStorageTree(currentStorageTree);
    } else if ((mode === 'copy' || mode === 'fromChat') && selectedStorage) getStorageTreeDropdown(selectedStorage);
  }, [selectedStorage, getStorageTreeDropdown, mode, currentStorageTree]);

  const [dropdown, setDropdown] = useState(false);

  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState();

  const submitTransferRequest = () => {
    const reqBody = {
      [`${type}_id`]: data.id,
    };

    if (selectedFolderData && selectedFolderData.id !== 'root') {
      reqBody.parent_folder_id = selectedFolderData.id;
    }

    setIsSubmitting(true);
    axios
      .put('/api/move_storage_content', reqBody)
      .then(() => {
        dispatch(getStorageTree({ storageId, showSnackbar }));
        close();
      })
      .catch((error) => {
        processDuplicateError(error, type, showSnackbar, 'Возникла ошбика при переносе');
        setIsSubmitting(false);
      });
  };

  const submitCopyRequest = () => {
    if (!selectedFolderData) return;
    const reqBody = {
      [`${type}_id`]: data.id,
    };
    if (selectedFolderData.id === 'root') {
      reqBody.parent_storage_id = selectedStorage.id;
    } else {
      reqBody.parent_folder_id = selectedFolderData.id;
    }

    setIsSubmitting(true);
    axios
      .post('/api/copy_storage_content', reqBody)
      .then(() => {
        showSnackbar('Данные успешно скопированы', 'success');
        close();
      })
      .catch((error) => {
        processDuplicateError(error, type, showSnackbar, 'Произошла ошибка при копировании данных в другое хранилище');
        setIsSubmitting(false);
      });
  };

  const submitCopyFromChatRequest = () => {
    if (!selectedFolderData) return;
    const reqBody = {
      chat_message_file_id: data.id,
      file_path: `${staticFilesPathPart}/${data.filePath}`,
    };
    if (selectedFolderData.id === 'root') reqBody.storage_id = selectedStorage.id;
    else reqBody.folder_id = selectedFolderData.id;
    setIsSubmitting(true);
    axios
      .post('/api/storage_file_from_message', reqBody)
      .then(() => {
        showSnackbar('Файл успешно скопирован', 'success');
        close();
      })
      .catch((error) => {
        processDuplicateError(error, type, showSnackbar, 'Произошла ошибка при копировании файла в другое хранилище');
        setIsSubmitting(false);
      });
  };

  const [duplicateTitleWarning, setDuplicateTitleWarning] = useState(false);

  const handleCopyFromChat = () => {
    if (data.title === duplicateTitleWarning) {
      submitCopyFromChatRequest();
    } else if (selectedFolderData.files?.some((file) => file.title === data.title)) {
      setDuplicateTitleWarning(data.title);
    } else submitCopyFromChatRequest();
  };

  const handleSubmit = () => {
    if (!selectedFolderData?.id) return;
    if (mode === 'transfer') submitTransferRequest();
    else if (mode === 'copy') submitCopyRequest();
    else if (mode === 'fromChat') handleCopyFromChat();
  };

  return (
    <>
      <Modal
        title={
        <>
          {mode === 'transfer' && 'Перенести в папку этого хранилища'}
          {mode === 'copy' && 'Скопировать в другое хранилище'}
          {mode === 'fromChat' && 'Скопировать в хранилище'}
        </>
        }
        onClose={close}
        onSave={handleSubmit}
        disabledSaveButton={!selectedFolderData?.id || isSubmitting}
        confirmButtonText={
        <>
          {mode === 'transfer' && !isSubmitting && 'Перенести'}
          {(mode === 'copy' || mode === 'fromChat') && !isSubmitting && !duplicateTitleWarning && 'Скопировать'}
          {mode === 'fromChat' && duplicateTitleWarning && !isSubmitting && 'Подтвердить'}
          {isSubmitting && 'Отправка...'}
        </>
        }
      >
          <StorageList>
              {type === 'folder' && (
                <CatalogFolder icon={iconFolder}>
                  <CatalogFileName>{data.title}</CatalogFileName>
                  <p>{convertBytes(data.size)}</p>
                  <p>{formatDateWithDots(data.date_created)}</p>
                  {renderUserName(data.creator_id)}
                </CatalogFolder>
              )}
              {type === 'file' && (
                <CatalogFile>
                    {isFileNameImage(data.title) ? (
                      <StyledImageIcon src={fileUrl} loading="lazy" />
                    ) : (
                      <StyledIcon $fileUrl={FileIcon} />
                    )}
                  <CatalogFileName>{data.title}</CatalogFileName>
                  <p>{data.size ? convertBytes(data.size) : ''}</p>
                  <p>{formatDateWithDots(data.date_created)}</p>
                  {renderUserName(data.creator_id)}
                </CatalogFile>
              )}
          </StorageList>

        {(mode === 'copy' || mode === 'fromChat') && (
          <div>
            <Subtitle>Хранилище</Subtitle>
            <StoragesDropdown
              handleAnotherStorageSelect={(data) => {
                setSelectedStorage(data);
              }}
              selectedStorageTitle={selectedStorage?.title}
            />
          </div>
        )}

        {isLoadingStorageTree && <Preloader />}

        {selectedStorageTree && !isLoadingStorageTree && (
          <div>
            <Subtitle>Папка</Subtitle>
            <Dropdown
              $show={dropdown}
              onClick={() => setDropdown(!dropdown)}
            >
              <StyledDropdownInput>
                {selectedFolderData?.path?.reduce((prev, curr, index) => {
                  let pathString = prev;
                  pathString += Object.keys(curr)[0];
                  if (selectedFolderData.path.length - 1 !== index) {
                    pathString += ' / ';
                  }
                  return pathString;
                }, '')}
              </StyledDropdownInput>

              <StyledSelect>
                <StorageRubric>
                  <StorageRubricItem
                    data-spoilers
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedFolderData({
                        id: 'root',
                        path: [
                          {
                            [selectedStorageTree.title]: selectedStorageTree.id,
                          },
                        ],
                        files: selectedStorageTree?.files,
                      });
                    }}
                  >
                    <StorageRubricLink
                      iconClose={iconCatalogClose}
                      iconOpen={iconCatalogOpen}
                      $active={true}
                      $highlighted={selectedFolderData?.id === 'root'}
                    >
                      {selectedStorageTree.title}
                    </StorageRubricLink>

                    {selectedStorageTree.folders.length > 0 && (
                      <StorageRubric>
                        {selectedStorageTree.folders.map((folder, i) => {
                          return (
                            <FolderAside
                              key={i}
                              data={folder}
                              handleSelect={(id, folderPath) => handleDestinationFolderSelect(folder, folderPath)}
                              selectedFolderId={selectedFolderData?.id}
                            />
                          );
                        })}
                      </StorageRubric>
                    )}
                  </StorageRubricItem>
                </StorageRubric>
              </StyledSelect>
            </Dropdown>
          </div>
        )}

        {duplicateTitleWarning && (
          <WarningBox>
            Файл с таким названием уже есть в выбраной папке. Подтвердите, что хотите загрузить файл как новую
            версию уже существующего.
          </WarningBox>
        )}
        </Modal>

      {userProfileId && <UserProfileModalContainer profileId={userProfileId} onClose={() => setUserProfileId(null)} />}
    </>
  );
};

export default TransferCopyFilesModal;
