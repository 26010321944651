import React, { useCallback, useContext, useRef, useState } from 'react';

import { IconButton } from '@shared/components/IconButton/IconButton';
import attachIcon from '@assets/images/icons/icon-links.svg';
import databaseIcon from '@assets/images/icons/chat-storage.svg';

import { Label } from '@shared/components/Label/Label';

import { Button } from '@shared/components/Button/Button';

import { useSendReportMutation } from 'src/redux/features/api/tickets';

import FilesUploadManage from '@components/files/FilesUploadManage';

import { randomSequence } from 'src/utilize/helper-functions';

import StorageModal from 'src/windows/storage/StorageModal';

import axios from 'axios';

import SnackbarContext from 'src/contexts/SnackbarContext';

import FileToUpload from '@components/files/FileToUpload';

import { useRichTextEditor } from '@components/RichTextEditor/useRichTextEditor';

import { RichTextEditor } from '@components/RichTextEditor';

import { stateToHTML } from 'draft-js-export-html';

import { ButtonsContainer, FilesContainer, StyledContainer } from './styles';
export const ReportForm = ({ ticketId, currentAction, onClose, removeFilesOnClose }) => {
  const [visible, setVisible] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesFromStorage, setFilesFromStorage] = useState([]);

  const [isFilesUploading, setIsFilesUploading] = useState();
  const [showStorage, setShowStorage] = useState(false);

  const [formId, setFormId] = useState(randomSequence());

  const { state, onChange } = useRichTextEditor();
  const [sendReport, { isLoading, isError }] = useSendReportMutation();

  const isCompleted = currentAction === 'confirmed';
  const isPending = currentAction === 'confirmation';

  const { showSnackbar } = useContext(SnackbarContext);

  const openFileSelectPopup = () => {
    document.getElementById('upfile').click();
  };

  const addFilesFromStorage = async () => {
    const filesIds = filesFromStorage.map((file) => file.id);

    await axios
      .post('/api/ticket_action_files/add_from_storage', {
        form_id: formId,
        storage_files: filesIds,
      })
      .catch(() => showSnackbar('Возникла ошибка при отправке файлов из хранилища'));
  };

  const addStorageFileReference = useCallback((storageFileData) => {
    setFilesFromStorage((files) => {
      const alreadyAdded = files.some((fileData) => fileData.id === storageFileData.id);
      if (alreadyAdded) return files;
      return [...files, storageFileData];
    });
    showSnackbar('Ссылка на файл из хранилища добавлена', 'success');
  }, []);

  const handleSendReport = async () => {
    if (filesFromStorage.length) {
      await addFilesFromStorage();
    }
    await sendReport({
      ticket_id: ticketId,
      report: stateToHTML(state.getCurrentContent()),
      form_id: formId,
    });
    onClose();
  };

  const clearFileManager = useRef();

  return (
    <>
      {visible && (
        <StyledContainer>
          <Label>Отчет о выполнении поручения</Label>
          <RichTextEditor
            value={state}
            onChange={onChange}
            extraButtons={
              <>
                <IconButton size={14} icon={attachIcon} onClick={openFileSelectPopup}>
                  <input
                    type="file"
                    multiple
                    id="upfile"
                    style={{ display: 'none' }}
                    onChange={(e) => setFilesToUpload([...e.target.files])}
                  />
                </IconButton>
                <IconButton size={14} icon={databaseIcon} onClick={() => setShowStorage(true)}></IconButton>
              </>
            }
          />

          {(filesToUpload?.length > 0 || filesFromStorage.length > 0) && (
            <FilesContainer>
              {filesFromStorage.map((f, i) => (
                <FileToUpload
                  file={f}
                  progressPercent={100}
                  isUploaded
                  removeFile={() => {
                    setFilesFromStorage((files) => files.filter((file, index) => index !== i));
                  }}
                  key={i}
                  index={i}
                  isFromStorage
                />
              ))}

              <FilesUploadManage
                formId={formId}
                filesToUpload={filesToUpload}
                filesUploadPath="/api/ticket_action_files/add"
                filesDeletePath="/api/ticket_action_files/remove"
                setIsFilesUploading={setIsFilesUploading}
                removeFilesOnClose={removeFilesOnClose}
                clearFileManager={clearFileManager}
                removeById
              />
            </FilesContainer>
          )}

          <ButtonsContainer>
            <Button width={'153px'} color={'green'} onClick={handleSendReport}>
              Отправить
            </Button>
            <Button width={'153px'} color={'light'} onClick={() => setVisible(false)}>
              Отмена
            </Button>
          </ButtonsContainer>
        </StyledContainer>
      )}

      {!visible && (
        <ButtonsContainer>
          {!isCompleted && !isPending && <Button onClick={() => setVisible(true)}>Поручение выполнено</Button>}
        </ButtonsContainer>
      )}

      {showStorage && (
          <StorageModal
            addStorageFileReference={addStorageFileReference}
            noURLParams
            close={() => setShowStorage(false)}
          />
      )}
    </>
  );
};
