import { useMemo } from 'react';
import SelectInput from '@components/form/form-select/SelectInput';
import { useSelector } from 'react-redux';

export const formatEmployeeDataForSelect = (employee, showPosition, showDepartment) => {
  if (!employee) return null;
  return {
    label: `${employee.last_name || ''} ${employee.first_name}${
      showPosition && employee.position ? ', ' + employee.position.toLowerCase() : ''
    }${showDepartment && employee.department ? ' (' + employee.department.toLowerCase() + ') ' : ''} `,
    value: employee.id,
  };
};

const formatEmployeesOptions = (employees, showPosition, showDepartment) => {
  if (!employees?.length) return [];
  return employees.map((employee) => formatEmployeeDataForSelect(employee, showPosition, showDepartment));
};

const EmployeeSingleSelect = ({ control, name, label, showPosition, showDepartment, specifiedEmployees }) => {
  const employees = useSelector((state) => state.users.employees);

  const data = useMemo(
    () => ({
      name: name,
      label: label,
      options: specifiedEmployees
        ? formatEmployeesOptions(specifiedEmployees, showPosition, showDepartment)
        : formatEmployeesOptions(employees, showPosition, showDepartment),
    }),
    [employees, specifiedEmployees],
  );
  // console.log('🚀 ~ EmployeeSingleSelect ~ data:', data);

  return <SelectInput control={control} data={data} formattedOptions={data.options} />;
};

export default EmployeeSingleSelect;
