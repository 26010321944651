import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import { isFileNameImage, isPreviewFile, showLocalDateTime } from 'src/utilize/helper-functions';

import { Container } from '@shared/components';

import { PreviewModal } from '@shared/widgets/PreviewModal';

import iconVersion from '@assets/images/icons/icon-history.svg';

import {
  StyledVersion,
  StyledVersionButton,
  StyledVersionItem,
  StyledVersionList,
  StyledVersionTitle
} from "@components/files/styles";

import SnackbarContext from '../../../contexts/SnackbarContext';
import useAuth from '../../../hooks/useAuth';
import { files_url } from '../../../settings/base-url';
import ThreeDotsLoader from '../../preloaders/ThreeDotsLoader';

const FileVersionsPopup = ({ storageFileId, extraButton }) => {
  const [isMenuShow, setIsMenuShow] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [fileVersions, setFileVersions] = useState([]);
  const [previewModalVisible, setPreviewModalVisible] = useState(false);

  const showSnackbar = useContext(SnackbarContext);
  const auth = useAuth();

  useEffect(() => {
    if (isMenuShow) {
      axios
        .get(`/api/storage_file_info/${storageFileId}`)
        .then((r) => {
          if (Array.isArray(r.data)) {
            setFileVersions(r.data.sort((a, b) => b.id - a.id));
          }
          setIsLoading(false);
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при запросе данных о версиях файла');
          setIsLoading(false);
        });
    }
  }, [isMenuShow, storageFileId, showSnackbar]);

  return (
    <>
      {' '}
      {/*если файл добавлен из хранилища, то появляется кнопка с выбором версии файла*/}
      <StyledVersion>
        <StyledVersionButton icon={iconVersion}
          onClick={() => {
            if (!isMenuShow) setIsLoading(true);
            setIsMenuShow(!isMenuShow);
          }}
        />
        <StyledVersionList $active={isMenuShow}>
          {isLoading || !auth?.token ? (
            <StyledVersionItem>
              <ThreeDotsLoader visible width={20} height={20}/>
            </StyledVersionItem>
          ) : (
            <>
              <StyledVersionTitle>Все версии файла</StyledVersionTitle>
              {fileVersions.map((fileVersion, i) => {
                let filePath;
                if (fileVersion.is_main) {
                  filePath = `/storages/${fileVersion.path}`;
                } else {
                  filePath = `/storages/versions/files/${fileVersion.storage_id}/${fileVersion.id}/${fileVersion.title}`;
                }

                return (
                  <StyledVersionItem key={fileVersion.id}>
                    <a key={i} href={`${files_url}${filePath}?token=${auth.token}`} target="_blank" rel="noreferrer">
                      <div
                        onClick={(e) => {
                          if (isPreviewFile(fileVersion.title) || isFileNameImage(fileVersion.title)) {
                            e.stopPropagation();
                            e.preventDefault();
                            setPreviewModalVisible(true);
                          }
                        }}
                      >
                        от {showLocalDateTime(fileVersion.date_created, 'DD.MM.yyyy kk:mm')}
                      </div>
                    </a>
                    {(isPreviewFile(fileVersion.title) || isFileNameImage(fileVersion.title)) && extraButton}

                    {previewModalVisible && (
                        <PreviewModal
                          url={`${files_url}${filePath}?token=${auth.token}`}
                          filePath={filePath}
                          fileName={fileVersion.title}
                          onClose={() => setPreviewModalVisible(false)}
                        />
                    )}
                  </StyledVersionItem>
                );
              })}
            </>
          )}
        </StyledVersionList>
        {/*если в дальнейшем файл будет удален из хранилища, то показываем только заголовок с предупреждением*/}
        {/*<StyledVersionList>*/}
        {/*  <StyledVersionTitle>Файл был удален из хранилища</StyledVersionTitle>*/}
        {/*</StyledVersionList>*/}
      </StyledVersion>
    </>
  );
};

export default FileVersionsPopup;
