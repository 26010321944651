import { Container } from '@shared/components';
import { styled } from 'styled-components';

export const FilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0;
  gap: 8px;
`;

export const StyledForm = styled.form`
  display: grid;
  gap: 32px;
`;

export const Wrapper = styled(Container)`
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 14px;

  ::placeholder {
    font-size: 14px;
  }
`;
