import { AddVersionModal } from '@components/AppVersions/AddVersionModal';
import { CreateTicketModal } from '@components/Tickets/TicketsFromMe';
import { useRef, useState, useCallback, useMemo } from 'react';
import useParamsCustom from 'src/hooks/urlParams/useParamsCustom';
import CreateTaskModal from 'src/components/tasks/CreateTaskModal';
import useAuth from 'src/hooks/useAuth';
import { useOutsideTrigger } from 'src/utilize/helper-functions';
import CreateEditNewsModal from 'src/windows/news/CreateEditNewsModal';
import ProjectFormCreateEdit from 'src/windows/projects/project-form-create-edit/ProjectFormCreateEdit';
import { CreateReminderModal } from '@components/Reminders';
import { Separator } from 'src/shared/components';
import { NavControlsButton, NavControlsItem, SiteInnerLink, StyledUserNavInnerList } from '@components/nav/styles';
import iconPlus from '@assets/images/icons/plus.svg';

const QuickAddNav = () => {
  const { projectId, taskProjectId, taskId } = useParamsCustom([
    '/projects/:projectId',
    '/projects/:taskProjectId/tasks/:taskId',
  ]);

  const [popup, setPopup] = useState();
  const [modal, setModal] = useState();

  const quickAddBtnRef = useRef();
  const quickAddPopup = useRef();

  const refsArray = useRef([quickAddBtnRef, quickAddPopup]);

  const hidePopup = useCallback(() => {
    setPopup(false);
  }, []);

  useOutsideTrigger(refsArray.current, hidePopup, popup);

  const auth = useAuth();
  const isDeveloper = useMemo(() => auth.user?.rights?.includes('developer'), [auth]);

  return (
    <>
      <NavControlsItem $active={popup}>
        {/* Кнопка - зеленый плюс */}
        <NavControlsButton
          icon={iconPlus}
          $add
          ref={quickAddBtnRef}
          onClick={() => setPopup(!popup)}
        />
        <StyledUserNavInnerList ref={quickAddPopup} $active={popup}>
          <SiteInnerLink
            onClick={() => {
              setPopup(false);
              setModal('project');
            }}
          >
            Проект
          </SiteInnerLink>
          <SiteInnerLink
            onClick={() => {
              setPopup(false);
              setModal('office');
            }}
          >
            Виртуальный офис
          </SiteInnerLink>
          {(projectId || taskProjectId) && (
            <SiteInnerLink
              onClick={() => {
                setPopup(false);
                setModal('task');
              }}
            >
              {`Задача к ${taskId ? 'текущей задаче' : 'текущему проекту'}`}
            </SiteInnerLink>
          )}
          {/*<SiteInnerLink*/}
          {/*  onClick={() => {*/}
          {/*    setPopup(false);*/}
          {/*    setModal('ticket');*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Поручение*/}
          {/*</SiteInnerLink>*/}
          <SiteInnerLink
            onClick={() => {
              setPopup(false);
              setModal('reminder');
            }}
          >
            Напоминание
          </SiteInnerLink>
          <Separator />
          {/* <span
            onClick={() => {
              setPopup(false);
              setModal('poll');
            }}
          >
            Опрос
          </span> */}
          {auth?.isUserRightful('news_add') && (
            <SiteInnerLink
              onClick={() => {
                setPopup(false);
                setModal('news');
              }}
            >
              Новость
            </SiteInnerLink>
          )}
          <SiteInnerLink
            onClick={() => {
              setPopup(false);
              setModal('blog');
            }}
          >
            Блог
          </SiteInnerLink>

          {isDeveloper && (
            <>
              <Separator />
              <SiteInnerLink
                onClick={() => {
                  setPopup(false);
                  setModal('version');
                }}
              >
                Версия портала
              </SiteInnerLink>
            </>
          )}
        </StyledUserNavInnerList>
      </NavControlsItem>

      {modal && (
        <>
          {modal === 'project' && <ProjectFormCreateEdit modalType={modal} data={{}} onClose={() => setModal(false)} />}
          {modal === 'office' && <ProjectFormCreateEdit modalType={modal} data={{}} onClose={() => setModal(false)} />}
          {modal === 'task' && (
            <CreateTaskModal
              projectId={projectId || taskProjectId}
              parentTaskId={taskId}
              close={() => setModal(false)}
            />
          )}
          {(modal === 'news' || modal === 'blog') && <CreateEditNewsModal modalType={modal} close={() => setModal(false)} />}
          {modal === 'ticket' && <CreateTicketModal onClose={() => setModal(false)} />}
          {modal === 'version' && <AddVersionModal onClose={() => setModal(false)} />}
          {modal === 'reminder' && <CreateReminderModal onClose={() => setModal(false)} />}
        </>
      )}
    </>
  );
};

export default QuickAddNav;
