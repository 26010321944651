import styled, { css } from 'styled-components';

export const StyledButton = styled.button`
  font-size: 16px;
  line-height: 24px;
  border: 1px solid ${({theme}) => theme.main};
  border-radius: 2px;
  padding: 7px 15px;
  width: ${(props) => props.width || 'fit-content'};
  min-width: ${(props) => props.minwidth || 'fit-content'};
  max-height: 40px;
  color: ${({theme}) => theme.lightest};
  background-color: ${({theme}) => theme.main};
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  user-select: none;

  &:hover {
    filter: brightness(95%);
  }

  &:disabled,
  &._disabled {
    border: 2px solid ${({theme}) => theme.border};
    color: ${({theme}) => theme.disabled};
    background-color: ${({theme}) => theme.disabledSecondary};
    cursor: not-allowed;
    pointer-events: none;
  }

  ${(props) =>
          props.size === 'sm' &&
          css`
            font-size: 12px;
            line-height: 20px;
            padding: 1px 8px;
            border-radius: 2px;
          `}

  ${(props) =>
          props.color === 'green' &&
          css`
            border-color: ${({theme}) => theme.success};
            color: ${({theme}) => theme.lightest};
            background-color: ${({theme}) => theme.success};
          `}

  ${(props) =>
          props.color === 'outlined' &&
          css`
            border-color: transparent;
            color: ${({theme}) => theme.main};
            background-color: transparent;

            &:hover {
              filter: brightness(1);
              border-color: ${({theme}) => theme.main};
              background-color: transparent;
            }
          `}

  ${(props) =>
          props.color === 'outlined-green' &&
          css`
            border-color: transparent;
            color: ${({theme}) => theme.success};
            background-color: transparent;

            &:hover {
              filter: brightness(1);
              border-color: ${({theme}) => theme.succes};
              background-color: transparent;
            }
          `}

  ${(props) =>
          props.color === 'light' &&
          css`
            background-color: ${({theme}) => theme.disabledSecondary};
            border: 1px solid ${({theme}) => theme.disabledSecondary};
            color: ${({theme}) => theme.darkest};
          `}

  ${(props) =>
          props.color === 'red' &&
          css`
            border: 1px solid ${({theme}) => theme.errorLight};
            background-color: ${({theme}) => theme.error};

            &:hover {
              filter: brightness(90%);
            }
          `}

  ${(props) =>
          props.color === 'secondary' &&
          css`
            border: 1px solid ${({ theme }) => theme.border};
            color: ${({ theme }) => theme.darkest};
            background-color: ${({ theme }) => theme.light};

            &:hover {
              filter: brightness(95%);
            }
          `}
`;
