import React, { useMemo, useContext, useRef, useState, useCallback } from 'react';

import { Modal } from '@shared/components/Modal/Modal';
import { Label } from '@shared/components/Label/Label';
import { SingleSelect } from '@shared/components/Select/Select';
import { Input } from '@shared/components/Input/Input';
import { IconButton } from '@shared/components/IconButton/IconButton';
import { StyledModalRowEnd } from '@shared/components/Modal/style';
import DateInput from '@shared/components/DateInput/DateInput';

import databaseIcon from '@assets/images/icons/chat-storage.svg';
import attachIcon from '@assets/images/icons/icon-links.svg';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SnackbarContext from 'src/contexts/SnackbarContext';

import axios from 'axios';
import { server_url } from 'src/settings/base-url';

import { ticketsApi, useGetTicketDetailsQuery } from 'src/redux/features/api/tickets';

import { randomSequence } from 'src/utilize/helper-functions';

import FilesUploadManage from '@components/files/FilesUploadManage';

import StorageModal from 'src/windows/storage/StorageModal';

import FileToUpload from '@components/files/FileToUpload';

import { RichTextEditor } from '@components/RichTextEditor';

import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import { components } from 'react-select';

import ConfirmAction from '@components/warnings/ConfirmAction';

import { t } from 'i18next';

import { Avatar } from '@shared/components/Avatar';

import { getOptions, schema, transformFormData } from './lib';
import { FilesContainer, StyledForm, Wrapper } from './styles';

export const EditTicketModal = ({ ticketId, onClose }) => {
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesDeleteArr, setFilesDeleteArr] = useState([]);
  const [filesFromStorage, setFilesFromStorage] = useState([]);
  const [isFilesUploading, setIsFilesUploading] = useState();
  const [formId, setFormId] = useState(randomSequence());
  const [showStorage, setShowStorage] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const removeFilesOnClose = useRef();
  const clearFileManager = useRef();

  const auth = useAuth();
  const users = useSelector((state) => state.users.employees);

  const { data, isLoading: isTicketLoading } = useGetTicketDetailsQuery({ id: ticketId });

  const ticket = useMemo(() => data?.ticket_detail, [data]);

  const executionDate = new Date(ticket.date_finish);

  const options = useMemo(() => getOptions(users, auth.token), [users]);

  const dispatch = useDispatch();

  const { showSnackbar } = useContext(SnackbarContext);

  const customOption = {
    label: `${
      ticket.executor_last_name
        ? `${ticket.executor_last_name} ${ticket.executor_first_name}`
        : ticket.executor_first_name
    }`,
    value: ticket.executor_id,
  };

  const { filesToEdit, addFilesFormDataTemplate } = useMemo(() => {
    let filesToEdit, addFilesFormDataTemplate;
    if (ticket.ticket_actions[0].ticket_action_files && auth?.token) {
      filesToEdit = ticket.ticket_actions[0].ticket_action_files.map((file) => ({
        ...file,
        name: file.file,
        src: `/ticket_actions/files/${ticket.ticket_actions[0].id}/${file.id}/${file.file}`,
      }));

      addFilesFormDataTemplate = new FormData();
      addFilesFormDataTemplate.append('ticket_action_id', ticket.ticket_actions[0].id);
    }
    return { filesToEdit, addFilesFormDataTemplate };
  }, [ticket, auth]);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: ticket.title,
      description: ticket.description
        ? EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(String(ticket.description)).contentBlocks,
              convertFromHTML(String(ticket.description)).entityMap,
            ),
          )
        : '',
      date: new Date(executionDate),
      user: customOption,
    },
  });

  const addStorageFileReference = useCallback((storageFileData) => {
    setFilesFromStorage((files) => {
      const alreadyAdded = files.some((fileData) => fileData.id === storageFileData.id);
      if (alreadyAdded) return files;
      return [...files, storageFileData];
    });
    showSnackbar('Ссылка на файл из хранилища добавлена', 'success');
  }, []);

  const addFilesFromStorage = async () => {
    const filesIds = filesFromStorage.map((file) => file.id);

    await axios
      .post('/api/ticket_action_files/add_from_storage', {
        ticket_action_id: ticket.ticket_actions[0].id,
        storage_files: filesIds,
      })
      .catch(() => showSnackbar('Возникла ошибка при отправке файлов из хранилища'));
  };

  const editTicket = async (data) => {
    try {
      if (filesFromStorage.length) {
        await addFilesFromStorage();
      }

      await axios.put(`${server_url}/api/tickets/edit`, data);

      if (filesDeleteArr.length) {
        const filesRemoveReq = {
          ticket_action_id: ticket.ticket_actions[0].id,
          files: filesDeleteArr.map((f) => f.id),
          form_id: formId,
        };
        await axios.patch('/api/ticket_action_files/remove', filesRemoveReq).catch(() => {
          showSnackbar('Возникла ошибка при удалении файла');
        });
      }

      dispatch(ticketsApi.util.invalidateTags(['Tickets', 'FromMe']));

      onClose();
    } catch (error) {
      showSnackbar('Возникла ошибка при редактировании поручения');
    }
  };

  const onSubmit = async (data) => {
    editTicket(transformFormData(ticket.id, data));
  };

  const onCloseHandler = async () => {
    const onRemove = removeFilesOnClose.current;

    if (onRemove) {
      await onRemove({ ticket_action_id: ticket.ticket_actions[0].id });
    }

    onClose();
  };

  const openFileSelectPopup = () => {
    document.getElementById('upfile').click();
  };

  const CustomOption = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        <Wrapper align={'center'}>
          {props.data?.value && <Avatar size={24} userId={props.data.value} />} {children}
        </Wrapper>
      </components.Option>
    );
  };

  const CustomValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        <Wrapper align={'center'}>
          {props.data.value && <Avatar size={24} userId={props.data.value} />} {children}
        </Wrapper>
      </components.SingleValue>
    );
  };

  return (
    <>
      <Modal
        type="submit"
        modalSize="767px"
        title="Редактировать поручение"
        confirmButtonText={'Сохранить'}
        onSave={handleSubmit(onSubmit)}
        onClose={() => {
          if (isDirty) {
            setConfirmModal(true);
            return;
          }
          onCloseHandler();
        }}
      >
        <StyledForm>
          <div>
            <Label>Кому</Label>
            <Controller
              name="user"
              control={control}
              render={({ field }) => (
                <SingleSelect
                  {...field}
                  options={options}
                  components={{ Option: CustomOption, SingleValue: CustomValue }}
                />
              )}
            />
          </div>

          <div>
            <Label>Название</Label>
            <Controller name="title" control={control} render={({ field }) => <Input {...field} error={false} />} />
          </div>

          <div>
            <Label>Описание</Label>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <RichTextEditor
                  {...field}
                  onChange={(value) => {
                    setValue('description', value, { shouldDirty: true });
                    field.onChange(value);
                  }}
                  extraButtons={
                    <>
                      <IconButton size={14} icon={attachIcon} onClick={openFileSelectPopup}>
                        <input
                          type="file"
                          multiple
                          id="upfile"
                          style={{ display: 'none' }}
                          onChange={(e) => setFilesToUpload([...e.target.files])}
                        />
                      </IconButton>
                      <IconButton size={14} icon={databaseIcon} onClick={() => setShowStorage(true)}></IconButton>
                    </>
                  }
                />
              )}
            />

            {(!!filesToUpload?.length || !!filesFromStorage?.length || !!filesToEdit?.length) && (
              <FilesContainer>
                {filesFromStorage.map((f, i) => (
                  <FileToUpload
                    file={f}
                    progressPercent={100}
                    isUploaded
                    removeFile={() => {
                      setFilesFromStorage((files) => files.filter((file, index) => index !== i));
                    }}
                    key={i}
                    index={i}
                    isFromStorage
                  />
                ))}

                <FilesUploadManage
                  formId={formId}
                  filesToUpload={filesToUpload}
                  filesToEdit={filesToEdit}
                  filesDeleteArr={filesDeleteArr}
                  setFilesDeleteArr={setFilesDeleteArr}
                  addFilesFormDataTemplate={addFilesFormDataTemplate}
                  filesUploadPath="/api/ticket_action_files/add"
                  filesDeletePath="/api/ticket_action_files/remove"
                  setIsFilesUploading={setIsFilesUploading}
                  removeFilesOnClose={removeFilesOnClose}
                  clearFileManager={clearFileManager}
                  removeById
                />
              </FilesContainer>
            )}
          </div>

          <div>
            <Label>Срок исполнения</Label>
            <StyledModalRowEnd>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <DateInput
                    {...field}
                    placeholder="Дата"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    timeIntervals={15}
                    timeCaption="Время"
                    timeFormat="p"
                    dateFormat="Pp"
                    showTimeSelect
                  />
                )}
              />
            </StyledModalRowEnd>
          </div>
        </StyledForm>
      </Modal>

      {showStorage && (
          <StorageModal
            addStorageFileReference={addStorageFileReference}
            noURLParams
            close={() => setShowStorage(false)}
          />
      )}

      {confirmModal && (
        <ConfirmAction
          cancel={() => setConfirmModal(false)}
          confirm={onCloseHandler}
          actionText={t('common.confirm_modal_close')}
        />
      )}
    </>
  );
};
