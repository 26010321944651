import { StyledLayout } from '@shared/components/Layout/styles';
import { Title } from '@shared/components/Title/Title';
import React, { useEffect, useMemo, useState } from 'react';

import { useGetTicketsQuery } from 'src/redux/features/api/tickets';

import { TicketCard } from '@components/Tickets/TicketsForMe';

import Preloader from '@components/preloaders/Preloader';

import { TicketModal } from '@components/Tickets/TicketsForMe/TicketCard/ui/TIcketModal';
import { useSearchParams } from 'react-router-dom';

import { omit } from '@shared/utils/omit';

import { Placeholder } from '@shared/components/Placeholder';

import { TicketsContainer } from './styles';

export const PageTicketsForMe = () => {
  const [visible, setVisible] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const ticketId = useMemo(() => searchParams.get('id'), [searchParams]);

  const { data, isLoading } = useGetTicketsQuery();

  useEffect(() => {
    if (!!ticketId) {
      setVisible(true);
    }
  }, [ticketId]);

  return (
    <>
      <StyledLayout>
        <Title>Поручения мне</Title>
        {isLoading ? (
          <Preloader />
        ) : !!data.tickets_for_me.length ? (
          <TicketsContainer>
            {data &&
              data.tickets_for_me.map((ticket, index) => (
                <TicketCard
                  key={index}
                  ticket={ticket}
                  onClick={() => setSearchParams({ ...queryParams, id: ticket.id }, { replace: true })}
                />
              ))}
          </TicketsContainer>
        ) : (
          <Placeholder />
        )}
      </StyledLayout>

      {visible && (
        <TicketModal
          ticketId={ticketId}
          onClose={() => {
            setSearchParams(omit(queryParams, 'id'));
            setVisible(false);
          }}
        />
      )}
    </>
  );
};
