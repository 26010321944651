import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSocketContext } from 'src/contexts/SocketContext';
import { addUnreadNotification, clearNewNotification } from 'src/redux/features/notificationsSlice';

import useParamsCustom from '../urlParams/useParamsCustom';

export const useCheckNotification = () => {
  const dispatch = useDispatch();
  const { readNotification } = useSocketContext();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { projectId, taskId, userId } = useParamsCustom([
    '/projects/:projectId',
    '/projects/:taskProjectId/tasks/:taskId',
    '/team/users/:userId',
  ]);

  // присваивает ссылки в зависимости от вида уведомлений;
  // отправляет ответ о прочтении уведомлений, если юзер находится на соответствующей странице
  const checkNotification = useCallback(
    (newNotifications) => {
      if (!newNotifications) return;
      const readNotifications = [];
      const notificationsToAdd = [];
      for (const notification of newNotifications) {
        let link = '';
        switch (notification.data_type) {
          case 'project_created_responsible':
          case 'project_created_executor':
          case 'project_created_member':
          case 'project_message':
          case 'project_mentioned_in_chat':
          case 'project_result_posted':
          case 'project_result_accepted':
          case 'project_result_rejected':
          case 'project_completed':
          case 'project_returned':
          case 'project_updated_executor':
          case 'project_updated_responsible':
          case 'project_updated_member':
            // если юзер находится на странице проекта, указанного в уведомлении, то отправить, что уведомление прочтено
            if (+projectId === notification.data_id) {
              readNotifications.push(notification.id);
              break;
            }
            link = `/projects/${notification.data_id}`;
            break;

          case 'task_created_responsible':
          case 'task_created_executor':
          case 'task_created_member':
          case 'task_message':
          case 'task_mentioned_in_chat':
          case 'task_result_posted':
          case 'task_result_accepted':
          case 'task_result_rejected':
          case 'task_completed':
          case 'task_returned':
          case 'task_updated_executor':
          case 'task_updated_responsible':
          case 'task_updated_member':
            // если юзер находится на странице задачи, указанного в уведомлении, то отправить, что уведомление прочтено
            if (+taskId === notification.data_id) {
              readNotifications.push(notification.id);
              break;
            }
            link = `/projects/${notification.root_project_id}/tasks/${notification.data_id}`;
            break;

          case 'ticket_assigned':
          case 'ticket_accepted':
          case 'ticket_rejected':
            // если юзер находится на странице поручения, указанного в уведомлении, то отправить, что уведомление прочтено
            if (location.pathname.includes('/tickets/me') && +searchParams.get('id') === notification.data_id) {
              readNotifications.push(notification.id);
              break;
            }
            link = `/tickets/me?id=${notification.data_id}`;
            break;

          case 'ticket_completed':
            // если юзер находится на странице поручения, указанного в уведомлении, то отправить, что уведомление прочтено
            if (location.pathname.includes('/tickets') && +searchParams.get('id') === notification.data_id) {
              readNotifications.push(notification.id);
              break;
            }
            link = `/tickets?filter=active&page=1&id=${notification.data_id}`;
            break;

          case 'team_new_subordinate':
          case 'team_new_colleague':
            // если юзер находится на странице пользователя, указанного в уведомлении, то отправить, что уведомление прочтено
            if (+userId === notification.data_id) {
              readNotifications.push(notification.id);
              break;
            }
            link = `/team/users/${notification.data_id}`;
            break;

          default:
            break;
        }
        if (link) notificationsToAdd.push({ ...notification, link });
      }
      // отправить сокет сообщение о прочтении уведомлений, если в результате нахождения юзера на
      // соответствующей странице, уведомления были отфильтрованы
      if (readNotifications.length) readNotification(readNotifications);
      // добавить новые уведомления к остальным уведомлениям в redux
      if (notificationsToAdd.length) {
        dispatch(addUnreadNotification(notificationsToAdd));
      } else dispatch(clearNewNotification());
    },
    [projectId, searchParams, location],
  );

  return checkNotification;
};
