import { useFieldArray } from 'react-hook-form';
import { StyledButtonPlus } from '@components/form/select/styles';
import { Container } from '@shared/components';
import plusIcon from '@assets/images/icons/plus.svg';

import PollOptionField from './PollOptionField';

const PollOptions = ({ control, register }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'response_options',
  });
  return (
    <div>
      <Container gap="1rem" align="center" style={{ marginBottom: '0.7rem' }}>
        <label>Варианты ответа</label>
        <StyledButtonPlus icon={plusIcon} onClick={() => append({ value: '' })} />
      </Container>
      {/* <IconButton icon={plusIcon}  /> */}
      <ol style={{ paddingLeft: '20px', listStyle: 'decimal' }}>
        {fields.map((field, index) => {
          return (
            <PollOptionField
              field={field}
              index={index}
              key={index}
              register={register}
              remove={remove}
              handleDelete={() => remove(index)}
            />
          );
        })}
      </ol>
    </div>
  );
};

export default PollOptions;
