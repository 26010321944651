import React from 'react';

import { Text } from '@shared/components/Text/Text';
import { Avatar } from '@shared/components/Avatar';

import { Button } from '@shared/components/Button/Button';

import moment from 'moment';

import { Status } from 'src/shared/components/Status';

import { Card, Container, Title, UserCard } from './styles';

export const TicketCard = ({ ticket, onClick }) => {
  const { id, title, creator_id, creator_avatar, creator_last_name, creator_first_name, date_finish, ticket_actions } =
    ticket;

  const date = new Date(date_finish);
  const action = ticket_actions[0].action;

  const getTicketAction = (action) => {
    switch (action) {
      case 'created':
        return 'Ожидает выполнения';
      case 'canceled':
        return 'Отменено';
      case 'confirmation':
        return 'Ожидает проверки';
      case 'confirmed':
        return 'Подтверждено';
      case 'returned':
        return 'Возрващено';
      default:
        break;
    }
  };

  const getActionColor = (action) => {
    switch (action) {
      case 'created':
        return '';
      case 'canceled':
        return 'red';
      case 'confirmation':
        return 'yellow';
      case 'confirmed':
        return 'green';
      case 'returned':
        return 'red';
      default:
        break;
    }
  };

  return (
    <>
      <Card key={id}>
        <Container>
          <Status color={getActionColor(action)}>{getTicketAction(action)}</Status>
          <Text as="span" size={2}>
            Исполнить до {moment(date).format('L')} {moment(date).format('LT')}
          </Text>
          <Title>{title}</Title>

          <UserCard>
            <Avatar userId={creator_id} size={32} showProfileOnClick />
            <Text size="2">
              {creator_last_name} {creator_first_name}
            </Text>
          </UserCard>
        </Container>
        <Button onClick={onClick}>Подробнее</Button>
      </Card>
    </>
  );
};
