import {useCallback, useContext, useRef, memo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {Search} from '@shared/components';
import SnackbarContext from 'src/contexts/SnackbarContext';
import {clearStorageSearchResults, searchStorage} from 'src/redux/features/storageSlice';

SearchStorage.propTypes = {
  storage_id: PropTypes.number,
};

function SearchStorage({storage_id}) {
  const {showSnackbar} = useContext(SnackbarContext);

  const {control, handleSubmit, reset, getValues} = useForm({defaultValues: {search_expression: ''}});

  const dispatch = useDispatch();

  const isSearchingStorage = useSelector((state) => state.storage.isSearchingStorage);
  const storageSearchResult = useSelector((state) => state.storage.storageSearchResult);

  // при переходе по папке, storageSearchResult очищается
  // здесь проверяется на null и поисковая строкая очищается
  useEffect(() => {
    if (storageSearchResult === null && getValues('search_expression')) {
      reset();
    }
  }, [storageSearchResult, getValues]);

  const handleStorageSearch = useCallback(async (data) => {
    if (!storage_id) return;
    const search_expression = data.search_expression.trim();
    if (!search_expression) {
      dispatch(clearStorageSearchResults());
      return;
    }
    dispatch(searchStorage({search_expression, storage_id, showSnackbar}));
  }, []);

  const delayedSearchTimeout = useRef();

  const triggerSubmit = useCallback(() => {
    if (delayedSearchTimeout.current) clearTimeout(delayedSearchTimeout.current);
    handleSubmit(handleStorageSearch)();
  }, [handleSubmit]);

  const startDelayedSearch = useCallback(() => {
    if (delayedSearchTimeout.current) clearTimeout(delayedSearchTimeout.current);
    delayedSearchTimeout.current = setTimeout(triggerSubmit, 1000);
  }, []);

  return (
    <Controller
      control={control}
      name="search_expression"
      render={({field}) => {
        return (
          <Search
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.value);
              startDelayedSearch();
            }}
            onSubmit={triggerSubmit}
            isLoading={isSearchingStorage}
          />
        );
      }}
    />
  );
}

export default memo(SearchStorage);
