import { useDispatch } from 'react-redux';
import { rtkQueryApi } from 'src/redux/features/api/rtkQueryApi';
import { Link } from 'react-router-dom';
import { Text } from '@shared/components/Text/Text';
import { Container } from '@shared/components/Container';
import { useSocketContext } from 'src/contexts/SocketContext';
import { removeNotification } from 'src/redux/features/notificationsSlice';
import { showLocalDateTime } from 'src/utilize/helper-functions';

import { notificationTypes } from '../notifications.types';

import { Card, StyledDate, Title } from './styles';

export const NotificationCard = ({ notification, status }) => {
  const dispatch = useDispatch();
  const { readNotification } = useSocketContext();

  const readNoteCallback = (data) => {
    if (data?.status === 'ok') {
      dispatch(removeNotification(notification.id));
      dispatch(rtkQueryApi.util.invalidateTags(['openedNotifications']));
    }
  };

  return (
    <Card>
      <Container width="100%" justify="space-between">
        <Link
          to={notification.link}
          onClick={() => {
            if (status === 'opened') return;
            readNotification([notification.id], readNoteCallback);
          }}
        >
          <Container direction="column" gap={'4px'}>
            <Text size="2">{notificationTypes[notification.data_type]?.text}</Text>

            <Container align="center">
              <Title>
                {notification.project_title ||
                  notification.task_title ||
                  notification.ticket_title ||
                  `${notification.employee_last_name || ''} ${notification.employee_first_name}`}
              </Title>
            </Container>
          </Container>
        </Link>

        <StyledDate>
          <Text as="span" size={2}>
            {showLocalDateTime(notification.date_update, null, true)}
            {/* {moment(notificationDate).format('L')} {moment(notificationDate).subtract('03:00:00').format('LT')} */}
          </Text>
        </StyledDate>
      </Container>
    </Card>
  );
};
