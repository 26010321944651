import { useSocketContext } from 'src/contexts/SocketContext';
import { useMessageContext } from 'src/contexts/MessageContext';

import MessageBox from './MessageBox';

const MessageContainer = (props) => {
  const { showPopover, editMessage, showUserProfile } = useMessageContext();
  const { sendResultFeedback } = useSocketContext();

  return (
    <MessageBox
      {...props}
      showPopover={showPopover}
      editMessage={editMessage}
      sendResultFeedback={sendResultFeedback}
      showUserProfile={showUserProfile}
      pinnedMessageType={props.pinnedMessageType}
    />
  );
};

export default MessageContainer;
