import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import useAuth from 'src/hooks/useAuth';
import { url_get_data_employee_create, url_get_data_employee_edit } from 'src/settings/base-url';

import EmployeesFormBody from './EmployeesFormBody';

// import { initSocket } from "../../../settings/sockets";

// employeeData - предыдущие значения полей при редактированиии
const EmployeesForm = ({ onCloseModal, action, employeeData }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const translateKey = 'EmployeesForm'; // для перевода
  // const socket = initSocket(auth.token);

  // инфа для построения модалки - свободные сотрудники,  отделы и тд  и статус загрузки
  const [infoIsLoad, setInfoIsLoad] = useState(null);

  // получаем списки отделов, сотрудников для отображения selectов
  useEffect(() => {
    // socket.emit("add_to_room", "employee_create_change");
    if (action === 'add') {
      axios.get(url_get_data_employee_create).then(
        (res) => {
          setInfoIsLoad(res.data.dataForEmployeeCreation);
        },
        () => {},
      );
    } else {
      const id = employeeData?.employee[0]?.id;
      if (id) {
        axios.get(url_get_data_employee_edit + `/${id}`).then(
          (res) => {
            setInfoIsLoad(res.data.dataForEmployeeEdition);
          },
          () => {},
        );
      }
    }
  }, [auth.token, action, employeeData?.employee]);

  // настройка для полей формы
  const inputs = {
    full_name: [
      {
        name: 'first_name',
        label: t(`${translateKey}.first_name`),
        type: 'text',
        prev: employeeData?.employee[0].first_name || '',
        isRequired: true,
      },
      {
        name: 'last_name',
        icon: true,
        label: t(`${translateKey}.last_name`),
        type: 'text',
        prev: employeeData?.employee[0].last_name || '',
      },
    ],
    gender: [
      {
        name: 'sex',
        icon: true,
        type: 'radio',
        radio_btns: [
          {
            name: 'sex',
            icon: true,
            label: t(`${translateKey}.male`),
            value: 'male',
            type: 'text',
          },
          {
            name: 'sex',
            icon: true,
            value: 'female',
            label: t(`${translateKey}.female`),
            type: 'text',
          },
        ],
        label: t(`${translateKey}.gender`),
        prev: employeeData?.employee[0].sex || '',
      },
    ],
    email_phone: [
      {
        name: 'email',
        icon: true,
        label: t(`${translateKey}.email`),
        type: 'text',
        prev: employeeData?.employee[0].email || '',
        isRequired: true,
      },
      {
        name: 'contacts_email',
        icon: true,
        label: 'Почта для уведомлений',
        type: 'text',
        prev: employeeData?.employee[0].contacts_email || '',
      },
      {
        name: 'contacts_phone',
        icon: true,
        label: t(`${translateKey}.phone`),
        type: 'text',
        prev: employeeData?.employee[0].contacts_phone || '',
      },
      {
        name: 'telegram',
        icon: true,
        label: t(`${translateKey}.telegram`),
        type: 'text',
        prev: employeeData?.employee[0].telegram || '',
      },
    ],
  };

  return (
    <>
      {infoIsLoad && (
        <EmployeesFormBody
          onCloseModal={onCloseModal}
          action={action}
          employeeData={employeeData}
          inputs={inputs}
          birthday={{
            name: 'birthday',
            icon: true,
            label: t(`${translateKey}.DOB`),
          }}
          // другие контакты
          other_contacts={{
            name: 'contacts_other',
            icon: true,
            label: t(`${translateKey}.other_contacts`),
            type: 'text',
          }}
          address={{
            name: 'contacts_address',
            icon: true,
            label: t(`${translateKey}.address`),
            type: 'text',
          }}
          passport_visible={{
            name: 'passport_visible',
            icon: true,
            label: 'Паспортные данные (открытые для всех)',
            type: 'text',
          }}
          passport_hidden={{
            name: 'passport_hidden',
            icon: true,
            label: 'Паспортные данные (скрытые от всех)',
            type: 'text',
          }}
          // должность
          position={{
            name: 'position_id',
            icon: true,
            label: t(`${translateKey}.position`),
            type: 'select',
            options: infoIsLoad?.positions || [],
          }}
          // руководитель отдела
          head_department={{
            name: 'head_id',
            label: t(`${translateKey}.head_department`),
            type: 'select',
            options: infoIsLoad?.heads || [],
          }}
          // отдел
          department={{
            name: 'department_id',
            icon: true,
            label: t(`${translateKey}.department`),
            type: 'text',
            options: infoIsLoad?.departments?.sort((a, b) => a.title.localeCompare(b.title)) || [],
          }}
          password={{
            name: 'password',
            icon: true,
            label: t(`common.password`),
            type: 'text',
            isRequired: true,
          }}
          // когда начал работать
          date_start={{
            name: 'date_start',
            label: t(`${translateKey}.start_to_work`),
            type: 'text',
            isRequired: true,
          }}
          // права
          rights={{
            name: 'rights',
            icon: true,
            label: t('common.rights.rights'),
            type: 'select',
            subType: 'tag',
            placeholder: 'Выберите права',
            //options: infoIsLoad?.rights.map(right => t(`common.rights.${right}`)) || [],
            options: infoIsLoad?.rights || [],
          }}
        />
      )}
    </>
  );
};

export default EmployeesForm;
