import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import SelectMulty from 'src/components/form/select/SelectMulty';
import { CustomOption, getOptions } from 'src/components/form/select/userSelectComponents';
import useAuth from 'src/hooks/useAuth';

const PollTarget = ({ control }) => {
  const pollTarget = useWatch({ control, name: 'poll_target' });

  const employees = useSelector((state) => state.users.employees);
  const departments = useSelector((state) => state.users.departments);
  const auth = useAuth();
  const userOptions = useMemo(() => getOptions({ users: employees, token: auth.token }), [employees]);
  const deptOptions = useMemo(() => {
    if (!departments) return;
    return departments.map((d) => ({ label: d.title, value: { id: d.id } }));
  }, [departments]);

  const usersSelectSettings = useMemo(
    () => ({
      label: '',
      name: 'respondents',
    }),
    [],
  );

  const deptSelectSettings = useMemo(
    () => ({
      label: '',
      name: 'departments',
    }),
    [],
  );

  return (
    <>
      {pollTarget === 'users' && (
        <div>
          <SelectMulty
            item={usersSelectSettings}
            control={control}
            options={userOptions}
            customOption={CustomOption}
            getOptionValue={(option) => option.value.id}
          />
        </div>
      )}
      {pollTarget === 'departments' && (
        <div>
          <SelectMulty
            item={deptSelectSettings}
            control={control}
            options={deptOptions}
            // customOption={CustomOption}
            getOptionValue={(option) => option.value.id}
          />
        </div>
      )}
    </>
  );
};

export default PollTarget;
