import { useState } from 'react';
import { Container, IconButton } from '@shared/components';
import iconDelete from '@assets/images/icons/delete-circle.svg';
import { PreviewModal } from '@shared/widgets/PreviewModal';
import { StyledAttachImage, StyledAttachItem } from '@components/files/styles';
import { FileLoader } from '@shared/widgets/FileLoader';
import ConfirmAction from '@components/warnings/ConfirmAction';
import useAuth from 'src/hooks/useAuth';
import { downloadFileUrl, server_url, staticFilesPathPart } from 'src/settings/base-url';
import { useDownloader } from '@shared/hooks/useDownloader';
import { isFileNameImage, isPreviewFile } from 'src/utilize/helper-functions';
import { unique13NumRegex } from 'src/utilize/regex';

import AttachedFileMenu from './AttachedFileMenu/AttachedFileMenu';
import FileVersionsPopup from './FileVersionsPopup/FileVersionsPopup';
import { getFileIcon } from './lib';

const AttachedFile = ({
  fileUrl,
  fileName,
  removeFile,
  storageFileId, // id файла в хранилище
  fileId,
  setOpenImgId, // опционально - функция для открытия модального окна галереи с указанным id изображения
  contextMenu,
  creator_id,
}) => {
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [fileSize, setFileSize] = useState(0);

  const auth = useAuth();

  const url = `${downloadFileUrl}${encodeURIComponent(fileUrl)}?token=${auth.token}`;

  const { downloadFile } = useDownloader();

  const onConfirm = () => {
    downloadFile({ url: fileUrl, filename: fileName, forceDownload: true });
    setConfirmModal(false);
  };

  return (
    <>
      <StyledAttachItem>
        <Container align={'center'} gap={'16px'}>
          {isFileNameImage(fileName) ? (
            <>
              <StyledAttachImage
                href={fileUrl}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  if (setOpenImgId) setOpenImgId(fileId);
                  else setPreviewModalVisible(true);
                }}
              >
                <img
                  loading="lazy"
                  src={`${server_url}/${staticFilesPathPart}/${encodeURIComponent(fileUrl)}?token=${auth.token}`}
                  alt=""
                />
              </StyledAttachImage>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (!setOpenImgId) setPreviewModalVisible(true);
                  else setOpenImgId(fileId);
                }}
              >
                {fileName?.replace(unique13NumRegex, '.')}
              </div>
            </>
          ) : (
            <div
              onClick={() => {
                downloadFile({ url: fileUrl, filename: fileName, token: auth.token }).then(({ fileDetails }) => {
                  if (fileDetails.isLarge) {
                    setConfirmModal(true);
                    setFileSize(fileDetails.size);
                  }
                });
              }}
            >
              <Container
                onClick={(e) => {
                  if (isPreviewFile(fileName)) {
                    e.stopPropagation();
                    e.preventDefault();
                    setPreviewModalVisible(true);
                  }
                }}
                align={'center'}
              >
                <StyledAttachImage icon={getFileIcon(fileName)} />
                <span>{fileName?.replace(unique13NumRegex, '.')}</span>
              </Container>
            </div>
          )}
          <FileLoader url={fileUrl} filename={fileName} token={auth.token} />
          {contextMenu && (
            <AttachedFileMenu
              contextMenu={contextMenu}
              fileData={{ fileId, fileUrl, fileFullUrl: url, fileName, creator_id }}
            />
          )}
        </Container>

        {storageFileId && (
          <FileVersionsPopup
            storageFileId={storageFileId}
            fileId={fileId}
            mainVersionUrl={fileUrl}
            extraButton={<FileLoader url={fileUrl} filename={fileName} token={auth.token} />}
          />
        )}

        {removeFile && (
          <IconButton
            size={18}
            icon={iconDelete}
            onClick={(e) => {
              e.stopPropagation();
              removeFile();
            }}
          />
        )}

        {previewModalVisible && (
          <PreviewModal
            url={url}
            filePath={fileUrl}
            fileName={fileName}
            onClose={() => setPreviewModalVisible(false)}
          />
        )}
      </StyledAttachItem>

      {confirmModal && (
        <ConfirmAction
          cancel={() => setConfirmModal(false)}
          confirm={onConfirm}
          actionText={`Вы уверены, что хотите скачать файл? Он весит ${fileSize} Мб`}
          confirmButtonText={'Да'}
          cancelButtonText={'Нет'}
          isSuccess
        />
      )}
    </>
  );
};

export default AttachedFile;
