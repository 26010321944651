import React, { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { department_window, position_window } from 'src/settings/window-types';
import TagsModal from 'src/components/tags/TagsModal';
import ContactGroupsModal from 'src/windows/contact-groups/ContactGroupsModal';
import NotificationsSettings from 'src/windows/notifications/NotificationsSettings';
import useAuth from 'src/hooks/useAuth';

import { useGetLinksQuery } from 'src/redux/features/api/links';

import {HeaderSiteNav, SiteNavLink, SiteNavList} from "@components/nav/styles";
import iconArrow  from "@assets/images/icons/dropdown.svg";

import SiteNavInnerList from './siteNavInnerList';
import { AdditionalInfoModal } from './AdditionalInfoModal';

const HeaderSiteNavList = ({
  mobilMenu,
  navListOpen,
  handlerSelectList,
  handlerBlur,
  handlerMobilMenu,
  popupWindow,
}) => {
  const { t } = useTranslation();
  // для открывания модального окна с тэгами
  const [tagsModal, toggleTagsModal] = useState();
  const [contactGroupsModal, setContactGroupsModal] = useState();
  const [notificationsModal, setNotificationsModal] = useState();
  const [additionalInfoModal, setAdditionalInfoModal] = useState();

  const auth = useAuth();
  const isDeveloper = useMemo(() => auth.user?.rights?.includes('developer'), [auth]);
  const isGuest = useMemo(() => !!auth?.user?.is_guest);

  let navigate = useNavigate();

  const { data: links } = useGetLinksQuery({}, { pollingInterval: 1000 * 60 * 5 });

  // список меню Настройки (в зависимости от текущих прав юзера)
  const settingsMenu = useMemo(() => {
    const settingsMenu = {
      [t('SiteNavInnerList_1.manage_department')]: {
        // открыть окно со списком отделов
        handler: () => popupWindow.props.onOpen(department_window),
        visible: auth?.isUserRightful('departments_edit'),
      },

      [t('SiteNavInnerList_1.manage_position')]: {
        // открыть окно со списком должностей
        handler: () => popupWindow.props.onOpen(position_window),
        visible: auth?.isUserRightful('positions_edit'),
        // to: "./",
      },
      [t('common.tags')]: {
        handler: () => toggleTagsModal(true),
        to: './',
        visible: true,
      },
      'Группы контактов': {
        // открыть окно со списком групп контактов
        handler: () => setContactGroupsModal(true),
        visible: auth?.isUserRightful('contacts_edit'),
      },
      'Доп. информация в контакты': {
        handler: () => setAdditionalInfoModal(true),
        visible: auth?.isUserRightful(auth.rightTypes.contacts_edit),
      },
      [t('HeaderSiteNavList.links')]: {
        // открыть окно ссылок
        handler: () => {
          handlerSelectList(false);
          navigate('links');
        },
        visible: auth.isUserRightful(auth.rightTypes.links_edit),
      },
      'Подписка на уведомления': {
        to: '/notifications/subscriptions',
        visible: true,
        separator: true,
      },
      'Отправка уведомлений': {
        visible: true,
        handler: () => setNotificationsModal(true),
      },
    };

    return settingsMenu;
  }, [auth]);

  return (
    <>
      {auth.user?.is_guest === 0 && (
        <HeaderSiteNav
          tabIndex={1}
          onBlur={() => {
            handlerBlur();
          }}
          $active={mobilMenu}
        >
          {/* выпадающее навигационное меню - Ссылки */}
          {!!links?.length && (
            <SiteNavList $active={navListOpen === 'links'}>
              <SiteNavLink onClick={() => handlerSelectList('links')} icon={iconArrow}>
                {t('HeaderSiteNavList.links')}
              </SiteNavLink>
              <SiteNavInnerList
                handlerMobilMenu={handlerMobilMenu}
                mobilMenu={mobilMenu}
                lists={{}}
                links={links}
              />
            </SiteNavList>
          )}

          <SiteNavLink as={Link}
            onClick={() => handlerMobilMenu && handlerMobilMenu('close')}
            to="/team/users"
          >
            {t('HeaderSiteNavList.team')}
          </SiteNavLink>

          {/* выпадающее навигационное меню - Работа */}
          <SiteNavList $active={navListOpen === 'work'}>
            <SiteNavLink onClick={() => handlerSelectList('work')} icon={iconArrow}>
              Работа
            </SiteNavLink>
            <SiteNavInnerList
              handlerMobilMenu={handlerMobilMenu}
              mobilMenu={mobilMenu}
              navListOpen={navListOpen}
              id="work"
              list={{
                Проекты: {
                  // открыть страницу проектов
                  to: '/projects',
                  visible: true,
                },
                'Виртуальные офисы': {
                  // открыть страницу виртуальных офисов
                  to: '/offices',
                  visible: true,
                },
                'Мои задачи': {
                  // открыть страницу Мои задачи
                  to: '/tasks?relation=creator,responsible,executor,member',
                  visible: true,
                },
                // функционал поручений пока убрали
                // 'Поручения мне': {
                //   separator: true,
                //   to: '/tickets/me',
                //   visible: !isGuest,
                // },
                // 'Поручения от меня': {
                //   to: '/tickets?filter=active&page=1',
                //   visible: !isGuest,
                // },
                Хранилища: {
                  separator: true,
                  to: '/storages',
                  visible: true,
                },
                Напоминания: {
                  separator: true,
                  to: '/reminders',
                  visible: true,
                },
                'Версии портала': {
                  separator: true,
                  to: '/versions',
                  visible: isDeveloper,
                },
              }}
            />
          </SiteNavList>

          {/* выпадающее навигационное меню - Компания */}
          <SiteNavList $active={navListOpen === 'company'}>
            <SiteNavLink onClick={() => handlerSelectList('company')} icon={iconArrow}>
              Компания
            </SiteNavLink>
            <SiteNavInnerList
              handlerMobilMenu={handlerMobilMenu}
              mobilMenu={mobilMenu}
              navListOpen={navListOpen}
              id="company"
              list={{
                Новости: {
                  // открыть страницу Новости
                  to: '/news',
                  visible: true,
                },
                Блоги: {
                  // открыть страницу Блоги
                  to: '/blogs',
                  visible: true,
                },
              }}
            />
          </SiteNavList>

          {/* навигационное меню - Контакты  */}
          {(auth.isUserRightful('contacts_view') || auth.isUserRightful('contacts_edit')) && (
            <SiteNavLink as={Link}
              onClick={() => handlerMobilMenu && handlerMobilMenu('close')}
              to="/contacts"
            >
              Контакты
            </SiteNavLink>
          )}

          {/* выпадающее навигационное меню - Настройки */}
          <SiteNavList $active={navListOpen === 'settings'}>
            <SiteNavLink onClick={() => handlerSelectList('settings')} icon={iconArrow}>
              {t('HeaderSiteNavList.settings')}
            </SiteNavLink>
            <SiteNavInnerList mobilMenu={mobilMenu} navListOpen={navListOpen} id="settings" list={settingsMenu} />
          </SiteNavList>
        </HeaderSiteNav>
      )}

      {tagsModal && <TagsModal onClose={() => toggleTagsModal(false)}/>}

      {contactGroupsModal && <ContactGroupsModal onClose={() => setContactGroupsModal(false)} />}

      {notificationsModal && <NotificationsSettings close={() => setNotificationsModal(false)} />}

      {additionalInfoModal && <AdditionalInfoModal onClose={() => setAdditionalInfoModal(false)} />}
    </>
  );
};

export default HeaderSiteNavList;
