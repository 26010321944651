import { Radio } from '@shared/components/Radio/Radio';

const PollTypeRadioButtons = ({ value, onChange }) => {
  const radioButtons = [
    {
      id: 'var1',
      label: 'Анонимный',
      value: 'anonymous',
      checked: value === 'anonymous',
      name: 'type',
      onChange: () => onChange('anonymous'),
    },
    {
      id: 'var2',
      label: 'Не анонимный',
      value: 'identified',
      checked: value === 'identified',
      name: 'type',
      onChange: () => onChange('identified'),
    },
  ];

  return <Radio items={radioButtons} />;
};

export default PollTypeRadioButtons;
