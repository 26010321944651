import { useRef } from 'react';
import styled from 'styled-components';
import { Input, IconButton, Container } from '@shared/components';
import iconDelete from '@assets/images/icons/delete.svg';
import iconAttach from '@assets/images/icons/icon-links.svg';

const ListItem = styled.li`
  padding-left: 20px;
  margin: 20px 0
`;

const PollOptionField = ({ field, index, register, handleDelete }) => {
  const pollOptionFileInputRef = useRef();

  return (
    <ListItem key={field.id}>
      <input ref={pollOptionFileInputRef} type="file" style={{ display: 'none' }} />
      <Container align="center" gap="20px">
        <Input key={field.id} register={{ ...register(`response_options.${index}.value`) }} />
        <IconButton icon={iconAttach} onClick={() => pollOptionFileInputRef.current.click()} />
        <IconButton icon={iconDelete} onClick={handleDelete} />
      </Container>
    </ListItem>
  );
};

export default PollOptionField;
