import { Radio } from '@shared/components/Radio/Radio';

const PollTargetRadioButtons = ({ value, onChange }) => {
  const radioButtons = [
    {
      id: 'var3',
      label: 'Всем участникам чата',
      value: 'chat_members',
      checked: value === 'chat_members',
      name: 'poll_target',
      onChange: () => onChange('chat_members'),
    },
    {
      id: 'var4',
      label: 'По пользователям',
      value: 'users',
      checked: value === 'users',
      name: 'poll_target',
      onChange: () => onChange('users'),
    },
    {
      id: 'var5',
      label: 'По отделам',
      value: 'departments',
      checked: value === 'departments',
      name: 'poll_target',
      onChange: () => onChange('departments'),
    },
    {
      id: 'var6',
      label: 'Всем',
      value: 'all',
      checked: value === 'all',
      name: 'poll_target',
      onChange: () => onChange('all'),
    },
  ];

  return <Radio items={radioButtons} />;
};

export default PollTargetRadioButtons;
