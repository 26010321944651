import {useState, useCallback, memo, useMemo, useRef} from 'react';
import ReactDOM from 'react-dom';
import {useTranslation} from 'react-i18next';
import {CreateTicketModal} from '@components/Tickets/TicketsFromMe';
import {CreateReminderModal} from '@components/Reminders';
import useAuth from 'src/hooks/useAuth';
import useCoordinates from 'src/hooks/useCoordinates';
import {useSocketContext} from 'src/contexts/SocketContext';
import {useMessageContext} from 'src/contexts/MessageContext';
import {randomSequence, useOutsideTrigger} from 'src/utilize/helper-functions';
import ConfirmAction from 'src/components/warnings/ConfirmAction';
import CreateTaskModal from 'src/components/tasks/CreateTaskModal';
import ProjectFormCreateEditBody from 'src/windows/projects/project-form-create-edit/ProjectFormCreateEditBody';
import LocalDateTime from 'src/components/shared/LocalDateTime';
import {StyledChatMenu, StyledChatMenuItem, StyledChatMenuSeparator} from '@components/chat/styles';

import {StyledControls, StyledControlWrapper, StyledMessageDots} from '@components/chat/message/styles';

import iconDots from '@assets/images/icons/subtask-dots.svg';

import MessageTagsModal from './MessageTagsModal';
import MessagePinBtn from './MessagePinBtn';
import ForwardMessageMenuItem from './MessageControlItems/ForwardMessageMenuItem';
import SelectMessageMenuItem from './MessageControlItems/SelectMessageMenuItem';

const MessageControlsContainer = (props) => {
  const {guestRights} = useMessageContext();

  return <MessageControls {...props} guestRights={guestRights}/>;
};

const MessageControls = memo(
  ({
     projectTitle,
     date_created,
     editor_state,
     employee_id,
     fullName,
     guestRights,
     handleMessageEditClick,
     hideProjectOptions,
     hidePin,
     messageId,
     message,
     messageTags,
     pinned,
     isMarked,
     setIsMarked,
     goToMessage,
     projectId,
     taskId,
     isForwarded,
     replyByQuoting
   }) => {
    const auth = useAuth();
    // const guestRights = null;
    const [dotsMenu, toggleDotsMenu] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState();
    const [taskOrProjectCreateModal, showTaskOrProjectCreateModal] = useState();
    const [ticketModalVisible, setTicketModalVisible] = useState(false);
    const [reminderModalVisible, setReminderModalVisible] = useState(false);
    const [tagsModal, toggleTagsModal] = useState(false);
    const {t} = useTranslation();
    const {manageMessagePin, deleteMessage, markMessage, unmarkMessage} = useSocketContext();

    // динамическое позиционирование меню
    const {coordinates, elementRef: dotsButtonRef, tooltipRef: dotsMenuRef} = useCoordinates();


    const refsForPopup = useRef([dotsMenuRef, dotsButtonRef]);
    const closePopup = useCallback(() => toggleDotsMenu(false), []);

    useOutsideTrigger(refsForPopup.current, closePopup, dotsMenu);

    const messageBasis = useMemo(
      () => ({
        message_id: messageId,
        editor_state,
        author: fullName,
        LocalDateTime: <LocalDateTime dateTime={date_created}/>,
      }),
      [messageId, editor_state, fullName, date_created],
    );

    return (
      <>
        <div>
          <StyledControls $active={dotsMenu}>
            <StyledControlWrapper>
              {!hidePin && auth?.user && !auth.user.is_guest && <MessagePinBtn pinned={pinned} messageId={messageId}/>}
            </StyledControlWrapper>
            <StyledControlWrapper>
              <StyledMessageDots
                icon={iconDots}
                size={18}
                onClick={() => toggleDotsMenu((prev) => !prev)}
                ref={dotsButtonRef}
                type="button"
              />
                <StyledChatMenu style={{...coordinates }} $active={dotsMenu} ref={dotsMenuRef} >
                  <div>
                    {(!auth?.user?.is_guest || guestRights?.includes('write')) && (
                      <>
                        {pinned === 1 && (
                          <>
                            <StyledChatMenuItem
                              onClick={() => {
                                goToMessage(messageId);
                                toggleDotsMenu(false);
                              }}
                            >
                              Перейти к сообщению
                            </StyledChatMenuItem>
                            <StyledChatMenuSeparator/>
                          </>
                        )}

                        {auth?.user?.id === employee_id && (
                          <>
                            {!isForwarded && (
                              <StyledChatMenuItem
                                onClick={() => {
                                  toggleDotsMenu(false);
                                  handleMessageEditClick();
                                }}
                              >
                                {t('FormButtons.edit')}
                              </StyledChatMenuItem>
                            )}

                            <StyledChatMenuItem
                              onClick={() => {
                                setConfirmDelete(true);
                                toggleDotsMenu(false);
                              }}
                            >
                              {t('FormButtons.delete')}
                            </StyledChatMenuItem>
                            <StyledChatMenuSeparator/>
                          </>
                        )}

                        <StyledChatMenuItem
                          onClick={() => {
                            toggleDotsMenu(false);
                            replyByQuoting(message);
                          }}
                        >
                          Ответить
                        </StyledChatMenuItem>

                        <StyledChatMenuItem
                          onClick={() => {
                            if (!!isMarked) {
                              unmarkMessage(messageId);
                            } else {
                              markMessage(messageId);
                            }

                            setIsMarked((prev) => !prev);
                            toggleDotsMenu(false);
                          }}
                        >
                          {!!isMarked ? 'Снять отметку' : 'Отметить сообщение'}
                        </StyledChatMenuItem>

                        <StyledChatMenuItem
                          onClick={() => {
                            toggleTagsModal(true);
                            toggleDotsMenu(false);
                          }}
                        >
                          Поставить тэги
                        </StyledChatMenuItem>
                      </>
                    )}

                    {!hideProjectOptions && auth?.user && !auth.user.is_guest && (
                      <>
                        {/*функционал поручений пока убрали*/}
                        {/*<StyledChatMenuItem*/}
                        {/*  onClick={() => {*/}
                        {/*    toggleDotsMenu(false);*/}
                        {/*    showTaskOrProjectCreateModal('task');*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  {t('Project.based_task_create')}*/}
                        {/*</StyledChatMenuItem>*/}
                        {/*<StyledChatMenuItem*/}
                        {/*  onClick={() => {*/}
                        {/*    toggleDotsMenu(false);*/}
                        {/*    setTicketModalVisible(true);*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  {t('Project.based_ticket_create')}*/}
                        {/*</StyledChatMenuItem>*/}
                        <StyledChatMenuItem
                          onClick={() => {
                            toggleDotsMenu(false);
                            setReminderModalVisible(true);
                          }}
                        >
                          {t('Project.based_reminder_create')}
                        </StyledChatMenuItem>
                        <StyledChatMenuItem
                          onClick={() => {
                            toggleDotsMenu(false);
                            showTaskOrProjectCreateModal('project');
                          }}
                        >
                          {t('Project.based_project_create')}
                        </StyledChatMenuItem>
                        <ForwardMessageMenuItem toggleDotsMenu={toggleDotsMenu} messageId={messageId}/>
                        <SelectMessageMenuItem toggleDotsMenu={toggleDotsMenu} messageId={messageId}/>
                      </>
                    )}
                  </div>
                </StyledChatMenu>
            </StyledControlWrapper>
          </StyledControls>
        </div>

        {confirmDelete && (
          <ConfirmAction
            actionText={t('Chat.confirm_delete')}
            cancel={() => setConfirmDelete(false)}
            confirm={() => {
              deleteMessage(messageId);
              setConfirmDelete(false);
              if (pinned) manageMessagePin(pinned, messageId);
            }}
            confirmButtonText="Удалить"
          />
        )}

        {tagsModal && (
          <MessageTagsModal messageTags={messageTags} messageId={messageId} onClose={() => toggleTagsModal(false)}/>
        )}

        {taskOrProjectCreateModal === 'task' && (
          <CreateTaskModal messageBasis={messageBasis} close={() => showTaskOrProjectCreateModal(false)}/>
        )}

        {taskOrProjectCreateModal === 'project' && (
          <ProjectFormCreateEditBody
            formId={randomSequence()}
            onClose={() => showTaskOrProjectCreateModal(false)}
            messageBasis={messageBasis}
          />
        )}

        {ticketModalVisible && (
          <CreateTicketModal
            projectTitle={projectTitle}
            messageId={messageId}
            message={message}
            onClose={() => setTicketModalVisible(false)}
          />
        )}
        {reminderModalVisible && (
          <CreateReminderModal
            projectTitle={projectTitle}
            projectId={projectId}
            taskId={taskId}
            messageId={messageId}
            message={message}
            onClose={() => setReminderModalVisible(false)}
          />
        )}
      </>
    );
  },
);

export default MessageControlsContainer;
