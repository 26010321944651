import { CreateReminderModal } from '@components/Reminders';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SnackbarContext from 'src/contexts/SnackbarContext';
import { useSocketContext } from 'src/contexts/SocketContext';
import { getFavorites } from 'src/redux/features/favoritesSlice';
import { checkAuthError, useOutsideTrigger } from 'src/utilize/helper-functions';
import { StyledChatMenu, StyledChatMenuItem, StyledChatMenuWrapper } from '@components/chat/styles';
import {Button, IconButton} from '@shared/components';
import iconDots from '@assets/images/icons/subtask-dots.svg';
import { colors } from 'src/variables';
import ConfirmAction from '@components/warnings/ConfirmAction';
import useLogout from 'src/hooks/useLogout';

import MenuItemChangeParent from '../chat-menu-items/MenuItemChangeParent';
import MenuItemCreateProject from '../chat-menu-items/MenuItemCreateProject';
import MenuItemChangeCreator from '../chat-menu-items/MenuItemChangeCreator';

const SingleProjectHeaderMenu = ({
  projectStatus,
  userHasRights,
  setEditModal,
  taskId,
  projectId,
  showSubtaskAddModal,
  showConfirmModal,
  showGuestCreateModal,
  canConvert,
  canDelete,
  canChangeCreator,
  manageTaskStatus
}) => {
  const [optionsMenu, toggleOptionsMenu] = useState(false);
  const [reminderModalVisible, setReminderModalVisible] = useState(false);
  const [deleteTaskModal, setDeleteTaskModal] = useState(false);
  const optionsBtnRef = useRef();
  const optionsMenuRef = useRef();
  const refsArray = useRef([optionsMenuRef, optionsBtnRef]);
  const clearOptionsMenu = useCallback(() => toggleOptionsMenu(false), []);
  useOutsideTrigger(refsArray.current, clearOptionsMenu, optionsMenu);

  const dispatch = useDispatch();
  const { showSnackbar } = useContext(SnackbarContext);
  const favorites = useSelector((state) => state.favorites.favorites);

  const navigate = useNavigate();

  useEffect(() => {
    if (!favorites) dispatch(getFavorites({ showSnackbar }));
  }, [favorites]);

  const favoriteData = useMemo(() => {
    return favorites?.find((favorite) => {
      return taskId
        ? favorite.root_project === +projectId && favorite.task_id === +taskId
        : favorite.project_id === +projectId;
    });
  }, [favorites]);

  const { addToFavorites, removeFavorite } = useSocketContext();

  const logout = useLogout();

  const onDeleteProject = () => {
    axios
      .delete(`/api/projects/delete`, { data: { project_id: Number(projectId) } })
      .then(() => {
        navigate(`/projects`);
        showSnackbar('Проект успешно удален', 'success');
      })
      .catch((error) => {
        if (error.response.status === 409)
          showSnackbar('Данный проект нельзя удалить, так как он уже используется в системе', 'error');
        else if (checkAuthError(error)) logout();
        else showSnackbar('Возника ошибка при удалении проекта', 'error');
      })
      .finally(() => setDeleteTaskModal(false));
  };

  const onDeleteTask = () => {
    axios
      .delete(`/api/tasks/delete`, { data: { task_id: Number(taskId) } })
      .then(() => {
        navigate(`/projects/${projectId || ''}`);
        showSnackbar('Задача успешно удалена', 'success');
      })
      .catch((error) => {
        if (error.response.status === 409) {
          showSnackbar('Данную задачу нельзя удалить, так как она уже используется в системе', 'error');
        } else if (checkAuthError(error)) {
          logout();
        } else {
          showSnackbar('Возника ошибка при удалении задачи', 'error');
        }
      })
      .finally(() => setDeleteTaskModal(false));
  };

  const addToFavoritesSocketCallback = (response) => {
    if (response.status === 'ok') {
      dispatch(getFavorites({ showSnackbar }));
      showSnackbar('Добавлено в избранное', 'success');
    }
  };

  const removeFavoriteHandler = () => {
    const removeData = taskId ? { task_id: taskId } : { project_id: projectId };

    removeFavorite(removeData, (response) => {
      if (response.status === 'ok') {
        dispatch(getFavorites({ showSnackbar }));
        showSnackbar('Удалено из избранных', 'success');
      }
    });
  };

  return (
    <>
      <StyledChatMenuWrapper>
        <IconButton
          icon={iconDots}
          background="#F0F0F0"
          size={24}
          style={{ backgroundSize: 'auto' }}
          onClick={() => toggleOptionsMenu(!optionsMenu)}
          $ref={optionsBtnRef}
        />

        <StyledChatMenu ref={optionsMenuRef} $active={optionsMenu}>
          {userHasRights && projectStatus === 'created' && (
            <StyledChatMenuItem
              color={colors.main}
              onClick={() => manageTaskStatus('start')}
            >
              {`Начать ${taskId ? 'задачу' : 'проект'}`}
            </StyledChatMenuItem>
          )}

          {userHasRights && projectStatus && projectStatus !== 'finished' && (
            <StyledChatMenuItem
              color={colors.main}
              onClick={() => showConfirmModal(true)}
            >
              {`Завершить ${taskId ? 'задачу' : 'проект'}`}
            </StyledChatMenuItem>
          )}

          {userHasRights && projectStatus && projectStatus !== 'finished' && (
            <StyledChatMenuItem
              onClick={() => {
                // toggleOptionsMenu(false);
                setEditModal(true);
              }}
            >
              Редактировать
            </StyledChatMenuItem>
          )}

          <StyledChatMenuItem
            onClick={() => {
              const data = {};
              taskId ? (data.task_id = taskId) : (data.project_id = projectId);
              favoriteData ? removeFavoriteHandler() : addToFavorites(data, addToFavoritesSocketCallback);
              toggleOptionsMenu(false);
            }}
          >
            {favoriteData ? 'Удалить из избранных' : 'Добавить в избранное'}
          </StyledChatMenuItem>

          {projectStatus && projectStatus !== 'finished' && (
            <StyledChatMenuItem onClick={() => showSubtaskAddModal(true)}>Добавить задачу</StyledChatMenuItem>
          )}
          {/*{userHasRights && (*/}
          {/*  <StyledChatMenuItem onClick={() => showGuestCreateModal(true)}>Добавить гостевой доступ</StyledChatMenuItem>*/}
          {/*)}*/}
          <StyledChatMenuItem
            onClick={() => {
              toggleOptionsMenu(false);
              setReminderModalVisible(true);
            }}
          >
            Создать напоминание
          </StyledChatMenuItem>
          {userHasRights && canConvert && (
            <MenuItemChangeParent taskId={taskId} projectId={projectId} toggleOptionsMenu={toggleOptionsMenu} />
          )}
          {userHasRights && taskId && <MenuItemCreateProject taskId={taskId} toggleOptionsMenu={toggleOptionsMenu} />}

          {canChangeCreator && <MenuItemChangeCreator toggleOptionsMenu={toggleOptionsMenu} />}

          {userHasRights && canDelete && (
            <StyledChatMenuItem
              onClick={() => {
                toggleOptionsMenu(false);
                setDeleteTaskModal(true);
              }}
              color={colors.error}
            >
              {taskId ? 'Удалить задачу' : 'Удалить проект'}
            </StyledChatMenuItem>
          )}
        </StyledChatMenu>
      </StyledChatMenuWrapper>

      {reminderModalVisible && (
        <CreateReminderModal
          projectId={projectId}
          taskId={taskId}
          onClose={() => {
            setReminderModalVisible(false);
          }}
        />
      )}

      {deleteTaskModal && (
        <ConfirmAction
          actionText={
            taskId
              ? `Задача будет удалена вместе со всеми ее подзадачами. Вы действительно хотите удалить данную задачу?`
              : `Проект будет удален вместе со всеми его подзадачами. Вы действительно хотите удалить данный проект?`
          }
          confirmButtonText={'Удалить'}
          cancelButtonText={'Отмена'}
          confirm={taskId ? onDeleteTask : onDeleteProject}
          cancel={() => setDeleteTaskModal(false)}
        />
      )}
    </>
  );
};

export default SingleProjectHeaderMenu;
