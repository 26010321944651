/* eslint-disable react-hooks/rules-of-hooks */
import { useGetActiveTicketsFromMeQuery, useGetCompletedTicketsFromMeQuery } from 'src/redux/features/api/tickets';

export const useLocalModel = () => {
  const getActiveTickets = ({ page, limit }) => {
    return useGetActiveTicketsFromMeQuery({ page, limit }, { refetchOnFocus: false });
  };

  const getCompletedTickets = ({ page, limit }) => {
    return useGetCompletedTicketsFromMeQuery({ page, limit }, { refetchOnFocus: false });
  };

  return {
    getActiveTickets,
    getCompletedTickets,
  };
};
