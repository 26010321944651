import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledNoScroll = styled.section`
  padding-top: 52px;

  @media (min-width: 1600px) {
    position: fixed;
    width: 100%;
    height: 100vh;
  }
`;

export const StyledIndexLayout = styled.section`
  padding: 50px 24px;
  margin: 0 auto;
  max-width: 1920px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 54px;

  @media (min-width: 1150px) {
    grid-template-columns: 1fr 1fr;
  }

  //@media (min-width: 1600px) {
  //  grid-template-columns: 1fr 1fr 1.5fr;
  //  grid-template-rows: 1fr 1fr;
  //}
`;

export const StyledIndexSection = styled.section`
  position: relative;
  max-height: 40vh;

  ${(props) =>
    props.priority === 1 &&
    `
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    `}

  ${(props) =>
    props.priority === 2 &&
    `
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      
      // @media (min-width: 1600px) {
      //   grid-column: 2 / 3;
      //   grid-row: 1 / 2;
      // }
    `}

  ${(props) =>
    props.priority === 5 &&
    `
      grid-column: 1 / 2;
      grid-row: 3 / 4;
      
      @media (min-width: 1150px) {
        grid-column: 2 / 3;
        grid-row: 1 / 5;
        max-height: 85vh;
      }
      
      // @media (min-width: 1600px) {
      //   grid-column: 3 / 4;
      // }
    `}

  ${(props) =>
    props.priority === 3 &&
    `
      grid-column: 1 / 2;
      grid-row: 4 / 5;
      
      // @media (min-width: 1600px) {
      //   grid-column: 1 / 2;
      //   grid-row: 2 / 3;
      // }
    `}

  ${(props) =>
    props.priority === 4 &&
    `
      grid-column: 1 / 2;
      grid-row: 5 / 6;
      
      // @media (min-width: 1600px) {
      //   grid-column: 2 / 3;
      //   grid-row: 2 / 3;
      // }
    `}
`;

export const StyledTitleWrapper = styled.div`
  padding-bottom: 10px;
  z-index: 3;
  border-bottom: 2px solid ${({ theme }) => theme.darkest};
  margin-bottom: 20px;
`;

export const StyledTitle = styled(Link)`
  display: inline-block;
  font-weight: 600;
  padding-right: 40px;
  position: relative;
  font-size: 18px;
  line-height: 24px;
  transition: all 0.2s ease-in-out;
  margin: ${(props) => props.margin || 'unset'};
  text-decoration: ${(props) => props.textDecoration || 'none'};

  &::after {
    background-image: url(${(props) => props.icon});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    height: 8px;
    position: absolute;
    right: 10px;
    top: 55%;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    width: 20px;
  }

  &:hover {
    color: ${({ theme }) => theme.main};

    &::after {
      filter: invert(50%) sepia(29%) saturate(7072%) hue-rotate(190deg) brightness(100%) contrast(101%);
      right: 5px;
    }
  }
`;

export const StyledPostWrapper = styled.div`
  position: relative;
  height: calc(100% - 50px);
  overflow-y: auto; // для появления скроллбара по ховеру
  visibility: hidden; // для появления скроллбара по ховеру

  * {
    visibility: visible; // для появления скроллбара по ховеру
  }

  &:hover {
    visibility: visible; // для появления скроллбара по ховеру
  }
`;

export const StyledProjectWrapper = styled.div`
  grid-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: -15px;
  padding: 15px;

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledProjectScroll = styled.div`
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  visibility: hidden;

  * {
    visibility: visible; // для появления скроллбара по ховеру
  }

  &:hover {
    visibility: visible; // для появления скроллбара по ховеру
  }
  
  @media (max-width: 1650px) {
    height: auto;
  }
`