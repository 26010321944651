import { useCallback, useEffect, useRef, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { StyledChatMenu, StyledChatMenuItem } from '@components/chat/styles';
import { useMessageContext } from 'src/contexts/MessageContext';
import useAuth from 'src/hooks/useAuth';

import CreateTaskModal from '../tasks/CreateTaskModal';

const MessageTextSelectionPopoverContainer = (props) => {
  const { popover, showPopover, setQuoteMessage, guestRights } = useMessageContext();

  return (
    <MessageTextSelectionPopover
      {...props}
      popover={popover}
      showPopover={showPopover}
      setQuoteMessage={setQuoteMessage}
      guestRights={guestRights}
    />
  );
};

const MessageTextSelectionPopover = memo(
  ({ popover, showPopover, setQuoteMessage, guestRights, hideProjectOptions }) => {
    const auth = useAuth();
    const { projectId, taskId } = useParams();

    const popoverRef = useRef();
    const { t } = useTranslation();

    // при нажатии на кнопку Ответить цитированием, собрать данные по выделенному тексту и сообщению
    const replyByQuoting = () => {
      const quoteData = {
        messageId: popover.data.reply_id,
        author: popover.data.author,
        dateTime: popover.data.date,
        quoteText: popover.data.reply_text, // в значении раньше был quoteText, но при цитировании текст не отправлялся
      };

      //
      setQuoteMessage(quoteData);
      showPopover({
        show: false,
        x: null,
        y: null,
        data: null,
      });
    };

    const hidePopover = useCallback(
      (event) => {
        if (popover?.show && !popoverRef.current?.contains(event.target)) {
          showPopover({
            show: false,
            x: null,
            y: null,
            data: null,
          });
        }
      },
      [popover, showPopover],
    );

    useEffect(() => {
      document.addEventListener('mousedown', hidePopover);

      const cleanup = () => {
        document.removeEventListener('mousedown', hidePopover);
      };
      return cleanup;
    }, [hidePopover]);

    const [createTaskModal, showCreateTaskModal] = useState(false);
    return (
      <>
        {popover?.show && (
          <StyledChatMenu
            $active={true}
            ref={popoverRef}
            style={{
              left: `${popover.x}px`,
              top: `${popover.y}px`,
              position: 'absolute',
            }}
          >
            {auth?.user && (!auth.user.is_guest || guestRights?.includes('write')) && (
              <StyledChatMenuItem onClick={replyByQuoting}>{t('FormButtons.quote')}</StyledChatMenuItem>
            )}

            {!hideProjectOptions && auth?.user && !auth.user.is_guest && (
              <StyledChatMenuItem
                onClick={() => {
                  showCreateTaskModal(true);
                  showPopover((p) => ({ ...p, show: false }));
                }}
              >
                {t('FormButtons.create_subtask_for_this')}
              </StyledChatMenuItem>
            )}

            <StyledChatMenuItem
              onClick={() => {
                try {
                  const selection = window.getSelection();
                  navigator.clipboard.writeText(selection.toString());
                  showPopover((p) => ({ ...p, show: false }));
                } catch (e) {}
              }}
            >
              {t('FormButtons.copy')}
            </StyledChatMenuItem>
          </StyledChatMenu>
        )}

        {createTaskModal && (
          <CreateTaskModal
            messageBasis={{
              message_id: popover.data.reply_id,
              author: popover.data.author,
              plainText: window.getSelection().toString(),
              LocalDateTime: popover.data.date,
            }}
            projectId={projectId}
            parentTaskId={taskId}
            close={() => showCreateTaskModal(false)}
          />
        )}
      </>
    );
  },
);
export default MessageTextSelectionPopoverContainer;
