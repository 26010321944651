export const notificationTypes = {
  project_created_responsible: {
    text: 'Создан проект, где вы ответственный.', //notes are arriving
    eng: 'A project has been created where you are the responsible.',
  },
  project_created_executor: {
    text: 'Создан проект, где вы исполнитель.', //notes are arriving
    eng: 'A project has been created where you are the executor.',
  },
  project_created_member: {
    text: 'Создан проект, где вы участник.', //notes are arriving
    eng: 'A project has been created where you are a member.',
  },
  task_created_responsible: {
    text: 'Создана задача, где вы ответственный.', //notes are arriving
    eng: 'A task has been created where you are the responsible.',
  },
  task_created_executor: {
    text: 'Создана задача, где вы исполнитель.', //notes are arriving
    eng: 'A task has been created where you are the executor.',
  },
  task_created_member: {
    text: 'Создана задача, где вы участник.', //notes are arriving
    eng: 'A task has been created where you are a member.',
  },
  project_message: {
    text: 'Сообщение в проект.', //notes are arriving
    eng: 'Message in project.',
  },
  task_message: {
    text: 'Сообщение в задачу.', //notes are arriving
    eng: 'Message in task.',
  },
  project_mentioned_in_chat: {
    text: 'Вас упомянули в сообщении.', // проект  //notes are arriving
    eng: 'You were mentioned in a message.',
  },
  task_mentioned_in_chat: {
    text: 'Вас упомянули в сообщении', // задача //notes are arriving
    eng: 'You were mentioned in a message.',
  },
  project_result_posted: {
    text: 'Выложен результат выполнения проекта.', //notes are arriving
    eng: 'The project execution result has been posted.',
  },
  task_result_posted: {
    text: 'Выложен результат выполнения задачи.', //notes are arriving
    eng: 'The task execution result has been posted.',
  },
  project_result_accepted: {
    text: 'Результат выполнения проекта принят.', //notes are arriving
    eng: 'The project execution result has been accepted.',
  },
  task_result_accepted: {
    text: 'Результат выполнения задачи принят.', //notes are arriving
    eng: 'The task execution result has been accepted.',
  },
  project_result_rejected: {
    text: 'Результат выполнения проекта отклонен.', //notes are arriving
    eng: 'The project execution result has been rejected.',
  },
  task_result_rejected: {
    text: 'Результат выполнения задачи отклонен.', //notes are arriving
    eng: 'The task execution result has been rejected.',
  },
  project_completed: {
    text: 'Проект завершен.', // notes are arriving
    eng: 'The project has been completed.',
  },
  task_completed: {
    text: 'Задача завершена.', // notes are arriving
    eng: 'The task has been completed.',
  },
  project_returned: {
    text: 'Проект вернули в работу.', // notes are arriving
    eng: 'The project has been returned to work.',
  },
  task_returned: {
    text: 'Задачу вернули в работу.', // notes are arriving
    eng: 'The task has been returned to work.',
  },
  ticket_assigned: {
    text: 'У вас поручение.',
    eng: 'You have been assigned a ticket.',
  },
  ticket_accepted: {
    text: 'Выполнение поручения принято.',
    eng: 'Ticket completion accepted.',
  },
  ticket_rejected: {
    text: 'Выполнение поручения отклонено (нужно доделать).',
    eng: 'Ticket completion rejected (needs revision).',
  },
  ticket_completed: {
    text: 'Ваше поручение выполнено.',
    eng: 'Your assigned ticket has been completed.',
  },
  team_new_subordinate: {
    text: 'У вас новый подчиненный.', // notes are arriving
    eng: 'You have a new subordinate.',
  },
  team_new_colleague: {
    text: 'У вас новый сослуживец.', // notes are arriving
    eng: 'You have a new colleague.',
  },
  project_updated_executor: {
    text: 'Вы были назначены исполнителем в проекте',
    eng: 'You have been assigned as an executor in the project',
  },
  task_updated_executor: {
    text: 'Вы были назначены исполнителем в задаче',
    eng: 'You have been assigned as an executor in the task',
  },
  project_updated_responsible: {
    text: 'Вы были назначены ответственным в проекте',
    eng: 'You have been assigned as a responsible in the project',
  },
  task_updated_responsible: {
    text: 'Вы были назначены ответственным в задаче',
    eng: 'You have been assigned as a responsible in the task',
  },
  project_updated_member: {
    text: 'Вы были добавлены в участники проекта',
    eng: 'You have been added to the project',
  },
  task_updated_member: {
    text: 'Вы были добавлены в участники задачи',
    eng: 'You have been added to the task',
  },
};
