export const ticketButtons = [
  { name: 'Активные', key: 'active' },
  { name: 'Выполненные', key: 'completed' },
];

export const contactsButtons = [
  { name: 'Все', key: '' },
  { name: 'Партнеры', key: 'partner' },
  { name: 'Клиенты', key: 'client' },
  { name: 'Клиенты-партнеры', key: 'client_partner' },
];

export const contactsModalButtons = [
  { name: 'Партнеры', key: 'partners' },
  { name: 'Клиенты', key: 'clients' },
];
