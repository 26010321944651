import React, { useMemo } from 'react';

import { Text } from '@shared/components/Text/Text';
import { Avatar } from '@shared/components/Avatar';

import { useSelector } from 'react-redux';

import { Button } from '@shared/components/Button/Button';

import moment from 'moment';

import { TicketStatus } from '../TicketStatus';

import { Card, Container, Title, UserCard } from './styles';

const getTicketAction = (action) => {
  switch (action) {
    case 'created':
      return 'Создано';
    case 'canceled':
      return 'Отменено';
    case 'confirmation':
      return 'Ожидает проверки';
    case 'confirmed':
      return 'Подтверждено';
    case 'returned':
      return 'Возрващено';
    default:
      break;
  }
};

const getActionColor = (action) => {
  switch (action) {
    case 'created':
      return '';
    case 'canceled':
      return 'red';
    case 'confirmation':
      return 'yellow';
    case 'confirmed':
      return 'green';
    case 'returned':
      return 'red';
    default:
      break;
  }
};

export const TicketCard = ({ ticket, onClick }) => {
  const users = useSelector((state) => state.users.employees);

  const executor = useMemo(() => users?.filter((user) => user.id === ticket.executor_id)[0], [ticket, users]);

  const executionDate = new Date(ticket.date_finish);
  const action = ticket.ticket_actions[0].action;
  const actionDate = new Date(ticket.ticket_actions[0].date_action);

  return (
    <>
      <Card>
        <Container>
          <TicketStatus color={getActionColor(action)}>
            {getTicketAction(action)} {moment(actionDate).format('L')} {moment(actionDate).format('LT')}
          </TicketStatus>

          <Text as="span" size={2}>
            Исполнить до {moment(executionDate).format('L')} {moment(executionDate).format('LT')}
          </Text>

          <Title>{ticket.title}</Title>

          <UserCard>
            <Avatar userId={executor?.id} size={32} showProfileOnClick />
            <Text size="2">
              {executor ? executor.last_name : ''} {executor && executor.first_name}
            </Text>
          </UserCard>
        </Container>
        <Button onClick={onClick}>Подробнее</Button>
      </Card>
    </>
  );
};
