import React, {useRef, memo, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import ImageGallery from 'src/windows/images/ImageGallery';
import {Avatar} from '@shared/components/Avatar';
import FolderLink from '@components/files/FolderLink';
import {getOldMessages, setColoredMsgId, setIsScrolledChatBottom} from 'src/redux/features/messagesSlice';
import useAuth from 'src/hooks/useAuth';
import LocalDateTime from 'src/components/shared/LocalDateTime';
import {renderMessage} from 'src/utilize/draftjs-helper-functions';
import {useSocketContext} from 'src/contexts/SocketContext';
import {useFormatMessageFiles} from 'src/utilize/message-helper-functions';
import AttachedFile from 'src/components/files/AttachedFile';
import iconThumbUp from '@assets/images/icons/thumb-up.svg';
import iconThumbDown from '@assets/images/icons/thumb-down.svg';
import iconReply from '@assets/images/icons/iconReply.svg';
import iconGoToMessage from '@assets/images/icons/gotomessage.svg';
import iconFile from "@assets/images/icons/icon-file.svg";

import {
  StyledDeletedMessage,
  StyledEditMessageButton,
  StyledMessageAttach,
  StyledMessageBody, StyledMessageContainer,
  StyledMessageDate,
  StyledMessageHeader,
  StyledMessageInfo,
  StyledMessageLink,
  StyledMessageUsername,
  StyledQuote,
  StyledQuoteDate,
  StyledQuoteName,
  StyledReadMoreButton,
  StyledResultButton,
  StyledResultButtonWrapper,
  StyledResultStatus,
  StyledResultTitle,
  StyledTag,
  StyledTagsWrapper,
  StyledThumb,
  StyledThumbIcon,
  StyledThumbWrapper,
} from '@components/chat/message/styles';

import {useSwipeable} from "react-swipeable";
import {useWindowWidth} from "@shared/hooks/useWindowWidth";

import {IconButton} from "@shared/components";

import {useMessageContext} from "../../../contexts/MessageContext";

import MessageControls from './MessageControls';
import ForwardedMessage from './ForwardedMessage/ForwardedMessage';

//import MessagePoll from './MessagePoll/MessagePoll';

const Message = ({
                   date_created,
                   deleted,
                   editMessage,
                   edited,
                   editor_state,
                   employee_id,
                   employee_last_name,
                   employee_first_name,
                   fullName,
                   hideProjectOptions,
                   hidePin,
                   goals,
                   id: messageId,
                   is_guest,
                   likes,
                   message_files,
                   message_storage_folders,
                   tags,
                   title,
                   projectData,
                   pinned,
                   reply_id,
                   reply_date,
                   reply_first_name,
                   reply_last_name,
                   reply_text,
                   reply_message_files = [],
                   sendResultFeedback,
                   showUserProfile,
                   message,
                   isMarked,
                   setIsMarked,
                   localDateCreated,
                   forwarded,
                   isInModal
                 }) => {
  const {setQuoteMessage, setPinnedMessagesModal} = useMessageContext();
  const {likeMessage, dislikeMessage, recoverMessage} = useSocketContext();
  const {taskId} = useParams();

  const projectType = useMemo(() => (taskId ? 'task' : 'project'), [taskId]);

  const dispatch = useDispatch();

  // определить, если в сообщении есть проектный результат или результат для задачи
  const objectGoal = useMemo(() => {
    let goal;
    if (Array.isArray(goals) && goals.length) goal = {...goals[0]};
    if (!projectData || !goal) return goal;

    if (goal.project_goal_id) {
      const selectedProjectGoal = projectData.project_goals?.find(
        (projectGoal) => projectGoal.id === goal.project_goal_id,
      );
      if (selectedProjectGoal) {
        goal.description = selectedProjectGoal.description;
      }
    } else if (goal.task_goal_id && projectData) {
      const selectedTaskGoal = projectData.task_goals?.find((taskGoal) => taskGoal.id === goal.task_goal_id);
      if (selectedTaskGoal) {
        goal.description = selectedTaskGoal.description;
      }
    }
    return goal;
  }, [goals, projectData]);

  const {t} = useTranslation();

  const auth = useAuth();
  const messageRef = useRef();

  const [hideableMessage, setHideableMessage] = useState({
    hideable: false,
    display: 'show',
  });


  // если высота сообщения больше 100 пихелей, то свернуть сообщение
  useEffect(() => {
    if (messageRef?.current) {
      const messageHeight = Number(getComputedStyle(messageRef.current).height.slice(0, -2));

      if (messageHeight >= 100) {
        setHideableMessage({
          hideable: true,
          display: 'hide',
        });
      } else {
        setHideableMessage((h) => {
          if (h.hideable) {
            return {hideable: false, display: 'show'};
          }
          return h;
        });
      }
    }
  }, [reply_text, editor_state]);

  const likesState = useMemo(() => {
    const likesObj = {
      likesQty: 0,
      dislikesQty: 0,
      iLiked: false,
      iDisliked: false,
    };

    if (likes) {
      return likes.reduce((likesState, like) => {
        like.like === 'dislike' ? likesState.dislikesQty++ : likesState.likesQty++;
        if (like.employee_id === auth.user.id) {
          like.like === 'dislike' ? (likesState.iDisliked = true) : (likesState.iLiked = true);
        }
        return likesState;
      }, likesObj);
    } else return likesObj;
  }, [likes, auth]);

  const handleMessageEditClick = () => {
    editMessage({
      chat_message_id: messageId,
      editor_state,
      tags,
      message_files,
      message_storage_folders,
      title,
      goals,
      reply_date,
      reply_first_name,
      reply_last_name,
      reply_text,
      reply_message_files
    });
  };

  const goToMessage = (message_id) => {
    if (!message_id) return;
    const foundMessage = document.getElementById(`messageId${message_id}`);
    if (foundMessage) foundMessage.scrollIntoView({block: 'center'});
    else dispatch(getOldMessages({chat_id: projectData.chat.id, old_message_id: message_id}));
    dispatch(setColoredMsgId(message_id));
    dispatch(setIsScrolledChatBottom(false));
  };

  const [openImgId, setOpenImgId] = useState(null);

  const {allAttachedFiles, gallerySourcesArr} = useFormatMessageFiles(message_files, messageId, auth?.token);

  const windowWidth = useWindowWidth();
  const breakpoint = 768;
  const maxSwipeTransform = 150;
  const [messagePositionOnSwipe, setMessagePositionOnSwipe] = useState(0);

  const handlers = useSwipeable({
    onSwiping: (evt) => {
      if (evt.dir === 'Right' && windowWidth < breakpoint) {
        setMessagePositionOnSwipe(Math.min(evt.absX, maxSwipeTransform))
      }
    },
    onSwiped: (evt) => {
      if (evt.dir === 'Right' && windowWidth < breakpoint) {
        setMessagePositionOnSwipe(0)
        replyByQuoting();
      }
    },
  });

  const replyByQuoting = () => {
    const quoteData = {
      messageId: messageId,
      author: fullName,
      dateTime: <LocalDateTime dateTime={date_created}/>,
      quoteText: message,
      messageFiles: message_files
    };
    setQuoteMessage(quoteData);
  };

  return (
    <>
      <StyledMessageContainer
        {...handlers}
        icon={iconReply}
        messagePositionOnSwipe={messagePositionOnSwipe}
      >
        <StyledMessageHeader>
          <Avatar
            userId={employee_id}
            firstName={employee_first_name}
            lastName={employee_last_name}
            projectType={projectType}
            projectId={projectData?.id}
            is_guest={is_guest}
            projectData={projectData}
            showProfileOnClick
          />

          <StyledMessageInfo>
            <StyledMessageUsername>{fullName}</StyledMessageUsername>
            <StyledMessageDate>{localDateCreated}</StyledMessageDate>
            <StyledThumbWrapper>
              {edited && <StyledEditMessageButton>{t('FormButtons.ed_')}</StyledEditMessageButton>}

              <StyledThumb $active={likesState.iLiked} onClick={() => likeMessage(likesState, messageId)}>
                <StyledThumbIcon icon={iconThumbUp}/>
                {likesState.likesQty > 0 && <span>{likesState.likesQty}</span>}
              </StyledThumb>

              <StyledThumb $active={likesState.iDisliked} onClick={() => dislikeMessage(likesState, messageId)}>
                <StyledThumbIcon icon={iconThumbDown}/>
                {likesState.dislikesQty > 0 && <span>{likesState.dislikesQty}</span>}
              </StyledThumb>
            </StyledThumbWrapper>
          </StyledMessageInfo>
        </StyledMessageHeader>
        {deleted === 1 ? (
          <StyledMessageBody>
            <StyledDeletedMessage>Сообщение удалено</StyledDeletedMessage>
            {auth?.user?.id === employee_id && (
              <StyledMessageLink style={{cursor: 'pointer'}} to="#" onClick={() => recoverMessage(messageId)}>
                Восстановить
              </StyledMessageLink>
            )}
          </StyledMessageBody>
        ) : (
          <>
            <StyledMessageBody
              $state={hideableMessage.display} // показать или скрыть длинное сообщение
              ref={messageRef}
              id={`message${messageId}`}
            >
              {(reply_text || reply_message_files.length > 0) && reply_date && (reply_first_name || reply_last_name) && (
                <StyledQuote onClick={() => goToMessage(reply_id)}>
                  <StyledQuoteName>{`${reply_first_name || ''} ${reply_last_name || ''} `}</StyledQuoteName>{' '}
                  <StyledQuoteDate>
                    <LocalDateTime dateTime={reply_date}/>
                  </StyledQuoteDate>
                  <div>{reply_text}</div>
                  {reply_message_files && reply_message_files.length > 0 &&
                    <>
                      <IconButton
                        icon={iconFile}
                        size={16}
                        style={{marginRight: '8px'}}
                      />
                      Прикрепленные файлы ({reply_message_files.length})
                    </>
                  }
                </StyledQuote>
              )}

              {Boolean(tags?.length) && (
                <StyledTagsWrapper>
                  {tags.map((tag) => {
                    return (
                      <StyledTag key={tag.id} color={tag.color}>
                        {tag.name}
                      </StyledTag>
                    );
                  })}
                </StyledTagsWrapper>
              )}

              {objectGoal && (
                <StyledResultTitle>{`${t('common.result')}: ${objectGoal?.description || ''}`}</StyledResultTitle>
              )}

              {title && !objectGoal && (
                <div>
                  <strong>{title}</strong>
                </div>
              )}

              {/*<MessagePoll />*/}

              {renderMessage(editor_state, showUserProfile)}
            </StyledMessageBody>

            {hideableMessage.hideable && (
              <StyledReadMoreButton
                onClick={() => {
                  if (messageRef?.current) {
                    setHideableMessage((h) => ({
                      ...h,
                      display: h.display === 'hide' ? 'show' : 'hide',
                    }));
                  }
                }}
              >
                {t(`FormButtons.${hideableMessage.display === 'hide' ? 'read_all' : 'fold'}`)}
              </StyledReadMoreButton>
            )}

            {/* Отображение файлов, прикрепленных к этому сообщению */}

            {message_storage_folders?.map((folderData) => (
              <StyledMessageAttach key={folderData.id}>
                <FolderLink folderData={folderData}/>
              </StyledMessageAttach>
            ))}

            {message_files?.length > 0 && (
              <StyledMessageAttach>
                {allAttachedFiles?.map((file) => {
                  return (
                    <AttachedFile
                      fileUrl={file.url}
                      fileName={file.file}
                      fileId={file.id}
                      key={file.id}
                      storageFileId={file.from_storage}
                      setOpenImgId={setOpenImgId}
                      contextMenu={['storageTransfer']}
                      creator_id={employee_id}
                    />
                  );
                })}
              </StyledMessageAttach>
            )}

            {objectGoal?.result === 'wait' &&
              (projectData?.responsible_id === auth?.user?.id ? (
                <StyledResultButtonWrapper>
                  <StyledResultButton onClick={() => sendResultFeedback(objectGoal.id, 'ok')}>
                    {t('FormButtons.accept')}
                  </StyledResultButton>
                  <StyledResultButton color="red" onClick={() => sendResultFeedback(objectGoal.id, 'declined')}>
                    {t('FormButtons.decline')}
                  </StyledResultButton>
                </StyledResultButtonWrapper>
              ) : (
                <StyledResultButtonWrapper>
                  <StyledResultStatus status="pending">Ожидает проверки</StyledResultStatus>
                </StyledResultButtonWrapper>
              ))}

            {objectGoal?.result === 'ok' && <StyledResultStatus status="accept">Принят</StyledResultStatus>}
            {objectGoal?.result === 'declined' && <StyledResultStatus status="decline">Не принят</StyledResultStatus>}
          </>
        )}
      </StyledMessageContainer>

      {/*isInModal - если сообщение в модалке с закрепами*/}
      {deleted !== 1 && !isInModal && (
        <MessageControls
          projectTitle={projectData?.title}
          taskId={taskId}
          projectId={projectData?.project_id || projectData?.id}
          handleMessageEditClick={handleMessageEditClick}
          employee_id={employee_id}
          messageId={messageId}
          message={message}
          editor_state={editor_state}
          fullName={fullName}
          date_created={date_created}
          messageTags={tags}
          hideProjectOptions={hideProjectOptions}
          hidePin={hidePin}
          pinned={pinned}
          goToMessage={goToMessage}
          isMarked={isMarked}
          setIsMarked={setIsMarked}
          isForwarded={Boolean(forwarded)}
          replyByQuoting={replyByQuoting}
        />
      )}

      {
        isInModal && <IconButton
          icon={iconGoToMessage}
          size={19}
          onClick={() => {
            goToMessage(messageId);
            setPinnedMessagesModal(false)
          }}
          title="Перейти к сообщению в чате"
        />
      }

      {forwarded && <ForwardedMessage {...forwarded} projectType={projectType}/>}

      {openImgId && gallerySourcesArr?.length && (
        <ImageGallery imgItems={gallerySourcesArr} openImgId={openImgId} close={() => setOpenImgId(null)}/>
      )}
    </>
  );
};

export default memo(Message);
